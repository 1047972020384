import { createContext, useContext } from 'react'
// import { createStoreGlobal, TStore } from './stores/createStoreGlobal'
// import { useLocalStore, observer } from 'mobx-react' // 6.x or mobx-react-lite@1.4.0
import { RootStore } from './stores/RootStore'


const StoresContext = createContext<RootStore>(new RootStore())

// export const StoreProvider = ({children}: {children: any}) => {
//   const store = useLocalStore(createStoreGlobal)
//   return <StoresContext.Provider value={new RootStore()}>{children}</StoresContext.Provider>
// }

export const useStore = () => {
  const store = useContext(StoresContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}