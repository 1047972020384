import React, { useState } from "react"
import { Tooltip } from 'reactstrap'


interface TooltipProps {
  text: string
  children?: any
  tooltipId: string
}

const TooltipItem = (props: TooltipProps) => {
  const { text, tooltipId } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {/* <Button className="mr-1" color="secondary" id={"Tooltip-" + id}>
        {item.text}
      </Button> */}
      {React.cloneElement(props.children, { id: "Tooltip-" + tooltipId })}
      <Tooltip
        placement={'top'}
        isOpen={tooltipOpen}
        target={"Tooltip-" + tooltipId}
        toggle={toggle}
        delay={{show: 700, hide: 0}}
      >
        {text}
      </Tooltip>
    </>
  );
};

export default TooltipItem