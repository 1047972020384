import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { GridLoader } from "react-spinners"

const Loading = () => {
  const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <Container>
      <Row>
        <Col style={{marginTop: '30vh'}}>
          <GridLoader color={'#908ae1'} loading={true} css={override} size={15} margin={2} />
        </Col>
      </Row>
    </Container>
  )
}
export default Loading