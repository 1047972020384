import * as GlobalTS from '../../ts/__tsGlobal'
import { makeAutoObservable } from "mobx";
import { RootStore } from './RootStore'
// import { useTranslation } from 'react-i18next'

export class GlobalStore {
  rootStore: RootStore
  loggedIn: GlobalTS.User | undefined
  activePage: GlobalTS.Pages
  lngs: {[key: string]: {nativeName: string}}
  lng: string
  versionBannerVisible: boolean
  darkmode: boolean

  constructor(rootStore: RootStore){
    this.rootStore = rootStore
    makeAutoObservable(this)
    this.loggedIn = undefined
    this.activePage = 'Home'
    this.lngs = {
      en: { nativeName: 'English' },
      de: { nativeName: 'Deutsch' }
    };
    this.lng = 'en'
    this.versionBannerVisible = true
    this.darkmode = false
  }

  setLoggedIn(user?: GlobalTS.User){
    if (user) {
      this.loggedIn = user
    } else {
      this.loggedIn = undefined
      this.rootStore.timelineStore.cleanupSession()
      this.rootStore.storyboardStore.cleanupSession()
      this.rootStore.attributeRulesetStore.cleanupSession()
    }
  }

  get userOptions() {
    return this.loggedIn?.options
  }

  userIsTester(testType: 'alpha' | 'beta') {
    return this.userOptions?.find(option => 
      option.optionName === 'tester' && 
      Array.isArray(option.value) &&
      option.value.find(value => 
        value === testType)
      ) !== undefined
  }

  get userAccessClipReport() {
    return process?.env?.REACT_APP_CLIPREPORT ? this.userIsTester( process.env.REACT_APP_CLIPREPORT as 'alpha' | 'beta' ) : true
  }

  get userAccessStoryboard() {
    return process?.env?.REACT_APP_STORYBOARD ? this.userIsTester( process.env.REACT_APP_STORYBOARD as 'alpha' | 'beta' ) : true
  }

  // setLanguage(lng: string){
  //   // const { i18n } = useTranslation()
  //   // i18n.changeLanguage(lng)
  //   this.lng = lng
  // }

  // setActivePage(page: GlobalTS.Pages){
  //   this.activePage = page
  // }

  setVersionBanner(visibile?: boolean){
    this.versionBannerVisible = typeof visibile === 'undefined' ? !this.versionBannerVisible : visibile
  }

  setDarkmode(toggle?: boolean){
    this.darkmode = typeof toggle === 'undefined' ? !this.darkmode : toggle
    if( this.darkmode ){
      document.body.classList.add('darkmode');
    } else {
      document.body.classList.remove('darkmode');
    }
  }
}