export default function authHeader(): {Authorization: string} | null {
  const storage = localStorage.getItem('user')
  const user = JSON.parse(storage ? storage : '{}');

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  }
  return null;

}
