import { AllOfSame, AttributeType, SelectedItem } from "../components/clipReport/__tsTimelineExport"
import Clip from "./Clip"
import ClipAttribute from "./ClipAttribute"
import Times from "./Times"

export default class TrackClip {
  clip: Clip
  parentTrackClip?: TrackClip
  visibleIndex: number

  constructor({clip, parentTrackClip, visibleIndex}: {clip: Clip, parentTrackClip?: TrackClip, visibleIndex?: number}) {
    this.clip = clip
    this.parentTrackClip = parentTrackClip
    this.visibleIndex = visibleIndex ?? 0
  }

  setParentClip(parentTrackClip: TrackClip){
    this.parentTrackClip = parentTrackClip
  }

  get clipTimes(): Times {
    if(this.parentTrackClip) {
      return this.clip.times.returnSubClipTimes(this.parentTrackClip.clipTimes)
    }
    return new Times(this.clip.times)
  }

  get clipVisible(): Times[] {
    if(this.parentTrackClip) {
      // console.log(this.parentTrackClip)
      // console.log(this.clip.times.visible)
      // console.log(this.clip.times.visible.map(visiblePart => visiblePart.returnSubClipTimes(this.parentTrackClip!.clipTimes)))
      return this.clip.times.visible
        .map(visiblePart => visiblePart.returnSubClipTimes(this.parentTrackClip!.clipTimes))
        .map(visiblePart => {
          // console.log({name: this.clip.getAttribute('name')?.getValueString(), visiblePart, clip: this.clip})
          // console.log(this.parentTrackClip!.clipVisible)
          return this.parentTrackClip!.clipVisible.map(parentVisiblePart=>
            visiblePart.returnInsideRange({rangeStart: parentVisiblePart.start, rangeOut: parentVisiblePart.end}))
            // .map(r=>{console.log(r); return r})
          })
        .reduce((acc, val) => acc.concat(val), [])
        .filter(vP => vP.duration > 0)
    }
    return this.clip.times.visible
  }

  get childTrackClips() : TrackClip[] {
    if(this.clip.getAttribute('sequenceUnfold')?.getValueBoolean(false)) {
      const clipTrackClips = this.clip.trackClips
      clipTrackClips.forEach(trackClip => trackClip.setParentClip(this))
      // console.log(clipTrackClips)
      return clipTrackClips.filter(trackClip => trackClip.clipTimes.duration > 0 && ! trackClip.getAttribute('ignore')?.getValueBoolean())
    }
    return [new TrackClip({clip: this.clip})]
  }

  setVisibleTime({start, end, visibleIndex}: {start?: number, end?: number, visibleIndex: number}) {
    if(typeof this.parentTrackClip === 'undefined') {
      this.clip.times.visible[visibleIndex]?.setTime({start, end})
    } else {
      // console.warn('setVisibleTime for parentTrackClip')
      // console.log(this.clip)
      // console.log(this.parentTrackClip)
      // console.log({start, end, visibleIndex})
      this.parentTrackClip.setVisibleTime({start, end, visibleIndex})
    }
    // console.log({name: this.clip.getAttribute('name')?.getValueString(), id: this.clip.id, start, end, visibleIndex})
  }

  addVisible({start, end}: {start: number, end: number}) {
    if(typeof this.parentTrackClip === 'undefined') {
      this.clip.addVisible({start, end})
    } else {
      // console.warn('addVisibleTime for parentTrackClip')
      // console.log(this.clip)
      // console.log(this.parentTrackClip)
      // console.log({start, end})
      this.parentTrackClip.addVisible({start, end})
    }
  }

  resetVisibleParts() {
    if(this.parentTrackClip)
      this.parentTrackClip.resetVisibleParts()
    else
      this.clip.times.resetVisibleParts()
  }

  get transparence() {
    let transparence: boolean | undefined
    if(this.parentTrackClip){
      // transparence = this.parentTrackClip.getAttribute('transparence')?.getValueBoolean()
      transparence = this.parentTrackClip.transparence
    }
    // if not set try to get own attribute
    if(!transparence){ 
      transparence = this.clip.getAttribute('transparence')?.getValueBoolean()
    }

    return transparence ? transparence : false
  }

  getAttribute(key: string, type?: AttributeType, { visible, timeFormat, sequenceId, partTimes }: { visible?: number, timeFormat?: string, sequenceId?: string, partTimes?: Times }={}) {
    let attribute: ClipAttribute | undefined
    // first try to get parent attribute
    if(this.parentTrackClip){
      attribute = this.parentTrackClip.getAttribute(key, type, { visible, timeFormat, sequenceId, partTimes })
    }
    // if not set try to get own attribute
    if(typeof attribute === 'undefined'){ 
      attribute = this.clip.getAttribute(key, type, { visible, timeFormat, sequenceId, partTimes })
    }

    return attribute
  }

  get visjsClassnames(): string[] {
    if(this.parentTrackClip){
      return ['isSubSequence', ...this.clip.visjsClassnames, ...this.parentTrackClip.visjsClassnames]
    }
    return this.clip.visjsClassnames
  }


  get visjsItems(): SelectedItem[] {
    const itemsV = this.clip.store?.activeSequence?.visiblePartsSorted?.visjs.items.filter(item => item.clipIdChain === JSON.stringify(this.idChain))
      .map(item => ({
        visjsId: item.id,
        clipIdChain: this.idChain
      }))
    const itemsA = this.clip.store?.activeSequence?.audiblePartsSorted?.visjs.items.filter(item => item.clipIdChain === JSON.stringify(this.idChain))
      .map(item => ({
        visjsId: item.id,
        clipIdChain: this.idChain
      }))
    return itemsV
      ? itemsA 
        ? [...itemsV, ...itemsA] 
        : itemsV 
      : itemsA 
        ? itemsA
        : []
  }

  get nameChain() : string [] {
    let nameChain: string [] = []
    if(this.parentTrackClip){
      if( this.parentTrackClip.getAttribute('name')?.getValueString() )
        nameChain = [...this.parentTrackClip.nameChain]
    }
    return [...nameChain, this.clip.getAttribute('name')?.getValueString() ?? this.clip.name]
  }

  get idChain() : string [] {
    let idChain: string [] = []
    if(this.parentTrackClip){
      idChain = [...this.parentTrackClip.idChain]
    }
    return [...idChain, this.clip.id]
  }

  getSameClips(same: AllOfSame) {
    if( ! this.clip.store ) return []
    let allClipIds: string[] = []

    switch(same) {
      case 'file':
        // get ClipObjs with same fileId
        allClipIds = Array.from(this.clip.store.clipItems.entries()).filter(([id,clip])=> clip.fileId === this.clip.fileId).map(([id])=> id)
        break
      case 'linked':
        // get linked ClipObjs
        allClipIds = [...this.clip.store.selectedClipObjs[0].linkedClipsIds, this.clip.store.selectedClipIds[0].clipIdChain[this.clip.store.selectedClipIds[0].clipIdChain.length-1]]
        console.log(this.clip.store.selectedClipObjs[0])
        console.log(allClipIds)
        break
      case 'dir':
        // get fileObj of originClipObj
        const fileObj = this.clip.store.filePaths.get(this.clip.store.selectedClipObjs[0].fileId)
        // 
        if (fileObj && fileObj.pathurl) {
          let fullPathParent = fileObj.pathurl.split('/')
          fullPathParent.pop()
          const fullPathParentWithoutChild = fullPathParent.join('/')
          const fileObjs = Array.from(this.clip.store.filePaths.values()).filter(o => ( o.pathurl ? o.pathurl.includes(fullPathParentWithoutChild) : false ))
          allClipIds = Array.from(this.clip.store.clipItems.entries()).filter(([id,clip])=> fileObjs.find(fileObj => fileObj.id === clip.fileId)).map(([id])=> id)
          // allClipIds = this.clip.store.allClipItemsOriginal.filter(clip => fileObjs.find(fileObj => fileObj.id === clip.fileId)).map(clip => clip.id)
        }
        break
      case 'track':
        const track = [...this.clip.store.activeSequence!.videotracks, ...this.clip.store.activeSequence!.audiotracks].find(track => 
          track.clips.find(clipId => this.clip.store?.clipItems.get(clipId.id)?.['id'] === this.clip.id)
        )
        if (track) {
          // allClipIds = track.clips.map(clip => clip.id)
          allClipIds = track.clips.map(c => c.id)
        }
        break
      default:
        allClipIds = this.clip.store.selectedClipIds.map(item => item.clipIdChain[item.clipIdChain.length-1])
    }

    // get TrackItems of ids
    console.log(allClipIds)
    return allClipIds.map(id => 
      this.clip.store!.getTrackItemOfEverywhere({clipId: id})!
    ).filter(trackItem => typeof trackItem !== 'undefined')
    // return allClipIds
  }
}