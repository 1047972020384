import axios from 'axios';

// import { regexToEncoded, encodedToRegex } from '../components/timelineExport/Functions'
import authHeader from './auth-header';
import { AttributeRuleset } from '../components/clipReport/__tsTimelineExport'


// const API_URL = 'http://localhost:8000/';

class UserService {
  getSortRules = () => {
    // console.log (axios.get(API_URL + 'rules/all', { headers: authHeader() }) )
    const authHead = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, apiKey"
      },
      // {'Content-Type': 'text/plain', ...(authHead ? authHead : '')},
    }
    //   "proxy": "http://localhost:8080",
    // console.log(requestOptions)
    return fetch(`${process.env.REACT_APP_API_URL}rules/all`, requestOptions)
      .then((response: any) => {
        // console.log('handleResponse')
        // console.log(response)
        return response.text().then((text: string) => {
          
          const data = text ? (tryParseJSON(text) ? tryParseJSON(text) : []) : []
          if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          // console.log(data)
          return data;
        });
      })
      // .then(data => {
      //   // console.log(user)
      //   // login successful if there's a user in the response
      //   // console.log(data)
      //   const changeTypes = [{
      //     id: 'number',
      //     rules: [{
      //       search: [{
      //         varIndex: 'number'
      //       }],
      //       result: [{
      //         groupId: 'number'
      //       }]
      //     }]
      //   }]
        
      //   if (data && data.length > 0) {
      //     // const rulesListOfDB: GlobalTS.SortingRulesetDB[] = data;
      //     // const sortingRuleset: GlobalTS.SortingRuleset[] = rulesListOfDB.map(ruleList => 
      //     //   ({...ruleList, id: parseInt(ruleList.id), rules: ruleList.rules.map(rule => 
      //     //     ({...rule, search: {...rule.search, 
      //     //         regex: encodedToRegex(rule.search.regex), stock: rule.search.stock ? {idSearchRegExp: encodedToRegex(rule.search.stock.idSearchRegExp)} : undefined
      //     //     }}))
      //     //   }))
      //     // return sortingRuleset
      //     const attributeRulesets: GlobalTS.AttributeRuleset[] = data.map((ruleset: any) => 
      //       ({...ruleset, id: parseInt(ruleset.id), rules: ruleset.rules.map((rule: any) =>
      //         ({...rule, 
      //           search: rule.search.map((ruleSearch: any) => 
      //             ({...ruleSearch, varIndex: ruleSearch.varIndex ? parseInt(ruleSearch.varIndex) : undefined})),
      //           result: rule.result.map((ruleResult: any) => 
      //             ({...ruleResult, varIndex: ruleResult.groupId ? parseInt(ruleResult.groupId) : undefined}))
      //         })
      //       )})
      //     )
      //     return attributeRulesets
      //   }

      //   return data;
      // });
  }

  // setSortRules = (ruleset: GlobalTS.SortingRuleset) => {
  setSortRules = (ruleset: AttributeRuleset) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )
    // const rulessetString = {...ruleset, jsonString: ruleset.rules.map(rule => (
    //   {...rule, search: {...rule.search, regex: regexToEncoded(rule.search.regex), stock: rule.search.stock ? {idSearchRegExp: regexToEncoded(rule.search.stock.idSearchRegExp)} : undefined}, changed: undefined}
    // ))}
    // console.log(rulessetString)

    const authHead = authHeader()
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, apiKey"
      },
      // body: JSON.stringify(rulessetString)
      body: JSON.stringify(ruleset) //.replaceAll('\\','\\\\')
      // {'Content-Type': 'text/plain', ...(authHead ? authHead : '')},
    }
    //   "proxy": "http://localhost:8080",
    console.log(requestOptions)
    return fetch(`${process.env.REACT_APP_API_URL}rules/update`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        console.log(response)
        return response.text().then((text: string) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          return data;
        });
      })
  }

  setSortRulesetActive = (rulesetId: number) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, apiKey"
      },
      body: JSON.stringify({id: rulesetId})
      // {'Content-Type': 'text/plain', ...(authHead ? authHead : '')},
    }
    //   "proxy": "http://localhost:8080",
    console.log(requestOptions)
    return fetch(`${process.env.REACT_APP_API_URL}rules/active`, requestOptions)
      .then((response) => {
        // console.log('handleResponse')
        console.log(response)
        return response.text().then((text: any) => {
          console.log(text)
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          return data;
        });
      })
  }

  getPublicContent() {
    return axios.get(process.env.REACT_APP_API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(process.env.REACT_APP_API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(process.env.REACT_APP_API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(process.env.REACT_APP_API_URL + 'admin', { headers: authHeader() });
  }

  uploadImage = ({dataUri, filename}: {dataUri: string, filename?: string}) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    var body = {}
    body = dataUri ? { ...body, dataUri} : body
    body = filename ? { ...body, filename} : body
    console.log(body)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      },
      body: JSON.stringify( body )
    }
    return fetch(`${process.env.REACT_APP_API_URL}upload/file`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        console.log(response)

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        return response.text().then((text: string) => {
          return text
        });
      })
  }

  saveStoryboard = (body: string) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    console.log(body)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      },
      body: body
    }
    return fetch(`${process.env.REACT_APP_API_URL}storyboard/save`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        console.log(response)

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        return response.text().then((text: string) => {
          return text
        });
      })
  }

  getStoryboards = () => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      }
    }
    return fetch(`${process.env.REACT_APP_API_URL}storyboard/all`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        // console.log(response)

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        if (response.status === 204) {
          return []
        }
        return response.text().then((text: string) => {
          return JSON.parse(text)
        });
      })
  }

  getUserOptions = () => {
    const authHead = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      }
    }
    return fetch(`${process.env.REACT_APP_API_URL}user/myOptions`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        // console.log(response)

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        if (response.status === 204) {
          return []
        }
        return response.text().then((text: string) => {
          return JSON.parse(text)
        });
      })
  }

  getStoryboard = (id: number) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      }
    }
    return fetch(`${process.env.REACT_APP_API_URL}storyboard/data/${id}`, requestOptions)
      .then((response: Response) => {
        // console.log('handleResponse')
        // console.log(response)

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        return response.text().then((text: string) => {
          return JSON.parse(text)
        });
      })
  }

  checkAvailable = (param: string) => {
    // console.log (axios.get(process.env.REACT_APP_API_URL + 'rules/all', { headers: authHeader() }) )

    const authHead = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authHead? authHead.Authorization : '', 
        'Content-Type': 'text/plain',
        "Access-Control-Allow-Origin": "*",
        'apiKey': 'test-apikey',
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Request-Methods": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Access-Control-Request-Methods, Authorization, X-Requested-With, apiKey"
      }
    }
    return fetch(`${process.env.REACT_APP_API_URL}user/available?${param}`, requestOptions)
      .then((response: Response) => {

        if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              // logout();
              // location.reload(true);
          }

          const error = (response.body) || response.statusText;
          return Promise.reject(error);
        }
        return response.text().then((text: string) => {
          return JSON.parse(text)
        });
      })
  }

}

const tryParseJSON = (jsonString: string) =>{
  try {
    const object = JSON.parse(jsonString);

    if (object && typeof object === "object") {
      return object;
    }
  }
  catch (e) { }

  return false;
};

export default new UserService();
