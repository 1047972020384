import React, { useState } from 'react'
import {
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
  Spinner
} from 'reactstrap'
import { useObserver } from "mobx-react"
// import { useTranslation } from 'react-i18next';

import { useStore } from '../store'
import { Dropdown } from 'reactstrap'
import * as StoryboardFunctions from './StoryboardFuntions'


const NavItemsStoryboard = () => {
  const {storyboardStore,routingStore} = useStore()
  // const { t } = useTranslation();

  const [dropdownStoryboardsOpen, setDropdownStoryboardsOpen] = useState(false);
  const [dropdownLatestStoryboardsOpen, setDropdownLatestStoryboardsOpen] = useState(false);

  const toggleStroyboards = () => {
    setDropdownStoryboardsOpen(prevState => !prevState)
  }

  const toggleLatestStroyboards = () => {
    storyboardStore.requestServerStoryboards()
    setDropdownLatestStoryboardsOpen(prevState => {
      if(prevState) setDropdownStoryboardsOpen(!prevState)
      return !prevState
    })
  }

  return useObserver(() => (
    <div className='nav-storyboard'>
      <NavItem className='nav-title'>
        <NavLink disabled href="#">Storyboard</NavLink>
      </NavItem>
      <Dropdown nav inNavbar isOpen={dropdownStoryboardsOpen} toggle={toggleStroyboards}>
        <DropdownToggle nav caret>
          Datei
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => routingStore.push('/storyboard')}>
              Neues Storyboard anlegen
          </DropdownItem>
          <Dropdown direction="right" isOpen={dropdownLatestStoryboardsOpen} toggle={toggleLatestStroyboards}>
            <DropdownToggle caret className="dropdown-item">
            Zuletzt verwendet
            </DropdownToggle>
            <DropdownMenu>
              {storyboardStore.serverStoryboards.data && storyboardStore.serverStoryboards.data.map(storyboard => 
                <DropdownItem onClick={()=> {
                  storyboardStore.loadStoryboard(storyboard.id)
                  // globalStore.setActivePage('Storyboards')
                  routingStore.push('/storyboard/edit')
                }} key={'sb_'+storyboard.id}>{storyboard.name} <span style={{fontSize: '.7em', color: '#888'}}>({StoryboardFunctions.dateToEdited(new Date(storyboard.changed))})</span></DropdownItem>
                )}
              {storyboardStore.serverStoryboards.request.status === 'loading' && 
                <DropdownItem><Spinner size="sm" color="secondary" /></DropdownItem>
              }
              <DropdownItem divider />
              <DropdownItem>Storyboard importieren</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <DropdownItem onClick={() => routingStore.push('/storyboard')}>
              Storyboard öffnen
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  ))
}
export default NavItemsStoryboard