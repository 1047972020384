import { ItemGroup } from "../components/clipReport/__tsTimelineExport"
import { TimelineStore } from "../components/stores/TimelineStore"

export default class ClipGroup {

  store: TimelineStore
  // id: number
  groupId: number
  showInList: boolean

  constructor(store: TimelineStore, groupId: number) {
    this.store = store
    // this.id = id
    this.groupId = groupId
    this.showInList = true
  }

  get group() {
    let group: ItemGroup | undefined
    this.store.sequences.forEach(sequence => {
      group = sequence.itemGroups.get(this.groupId)
    })
    return group
  }

  get rawData() {
    return {
      groupId: this.groupId,
      showInList: this.showInList
    }
  }

}