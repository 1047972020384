// import { text } from '@fortawesome/fontawesome-svg-core'
// import React from "react"
// import xml2json from 'react-xml-parser'
import * as d3 from 'd3-polygon'

import * as ReportTS from '../__tsTimelineExport'
import Rectangle from '../../../classes/Rectangle'
import Clip from '../../../classes/Clip'
import ClipGroup from '../../../classes/ClipGroup'

// const pproTicks = 254016000000

export const MOD = {
  MODE : {
    TOGGLE: 'toggle' as ReportTS.ModMode,
    CHANGE: 'change' as ReportTS.ModMode,
    ARRAYADD: 'arrayAdd' as ReportTS.ModMode,
    ARRAYCHANGE: 'arrayChange' as ReportTS.ModMode,
    ARRAYDELETE: 'arrayDelete' as ReportTS.ModMode
  },
  KEYS: {
    NAME: 'name' as ReportTS.ModKeys,
    RIGHTS: 'rights' as ReportTS.ModKeys,
    OWNER: 'owner' as ReportTS.ModKeys,
    TRANSPARENCE: 'transparence' as ReportTS.ModKeys,
    IGNORE: 'ignore' as ReportTS.ModKeys,
    SEQUENCEUNFOLD: 'sequenceUnfold' as ReportTS.ModKeys,
    GROUPS: 'groups' as ReportTS.ModKeys
  }
}

export const REGEXLIST = {
  TYPE: {
    FOLDER: 'folder',
    FILE: 'file'
  }
}

export const ALLOFSAME = {
  FILE: 'file' as ReportTS.AllOfSame,
  DIR: 'dir' as ReportTS.AllOfSame,
  TRACK: 'track' as ReportTS.AllOfSame
}

// export const csvHeaders = {
//   name: 'Name',
//   source: "Quelle",
//   tcIn: "TC in",
//   tcOut: "TC out",
//   dur: "DUR",
//   new: "Neu gekauft",
//   rights: "Rechteumfang"
// }

export const PPROLABELCOLORS = {
  Violet: '#a790e1',
  Iris: '#729acc',
  Caribbean: '#2ad698',
  Lavender: '#e384e3',
  Cerulean: '#2fbedd',
  Forest: '#51b858',
  Rose: '#f86fa4',
  Mango: '#eca63a',
  Purple: '#970097',
  Blue: '#3c3bff',
  Teal: '#00807f',
  Magenta: '#e731e6',
  Tan: '#cec094',
  Green: '#1e7021',
  Brown: '#8b4513',
  Yellow: '#e2e264'
}

export const DEFAULT = {
  SOURCE: {
    UNKNOWN: {
      owner: 'unbekannt',
      rights: 'unbekannt'
    },
    SELF: {
      owner: 'Eigendreh',
      rights: 'Alle Rechte'
    },
    NORIGHTS: {
      rights: 'Keine Rechte'
    }
  },
}

// commented
// export const getOwnerOfNestedFolder = ({filePath, regex, res}:{filePath: string, regex: RegExp, res: ReportTS.Source }): ReportTS.Source | null => {
//   let pathComponents = decodeURIComponent(filePath).split('/')
//     .reverse()
//     .find(pathPart => {
//       return regex.test(pathPart)
//     })
//   if (pathComponents && pathComponents.length > 0) return res
//   return null
// }

// unused
// export const searchInPath = ({filePath, regex}:{filePath: string, regex: RegExp}): boolean => {
//   const pathComponents = decodeURIComponent(filePath).split('/')
//     .reverse()
//     .find(pathPart => {
//       return regex.test(pathPart)
//     })
//   if (pathComponents && pathComponents.length > 0) return true
//   return false
// }

export const replaceVariables = (value: string | boolean | ClipGroup[], variables: {key: string, value: string | boolean}[]) => {
  if(typeof value === 'boolean') return value
  if(typeof value === 'object') return value

  let returnString = value
  variables.forEach( variable => {
    if(typeof variable.value === 'boolean') return true
    returnString = returnString.replaceAll(new RegExp('{{'+variable.key+'}}', 'g'), variable.value)
  })
  return returnString
}

// export const getClipIds = (idsWithIndex: string[][]) => {
//   return idsWithIndex.map(id => id[id.length-1].match(/clipitem-[0-9a-zA-Z]+/) ? id[id.length-1].match(/clipitem-[0-9a-zA-Z]+/)![0] : '') 
// }

// export const getPureIds = (idsWithIndex: string[]) => {
//   return idsWithIndex.map(id => id.match(/clipitem-[0-9a-zA-Z]+(-parent-[0-9a-zA-Z]+)?/) ? id.match(/clipitem-[0-9a-zA-Z]+(-parent-[0-9a-zA-Z]+)?/)![0] : '')
// }

// export const getPureIdsUnique = (ids: string []) => {
//   const selClipIds = getPureIds( ids )
//   return Array.from(new Set(selClipIds))
// }

export const getVisjsIdGroupType = (visjsItemId: string) => {
  switch(true) {
    case /^[a]-[a-z]+/.test(visjsItemId):
      return 'audio'
    case /^[v]-[a-z]+/.test(visjsItemId):
      return 'video'
    default:
      return undefined
  }
}

export const getTrackOfClip = (tracks: ReportTS.Track[], clipToFind: {key: keyof Clip, val: string}, allClipItems: Map<string,Clip>): ReportTS.Track | undefined => {
  return tracks.find(track => 
    track.clips.find(clipId => allClipItems.get(clipId.id)?.[clipToFind.key] === clipToFind.val )
  )
}

// export const getItemsOfGroup = (groupId: number, tracks: ReportTS.Track[], allClipItems: Map<string,Clip>): Clip[] => {
//   return tracks.map(track => track.clips)
//   .reduce((acc, val) => acc.concat(val), [])
//   .map(clipId => allClipItems.get(clipId.id)!)
//   .filter(clip => clip.groups ? clip.groups.find(group => group.groupId === groupId) : undefined)
// }

const convertToCSV = (objArray: string) => {
  const delimiter = ';'
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line !== '') line += delimiter
          line += array[i][index];
      }
      str += line + '\r\n';
  }
  return str;
}

// export const exportCSVFile = ({items, fileTitle}: {items: typeof csvHeaders[], fileTitle: string}) => {
export const exportCSVFile = ({headers, list, fileTitle}: {headers: {[x: string]: string }, list: {[x: string]: string }[], fileTitle: string}) => {

  // items.unshift(csvHeaders)
  // console.log(list)

  // Convert Object to JSON
  var jsonObject = JSON.stringify([headers, ...list])

  var csv = convertToCSV(jsonObject)

  const fileName = fileTitle.split('.').length > 1 ? fileTitle.split('.').slice(0, -1).join('.') : fileTitle

  const exportedFilename = fileName + '.csv' || 'export.csv'

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  // @ts-ignore
  if (navigator.msSaveBlob) { // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, exportedFilename)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const exportJSONFile = (json: Object, fileTitle: string) => {
  console.log(json)
  // Convert Object to JSON
  const jsonString = JSON.stringify(json)

  const fileName = fileTitle.split('.').length > 1 ? fileTitle.split('.').slice(0, -1).join('.') : fileTitle
  console.log(fileName)
  const exportedFilename = fileName + '.json' || 'export.json'
  console.log(exportedFilename)
  const blob = new Blob([jsonString], { type: 'text;charset=utf-8;' })
  // @ts-ignore
  if (navigator.msSaveBlob) { // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, exportedFilename)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const getTimecodeHMS = (frames: number, timebase: number) => {
  return secondsToTimecodeHMS( Math.round(frames/timebase) )
}

export const getTimecodeMS = (frames: number, timebase: number) => {
  return framesToTimecodeMS(frames, timebase)
}

const framesToTimecodeMS = (frames: number, timebase: number) => {
  return secondsToTimecodeMS( Math.round(frames/timebase) )
}

const secondsToTimecodeHMS = (secondsTotal: number) => {
  const seconds = secondsTotal % 60
  const minutes = (( secondsTotal - seconds ) / 60) % 60
  const hours = ( secondsTotal - seconds - minutes * 60 ) / 3600
  return returnWithLeadingZero(hours) + ':' + returnWithLeadingZero(minutes) + ':' + returnWithLeadingZero(seconds)
}

const secondsToTimecodeMS = (secondsTotal: number) => {
  const seconds = secondsTotal % 60
  const minutes = ( secondsTotal - seconds ) / 60
  return returnWithLeadingZero(minutes) + ':' + returnWithLeadingZero(seconds)
}

const returnWithLeadingZero = (tcInt: number) => {
  return tcInt < 10 ? '0'+tcInt : tcInt.toString()
}

export const regexToEncoded = (regExp: RegExp) => {
  // const string = encodeURIComponent(regExp.toString())
  // const findFlags = string.match(/%2F([gmiyus])*$/)

  // const start = string.match(/^%2F/) ? 3 : 0
  // const flags = (findFlags && findFlags.length === 2) ? findFlags[1] as ReportTS.RegExpFlags : undefined
  // const end = findFlags ? ( string.length - findFlags[0].length ) : 0

  // return {encodedRegex: string.substring(start, end), flags}
  return {encodedRegex: encodeURIComponent(regExp.source), flags: regExp.flags}
}

export const encodedToRegex = (regexElement: ReportTS.RegExpEncoded): RegExp => {
  return new RegExp(decodeURIComponent(regexElement.encodedRegex), regexElement.flags)
}

export const checkFiltersForTransparency = (clip: Clip, fileDimensions: ReportTS.MediaDimensions | undefined, sequenceDimensions: ReportTS.MediaDimensions): boolean => {
  if (!clip.filter) return true
  const basicMotion = clip.filter.filter(fil => fil.name === 'Basic Motion')
  let coversAll = true
  if (basicMotion) {
    basicMotion.forEach(basicMotionEffect => {
      if(basicMotionEffect.parameter && basicMotionEffect.parameter.length > 0){
        const scaleParam = basicMotionEffect.parameter.find((p: ReportTS.EffectParameter) => p.parameterid === 'scale')
        const scale = scaleParam ? parseFloat(scaleParam.value) : undefined

        const centerParam = basicMotionEffect.parameter.find((p: ReportTS.EffectParameter) => p.parameterid === 'center')
        const center = centerParam ? {horiz: parseFloat(centerParam.value.horiz), vert: parseFloat(centerParam.value.vert)} : undefined

        // const centerOffsetParam = basicMotionEffect.parameter.find((p: ReportTS.EffectParameter) => p.parameterid === 'centerOffset')
        // const centerOffset = centerOffsetParam ? {horiz: parseFloat(centerOffsetParam.value.horiz), vert: parseFloat(centerOffsetParam.value.vert)} : undefined

        const rotationParam = basicMotionEffect.parameter.find((p: ReportTS.EffectParameter) => p.parameterid === 'rotation')
        const rotation = rotationParam ? parseFloat(rotationParam.value) : undefined

        // console.log(JSON.parse(JSON.stringify(fileDimensions)))
        // console.log(JSON.parse(JSON.stringify(sequenceDimensions)))
        const dimensions = fileDimensions ? fileDimensions : sequenceDimensions
        const clipScreen = new Rectangle(dimensions.width, dimensions.height)
        if(scale){
          // console.log('scale: '+scale)
          clipScreen.scale(scale / 100)
        }
        if(rotation){
          // console.log('rotation: '+rotation)
          clipScreen.rotate(rotation)
        }
        if(center){
          // console.log('center: '+JSON.stringify(center))
          clipScreen.translate(center)
        }
        // console.log(clipScreen.coords())
        coversAll = coversAll ? d3.polygonContains(clipScreen.coords(),[-(sequenceDimensions.width/2)+1,-(sequenceDimensions.height/2)+1]) : coversAll
        coversAll = coversAll ? d3.polygonContains(clipScreen.coords(),[-(sequenceDimensions.width/2)+1, (sequenceDimensions.height/2)-1]) : coversAll
        coversAll = coversAll ? d3.polygonContains(clipScreen.coords(),[ (sequenceDimensions.width/2)-1, (sequenceDimensions.height/2)-1]) : coversAll
        coversAll = coversAll ? d3.polygonContains(clipScreen.coords(),[ (sequenceDimensions.width/2)-1,-(sequenceDimensions.height/2)+1]) : coversAll

        // if(centerOffset){
        //   console.log('centerOffset: '+JSON.stringify(centerOffset))
        //   // clipScreen.centerOffset(centerOffset)
        // }
      }
    })
  }

  const opacity = clip.filter.filter(fil => fil.name === 'Opacity')
  if(opacity){
    opacity.forEach(opacityEffect => {
      if(opacityEffect.parameter && typeof opacityEffect.parameter === 'object'){
        const opacityValue = parseFloat( (opacityEffect.parameter as ReportTS.EffectParameter).value )
        
        if(opacityValue < 100) {
          coversAll = false
        }
      }
    })
  }

  return coversAll
}

export const getFileTypeByPath = (pathurl: string): ReportTS.FileType => {
  const dot = pathurl.lastIndexOf(".")
  const fileType = pathurl.substring(dot+1);
  switch(fileType.toLowerCase()) {
    case 'psd':
      return 'photoshop'
    case 'jpg':
    case 'png':
    case 'tif':
    case 'jpeg':
    case 'eps':
      return 'image'
      // break
    case 'ai':
      return 'illustrator'
    case 'aep':
      return 'aftereffects'
    case 'indd':
      return 'indesign'
      // break
    case 'mxf':
    case 'mp4':
    case 'mov':
    case 'avi':
      return 'video'
      // break
    case 'wav':
    case 'aiff':
    case 'mp3':
      return 'audio'
    default:
      return ''
  }
}

// export const getClassnames = (clip: Clip, activeSequenceId: string) => {
//   let classnames = ''
//   // classnames += TimelineStore.getAttributeOfClip({clip: clip, attribute: 'transparence'}).value ? ' transparence' : ''
//   // classnames += this.getAttributeOfClip({clip: clip, attribute: 'ignore'}).value ? ' ignore' : ''
//   // if the sequence is unfolded
//   classnames += clip.parent.sequenceId && clip.parent.sequenceId !== activeSequenceId ? ' isSequence unfolded' : ''
//   classnames += clip.sequenceId ? ' isSequence' : ''
//   classnames += clip.parent.sequenceId ? ' isSubSequence' : ''
//   // classnames += clip.groups && clip.groups.length > 0 ? ' isGroupItem' : ''
//   classnames += clip.groupIds.length > 0 ? ' isGroupItem' : ''
//   classnames += clip.pproColorLabel ? ' pproColorLabel-'+clip.pproColorLabel : ''
//   // classnames += clip.isAdjustmentLayer ? ' isAdjustmentLayer' : ''
//   // classnames += clip.isGraphicsElement ? ' isGraphicsElement' : ''
//   classnames += clip.itemType && clip.itemType === 'adjustmentLayer' ? ' isAdjustmentLayer' : ''
//   classnames += clip.itemType && clip.itemType === 'graphic' ? ' isGraphicsElement' : ''
//   return classnames
// }

export const groupIdsOfClip =(selectedClipObjs: Clip[])=>{
  return selectedClipObjs.filter(clip => clip.groups.length > 0)
    .map(clip => clip.groups)
    .reduce((acc, val) => acc.concat(val), [])
    .filter((v, i, a) => a.indexOf(v) === i)
  // return selectedClipObjs.filter(clip => clip.groups && clip.groups.length > 0)
  //   .map(clip => clip.groups!.map(group => group.groupId))
  //   .reduce((acc, val) => acc.concat(val), [])
  //   // just stay with unique groupIds
  //   .filter((v, i, a) => a.indexOf(v) === i)
}