import React, { useState, CSSProperties } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

interface ModalEditProps  {
  // selectedIds: string[]
  modalTitle: string
  // func: Function
  // funcParams: any
  callback: Function
  children: any
  isVisible?: Function
  style?: CSSProperties
  size?: 'sm' | 'md' | 'lg'
  confirmButton?: string
  confirmButtonColor?: string
  modalBody?: JSX.Element | string
}

const ModalCheckAction = (props: ModalEditProps) => {
  const [modal, setModal] = useState(false)
  const { t } = useTranslation();

  const toggle = (execute: boolean) => {
    if(modal && execute){
      // props.func(props.funcParams)
      console.log('execute')
      props.callback()
    }
    setModal(!modal)

    if( props.isVisible ){
      props.isVisible(!modal)
    }
  }

  return (
    <div className="modal-check" style={props.style}>
      <div onClick={() => toggle(false)} className="modal-check-trigger">
        {props.children}
      </div>
      <Modal isOpen={modal} toggle={() => toggle(false)} className={'modalCheckAction'} size={props.size ? props.size : 'md'}>
        <ModalHeader toggle={() => toggle(false)}>{props.modalTitle}</ModalHeader>
        <ModalBody>
          {props.modalBody ? props.modalBody : 'Sind sie sicher?'}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle(false)}>{t('cancel')}</Button>
          <Button color={props.confirmButtonColor ? props.confirmButtonColor : "danger"} onClick={() => {toggle(true)}}>{props.confirmButton ? props.confirmButton : t('confirm')}</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalCheckAction;