import * as ReportTS from '../clipReport/__tsTimelineExport'
import * as GlobalTS from '../../ts/__tsGlobal'
import * as Globals from '../clipReport/functions/Functions'
import { makeAutoObservable } from "mobx"

import Operation from '../../classes/Operation'
// import ClipAttribute from '../../classes/ClipAttribute'
import { RootStore } from './RootStore'
import Time from '../../classes/Time'
import Clip from '../../classes/Clip'
import Times from '../../classes/Times'
import TrackClip from '../../classes/TrackClip'
import ClipGroup from '../../classes/ClipGroup'
import ClipTimes from '../../classes/ClipTimes'
import VisiblePart from '../../classes/ClipVisiblePart'

const STARTTIME = process.env.REACT_APP_STARTTIME ? parseInt(process.env.REACT_APP_STARTTIME) : 0

export class TimelineStore {
  rootStore: RootStore
  // activeView: ReportTS.View
  fileName: string
  selectedClipIds: ReportTS.SelectedItem[]
  sequences: ReportTS.Sequence[]
  filePaths: Map<string,ReportTS.FilePathItem>
  clipItems: Map<string,Clip>
  showModalEdit: boolean
  actualOperationStack: Operation[]
  operationStack: ReportTS.OperationCollection[]
  activeSequenceId: string
  errors: GlobalTS.Error[]
  timeFormat: string
  timelistOptions: Map<string,boolean>
  // attributeRuleset: AttributeRulesetStore
  // attributeSearchTypes: {folder: 'Ordner', file: 'Datei', folderAndFile: 'Ordner und Datei', clipname: 'Clipname'},

  constructor(rootStore: RootStore){
    this.rootStore = rootStore
    makeAutoObservable(this)
    // this.activeView = 'ImportFile'
    this.fileName = ""
    this.selectedClipIds = []
    this.sequences = []
    this.filePaths = new Map()
    this.clipItems = new Map()
    this.showModalEdit = false
    this.actualOperationStack = []
    this.operationStack = []
    this.activeSequenceId = ""
    this.errors = []
    this.timeFormat = 'mm:rss'
    this.timelistOptions = new Map<string,boolean>()
    // this.attributeRuleset = new AttributeRulesetStore(rootStore)
  }

  // setActiveView(view: ReportTS.View) {
  //   this.activeView = view
  // }

  cleanupSession(){
    this.fileName = ""
    this.selectedClipIds = []
    this.sequences = []
    this.filePaths = new Map()
    this.clipItems = new Map()
    this.showModalEdit = false
    this.actualOperationStack = []
    this.operationStack = []
    this.activeSequenceId = ""
    this.errors = []
  }

  setFileName(fileName: string) {
    this.fileName = fileName
  }

  toggleShowModalEdit() {
    this.showModalEdit = !this.showModalEdit
  }

  get activeSequence() {
    return this.sequences.find(seq => seq.id === this.activeSequenceId)
  }
  
  get activeSequenceParents() {
    // console.log(this.activeSequenceId)
    const inVideotracks = this.sequences.filter(seq => 
      seq.videotracks.find(videotrack => 
        videotrack.clips.find(clipId => 
          this.clipItems.get(clipId.id)?.sequenceId === this.activeSequenceId
          // clip.sequenceId === this.activeSequenceId
        )
      )
    )
    const inAudiotracks = this.sequences.filter(seq => 
      seq.audiotracks.find(audiotrack => 
        audiotrack.clips.find(clipId => 
          this.clipItems.get(clipId.id)?.sequenceId === this.activeSequenceId
          // clip.sequenceId === this.activeSequenceId
        )
      )
    )
    return [...[], ...inVideotracks, ...inAudiotracks].map(seq => ({id: seq.id, name: seq.name}))
  }

  get activeSequenceVisjs() {
    // const aS = this.activeSequence
    let visjs: {items: ReportTS.VisjsItem[], groups: ReportTS.VisjsGroup[]} | null = null
    if (this.activeSequence && !this.activeSequence.visiblePartsSorted) {
      this.updateVisiblePartsSorted()
    }
    if (this.activeSequence?.visiblePartsSorted?.visjs) {
      visjs = this.activeSequence.visiblePartsSorted.visjs
    }
    if (this.activeSequence?.audiblePartsSorted?.visjs) {
      if (visjs) {
        const orderOffset = visjs.groups.length
        visjs.groups[orderOffset-1].className = 'lastVideoTrack'
        visjs = {
          items: [...visjs.items, ...this.activeSequence.audiblePartsSorted.visjs.items],
          groups: [...visjs.groups, ...this.activeSequence.audiblePartsSorted.visjs.groups.slice().reverse().map((group, index) => ({...group, order: index + orderOffset}))]
        }
      } else {
        visjs = this.activeSequence.audiblePartsSorted.visjs
      }
    }
    return visjs
  }

  get activeSequenceVisjsVideo() {
    if (this.activeSequence?.visiblePartsSorted?.visjs) {
      return this.activeSequence.visiblePartsSorted.visjs
    }
    return null
  }

  get activeClipItems() {
    if (this.activeSequence) {
      return [ ...this.activeSequence.videotracks.map(vt => vt.clips).reduce((acc, val) => acc.concat(val), []),
        ...this.activeSequence.audiotracks.map(at => at.clips).reduce((acc, val) => acc.concat(val), []) ]
    }
    return []
  }

  getSequenceName(sequenceId: string) {
    const sequence = this.sequences.find(seq => seq.id === sequenceId)
    return sequence ? sequence.name : ''
  }

  getTrackItem(clipIdChain: string[]): TrackClip | null {
    let trackClip: TrackClip | undefined
    if(this.activeSequence){
      this.activeSequence.visiblePartsSorted?.tracks.every(track => {
        trackClip = track.clips.find(tItem => JSON.stringify(tItem.idChain) === JSON.stringify(clipIdChain) )
        return typeof trackClip === 'undefined'
      })
      if(typeof trackClip === 'undefined')
        this.activeSequence.audiblePartsSorted?.tracks.every(track => {
          trackClip = track.clips.find(tItem => JSON.stringify(tItem.idChain) === JSON.stringify(clipIdChain) )
          return typeof trackClip === 'undefined'
        })
    }
    return trackClip ? trackClip : null
  }

  getTrackItemOfEverywhere({clipId, clipIdChain}: {clipId?: string, clipIdChain?: string[]}){
    let trackClip: TrackClip | undefined
    this.sequences.every(sequence => {
      sequence.visiblePartsSorted?.tracks.every(track => {
        trackClip = clipId ? track.clips.find(tClip => tClip.clip?.id === clipId ) : undefined
        trackClip = clipIdChain && typeof trackClip === 'undefined' ? track.clips.find(tItem => JSON.stringify(tItem.idChain) === JSON.stringify(clipIdChain) ) : trackClip
        return typeof trackClip === 'undefined'
      })
      if(typeof trackClip === 'undefined')
        sequence.audiblePartsSorted?.tracks.every(track => {
          trackClip = clipId ? track.clips.find(tClip => tClip.clip?.id === clipId ) : undefined
          trackClip = clipIdChain && typeof trackClip === 'undefined' ? track.clips.find(tItem => JSON.stringify(tItem.idChain) === JSON.stringify(clipIdChain) ) : trackClip
          return typeof trackClip === 'undefined'
        })
      return typeof trackClip === 'undefined'
    })
    return trackClip
  }

  setSelectedClipIds(selectedClipIds: ReportTS.SelectedItem[], updateUI = false) {
    let uniqueSelectedIdsPure = Array.from(new Set(
      selectedClipIds.map(item => item.clipIdChain[item.clipIdChain.length-1])
    ))

    // first get ClipItems
    if (this.activeSequence && this.filePaths.size > 0) {
      console.log(uniqueSelectedIdsPure)
      const selectedClipObjs =  this.activeClipItems.filter(clipId => uniqueSelectedIdsPure.find(id => id === clipId.id))
        .map(clipId => this.clipItems.get(clipId.id)!)
        // .map(clip => ({...clip, fileObj: this.filePaths.find(fp => fp.id === clip.fileId) }))
      // console.log(selectedClipObjs)

      // check for groups
      const groupIds = selectedClipObjs.filter(clip => clip.groupIds.length > 0).map(clip => clip.itemGroups).reduce((acc, val) => acc.concat(val), [])
      // const groupIds = Globals.groupIdsOfClip(selectedClipObjs)
      console.log(groupIds)
      const clipIdsOfGroups = selectedClipObjs
        // get clips that have groups
        .filter(clip => clip.groupIds.length > 0)
        .map(clip => clip.itemGroups)
        // just get every group once if selected multiple clips
        .reduce((acc, val) => {
          val.forEach(valEach => {
            if( typeof acc.find((itemGroup) => itemGroup.id === valEach[1].id) === 'undefined'){
              // not in accumulated array so add it
              acc.push(valEach[1])
            }
          })
          return acc
        }, [] as ReportTS.ItemGroup[])
        // get clips of groups
        .reduce((acc, val) => {
          if(val.clipItems) acc = acc.concat(val.clipItems)
          return acc
        }, [] as Clip[]).map(clip => clip.id)

      if(clipIdsOfGroups)
        uniqueSelectedIdsPure = Array.from(new Set([...uniqueSelectedIdsPure, ...clipIdsOfGroups]))
    }

    // const idsOfSameClipitem = this.activeSequenceVisjs ? this.activeSequenceVisjs.items.filter(item => uniqueSelectedIdsPure.find(id => {
    //   const regex = new RegExp('^([av]-)?'+id+'_(\\d+)?')
    //   return regex.test(item.id)
    // })).map(item => item.id) : []
    // // console.log(selectedClipIds)
    // // console.log(idsOfSameClipitem)
    // if(this.selectedClipIds.sort().join(',') !== idsOfSameClipitem.sort().join(',')) this.selectedClipIds = [idsOfSameClipitem]
    const itemsToSelect = uniqueSelectedIdsPure.map(id => this.getTrackClip(id)?.visjsItems).reduce((acc, val) => {
      return typeof val !== 'undefined' ? [...acc!,...val] : [...acc!]
    }, [] as ReportTS.SelectedItem[])
    this.selectedClipIds = itemsToSelect ? itemsToSelect : []
    if(updateUI) this.updateVisiblePartsSorted()
    console.log(this.selectedClipIds)
    // return idsOfSameClipitem
    return this.selectedClipIds
  }

  setSameOfSelectedClipIds(same: ReportTS.AllOfSame){
    // let allClipIds: ReportTS.SelectedItem[] = []

    // if (this.selectedClipIds.length > 0) {
    //   switch(same) {
    //     case 'file':
    //       // get ClipObjs with same fileId
    //       allClipIds = Array.from(this.clipItems.entries()).filter(([id,clip])=> clip.fileId === this.selectedClipObjs[0].fileId).map(([id])=> id)
    //       break
    //     case 'linked':
    //       // get ClipObjs with same fileId
    //       allClipIds = [...this.selectedClipObjs[0].linkedClipsIds, this.selectedClipIds[0].clipIdChain[this.selectedClipIds[0].clipIdChain.length-1]]
    //       console.log(this.selectedClipObjs[0])
    //       console.log(allClipIds)
    //       // allClipIds = Array.from(this.clipItems.entries()).filter(([id,clip])=> clip.fileId === this.selectedClipObjs[0].fileId).map(([id])=> id)
    //       break
    //     case 'dir':
    //       // get fileObj of originClipObj
    //       const fileObj = this.filePaths.get(this.selectedClipObjs[0].fileId)
    //       // 
    //       if (fileObj && fileObj.pathurl) {
    //         let fullPathParent = fileObj.pathurl.split('/')
    //         fullPathParent.pop()
    //         const fullPathParentWithoutChild = fullPathParent.join('/')
    //         const fileObjs = Array.from(this.filePaths.values()).filter(o => ( o.pathurl ? o.pathurl.includes(fullPathParentWithoutChild) : false ))
    //         allClipIds = Array.from(this.clipItems.entries()).filter(([id,clip])=> fileObjs.find(fileObj => fileObj.id === clip.fileId)).map(([id])=> id)
    //         // allClipIds = this.allClipItemsOriginal.filter(clip => fileObjs.find(fileObj => fileObj.id === clip.fileId)).map(clip => clip.id)
    //       }
    //       break
    //     case 'track':
    //       const track = Globals.getTrackOfClip([...this.activeSequence!.videotracks, ...this.activeSequence!.audiotracks], {key: 'id', val: this.selectedClipObjs[0].id}, this.clipItems)
    //       if (track) {
    //         // allClipIds = track.clips.map(clip => clip.id)
    //         allClipIds = track.clips.map(c => c.id)
    //       }
    //       break
    //     default:
    //       allClipIds = this.selectedClipIds
    //   }
    // }
    const items = this.selectedClipIds.map(item =>
      this.getTrackItemOfEverywhere({clipId: item.clipIdChain[item.clipIdChain.length-1]})!
      .getSameClips(same)
      .map(trackItem => trackItem.visjsItems)
      .reduce((acc, val) => acc.concat(val), [])
    ).reduce((acc, val) => acc.concat(val), [])
    this.setSelectedClipIds(items, true)
  }

  get selectedGroups(): ReportTS.ItemGroup[] {
    // const selClipIds = Globals.getPureIds( this.selectedClipIds )
    const selClipIds = this.selectedClipIds
    const uniqueSelectedIdsPure = Array.from(new Set(selClipIds))

    // check for groups
    if (this.activeSequence && this.filePaths.size > 0) {
      return Globals.groupIdsOfClip(this.selectedClipObjs).map(clipGroup => clipGroup.group!).filter(itemGroup => typeof itemGroup !== 'undefined')

      // const selectedClipObjs =  this.activeClipItems
      //   .filter(clipId => uniqueSelectedIdsPure.find(id => id === clipId.id))
      //   .map(clipId => this.clipItems.get(clipId.id)!)
      //   // .map(clip => ({...clip, fileObj: this.filePaths.find(fp => fp.id === clip.fileId) }))
      //   // .map(clip => {
      //   //   const file = this.filePaths.find(fp => fp.id === clip.fileId)
      //   //   const pathurl = file ? file.pathurl : undefined
      //   //   return {...clip, pathurl, 
      //   //     // source: this.getSourceOfClip(clip.id) 
      //   //   }
      //   // })

      // const groupIds = Globals.groupIdsOfClip(this.selectedClipObjs)
      // // selectedClipObjs.filter(clip => clip.groups)
      // //   .map(clip => clip.groups!.map(group => group.groupId))
      // //   .reduce((acc, val) => acc.concat(val), [])
      // //   // just stay with unique groupIds
      // //   .filter((v, i, a) => a.indexOf(v) === i)

      // return this.groupItemsOfIds(groupIds) //.map(group => ({...group, clipItems: group.clipItems ? group.clipItems
      //   // .map(clip => ({...clip, fileObj: this.filePaths.find(fp => fp.id === clip.fileId) }))
      //   // .map(clip => {
      //   //   const pathurl = clip.filePathItem?.pathurl
      //   //   return {...clip, pathurl, 
      //   //     // source: this.getSourceOfClip(clip.id) 
      //   //   }
      //   // }) as ReportTS.ClipWithPathSource[] : []
      // //}))

    }
    return []
  }

  setActiveSequenceId(activeSequenceId: string) {
    console.log(activeSequenceId)
    this.activeSequenceId = activeSequenceId
    // console.log(JSON.parse(JSON.stringify(this.activeSequence)))
  }

  setProject({activeSequenceId, sequences, filePaths, clipItems, errors}: {activeSequenceId: string, sequences: ReportTS.Sequence[], filePaths: Map<string,ReportTS.FilePathItem>, clipItems: Map<string,Clip>, errors: GlobalTS.Error[]}) {
    this.activeSequenceId = activeSequenceId
    this.sequences = sequences
    this.filePaths = filePaths //this.applyRulesOnFileItems(filePaths)
    this.clipItems = clipItems
    this.clipItems.forEach(clipItem => clipItem.addStore(this))
    this.linkClips()
    this.operationStack = []
    this.errors = errors
    console.log(sequences)
    console.log(clipItems)
  }

  setVisRange(start: number, end: number) {
    this.sequences.find(seq => seq.id === this.activeSequenceId)!.visRange = {start, end}
  }

  get firstClip() {
    return this.activeSequence?.videotracks[1].clips[0]
  }
  // get allClipItems(): {clipId: string, trackType: ReportTS.TrackType}[] {
  //   if (this.sequences.length > 0) {
  //     const videoClips = this.sequences.map(seq => seq.videotracks.map(vt => vt.clips).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []).map(clipId => ({clipId, trackType: 'video' as ReportTS.TrackType}))
  //     const audioClips = this.sequences.map(seq => seq.audiotracks.map(vt => vt.clips).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []).map(clipId => ({clipId, trackType: 'audio' as ReportTS.TrackType}))
  //     return [ ...videoClips, ...audioClips ]
  //   }
  //   return []
  // }

  // get allClipItemsOriginal(): ReportTS.ClipProps[] {
  //   if (this.sequences.length > 0) {
  //     const videoClips = this.sequences.map(seq => seq.videotracks.map(vt => vt.clips).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), [])
  //     const audioClips = this.sequences.map(seq => seq.audiotracks.map(vt => vt.clips).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), [])
  //     return [ ...videoClips, ...audioClips ]
  //   }
  //   return []
  // }

  get activeListVideo() {
    if (this.activeSequence?.visiblePartsSorted) {
      return this.activeSequence.visiblePartsSorted.list
    } else if (this.activeSequence) {
      this.updateVisiblePartsSorted()
      if(this.activeSequence.visiblePartsSorted){
        return this.activeSequence.visiblePartsSorted.list
      }
    }
    return [] 
  }

  get activeListAudio() {
    if (this.activeSequence?.audiblePartsSorted) {
      return this.activeSequence.audiblePartsSorted.list
    } else if (this.activeSequence) {
      this.updateVisiblePartsSorted()
      if(this.activeSequence.audiblePartsSorted){
        return this.activeSequence.audiblePartsSorted.list
      }
    }
    return [] 
  }

  // getSequenceIdOfClip(clipId: string) {
  //   const seqVideo = this.getSequenceOfClip(clipId)
  //   return seqVideo ? seqVideo.id : undefined
  // }

  getSequenceOfClip(clipId: string) {
    return this.sequences.find(seq => seq.videotracks.find(videotrack => videotrack.clips.find(vClipId => vClipId.id === clipId)) || seq.audiotracks.find(audiotrack => audiotrack.clips.find(aClipId => aClipId.id === clipId)) )
  }

  groupItemsOfId(groupId: number) {
    if(this.activeSequence){
      return this.activeSequence.itemGroups.get(groupId)?.clipItems??[]
    }
    return []
    // return Array.from(this.clipItems.entries()).filter(([id,clip]) => clip.groups ? clip.groups.find(group => group. === groupId) : false)
    // return this.activeClipItems.map(clipId => this.clipItems.get(clipId.id)!).filter(clip => clip.groups ? clip.groups.find(group => group.groupId === groupId) : false)
  }

  groupItemsOfIds(groupIds: number[]): ReportTS.ItemGroup[] {
    return groupIds
      .map(groupId => this.getGroup(groupId))
      .filter(group => typeof group !== 'undefined') as ReportTS.ItemGroup[]
  }

  getGroup(groupId: number) {
    if(this.activeSequence){
      return this.activeSequence.itemGroups.get(groupId)
    }
    // return this.activeSequence?.itemGroups.find(iG => iG.id === groupId)
  }

  getFileObj(fileId: string){
    return this.filePaths.get( fileId )
  }

  // changeGroupName({groupId, newName}:{groupId: number, newName: string}) {
  //   console.log(newName)
  //   console.log(groupId)
  //   const itemGroup = this.getGroup(groupId)
  //   // const itemGroup = this.activeSequence ? this.activeSequence.itemGroups.find(iG => iG.id === groupId) : undefined
  //   if (itemGroup) itemGroup.name = newName
  // }

  changeGroupName({group, newName}:{group: ReportTS.ItemGroup, newName: string}) {
    group.name = newName
    // const itemGroup = this.getGroup(groupId)
    // const itemGroup = this.activeSequence ? this.activeSequence.itemGroups.find(iG => iG.id === groupId) : undefined
    // if (itemGroup) itemGroup.name = newName
  }

  get activeSequenceVisRange(){
    if (this.activeSequence) {
      // console.log(aS)
      // console.log(STARTTIME)
      const seqDuration = this.activeSequence.timeTotal.frames / this.activeSequence.timeBase
      const endTime = STARTTIME + ( seqDuration +5 )*1000
      const defaultTimes = {start: STARTTIME, min: STARTTIME, end: endTime, max: endTime, zoomMax: (seqDuration+5)*1000}
      const retVal = this.activeSequence.visRange ? {...this.activeSequence.visRange, min: STARTTIME, max: endTime, zoomMax: (seqDuration+5)*1000} : defaultTimes
      // console.log(retVal)
      return retVal
    }
    return {}
  }

  get selectedClipObjs(){
    if (this.filePaths.size > 0) {
      console.log(this.selectedClipIds)
      return this.selectedClipIds.map(selectedItem => this.clipItems.get(selectedItem.clipIdChain[selectedItem.clipIdChain.length-1])).filter(c => typeof c !== 'undefined') as Clip []

      // const clipIds = Globals.getClipIds(this.selectedClipIds)
      // const parentClipIds = this.getParentClipIdsOf(this.selectedClipIds)
      // // console.log(this.selectedClipIds)
      // // console.log(parentClipIds)
      // return clipIds.map(clipId => this.clipItems.get(clipId)).filter(c => typeof c !== 'undefined') as Clip []

      // return this.allClipItems
      //   .filter(clip => clipIds.find(id => id === clip.id))
      //   .map(clip => {
      //     const file = this.filePaths.get(clip.fileId)
      //     const pathurl = file ? file.pathurl : undefined
      //     const parentClipIdItem = parentClipIds.find(item => item && item.id === clip.id)
      //     const visible = this.activeListVideo.filter(clipList => clipList.ids.find(id => id === clip.id)).map(clipList => ({start: clipList.start, end: clipList.end, duration: clipList.duration}))
      //     return {...clip, pathurl, visible,
      //       // source: this.getSourceOfClip(clip.id), 
      //       parentClipId: parentClipIdItem ? parentClipIdItem.parentClipId : undefined }
      //   })

      //   // .map(clip => ({...clip, fileObj: this.filePaths.find(fp => fp.id === clip.fileId) }))
    }
    return []
  }

  // getParentClipIdsOf(visjsIds: string[]){
  //   return visjsIds.map(visjsId => {
  //     const visjsItem = this.activeSequenceVisjs ? this.activeSequenceVisjs.items.find(item => 
  //       (item.id === visjsId)
  //     ) : undefined
  //     const clipId = visjsId.match(/clipitem-\d+/) ? visjsId.match(/clipitem-\d+/)![0] : ''
  //     return visjsItem ? {id: clipId, parentClipId: visjsItem.parentClipId} : undefined
  //   })
  // }

  getClipOfSubSequence(sequenceId: string){
    return Array.from(this.clipItems.values()).find(clip => clip.sequenceId === sequenceId)
    // return this.allClipItems.find(clip => clip.sequenceId && clip.sequenceId === sequenceId)
  }

  setForAll({key, value}: {key: ReportTS.ClipKeysMod | 'transparence', value: any}) {
    this.actualOperationStack = []
    this.forEachVideoClip(clip => {
      // (clip[key] as any) = value
      this.modifyClipAndStackIt({clip, modification: {key, value, mode: 'change'}})
      return clip
    }, true)
    this.operationStack.push({id: 1, time: new Date(), name: 'alle Clips verändern', operations: this.actualOperationStack})
  }

  forEachVideoClip(callback: (clip: Clip) => Clip, justActiveSequence = false, justInTracks: string[] = []) {
    this.sequences.forEach(sequence => {
      if(!justActiveSequence || ( justActiveSequence && this.activeSequenceId === sequence.id ) ) {
        sequence.videotracks.forEach(videotrack => {
          if(justInTracks.length === 0 || justInTracks.find(trackIdx => trackIdx === videotrack.name)) {
            videotrack.clips.forEach(clipId => {
              // videotracks = [...data!.videotracks]
              const clip = this.clipItems.get(clipId.id)!
              callback(clip)
            })
          }
        })
      }
    })
    this.updateVisiblePartsSorted()
  }

  forEachAudioClip(callback: (clip: Clip) => Clip, justActiveSequence = false) {
    this.sequences.forEach(sequence => {
      if(!justActiveSequence || ( justActiveSequence && this.activeSequenceId === sequence.id ) ) {
        sequence.audiotracks.forEach(audiotrack => {
          audiotrack.clips.forEach(clipId=> {
            // audiotracks = [...data!.audiotracks]
            const clip = this.clipItems.get(clipId.id)!
            callback(clip)
          })
        })
      }
    })
    this.updateVisiblePartsSorted()
  }

  updateVisiblePartsSorted() {
    if (this.activeSequence) {
      // const resultVisibleParts = Globals.getVisiblePartsSorted({
      //   sequences: this.sequences,
      //   activeSequenceId: this.activeSequenceId,
      //   timebase: activeSequence.timeBase,
      //   filePaths: this.filePaths})
      // const resultAudibleParts = Globals.getAudiblePartsSorted({
      //   aTracks: activeSequence.audiotracks,
      //   timebase: activeSequence.timeBase,
      //   filePaths: this.filePaths
      // })
      const resultVisibleParts = this.getVisiblePartsSorted()!
      const resultAudibleParts = this.getAudiblePartsSorted()!
      // console.log(JSON.parse(JSON.stringify(resultVisibleParts)))
      this.sequences.find(seq => seq.id === this.activeSequenceId)!.visiblePartsSorted! = resultVisibleParts.visiblePartsSorted
      this.sequences.find(seq => seq.id === this.activeSequenceId)!.audiblePartsSorted! = resultAudibleParts.audiblePartsSorted
    }
  }

  getTrackClip(clipId: string){
    let trackClip: TrackClip | undefined
    if(this.activeSequence){
      this.activeSequence.visiblePartsSorted?.tracks.every(track => {
        trackClip = track.clips.find(tClip => tClip.clip?.id === clipId )
        return typeof trackClip === 'undefined'
      })
      if(typeof trackClip === 'undefined')
        this.activeSequence.audiblePartsSorted?.tracks.every(track => {
          trackClip = track.clips.find(tClip => tClip.clip?.id === clipId )
          return typeof trackClip === 'undefined'
        })
    }
    return trackClip
  }

  isAudioOfVideo(fileId: string) {
    const found = Array.from(this.filePaths.values()).filter(filePath => 
      filePath.id === fileId && filePath.type === 'video'
    )
    if(found.length > 0) return true
    return false
  }

  getAudiblePartsSorted (sequenceId?: string) {
    if(this.activeSequence){
      // const time = new Time({format: this.timeFormat, timebase: this.activeSequence.timeBase})
    
      // **** Start creating for visJS
      let visGroups = this.activeSequence.audiotracks.map( (obj, i) => ({id: 'a'+i, content: 'a'+obj.name, order: i}) )
    
      // take all and put as expected
      let visItemsExpected: ReportTS.VisjsItem[] = this.activeSequence.audiotracks.map((aTrack, i) => {
        return aTrack.clips.map(clipId => {
          const clip = this.clipItems.get(clipId.id)!
          let classnames = 'expected'
          classnames += clip.getAttribute('ignore')?.value ? ' ignore' : ''
          // classnames += (o.ignore !== undefined && o.ignore === true) ? ' ignore' : ''
          classnames += clip.pproColorLabel ? ' pproColorLabel-'+clip.pproColorLabel : ''
          // classnames += clip.isAdjustmentLayer ? ' isAdjustmentLayer' : ''
          // classnames += clip.isGraphicsElement ? ' isGraphicsElement' : ''
          classnames += clip.itemType && clip.itemType === 'adjustmentLayer' ? ' isAdjustmentLayer' : ''
          classnames += clip.itemType && clip.itemType === 'graphic' ? ' isGraphicsElement' : ''
          const tooltipContainer = document.createElement('div')
          tooltipContainer.setAttribute('class', 'tooltip-container')
          tooltipContainer.innerHTML = `<div class="tooltip-header"><span class="tooltip-title">${clip.name}</span><br/><span>${clip.times.startFormatted(this.timeFormat)}</span> - <span>${clip.times.endFormatted(this.timeFormat)}</span> = <span>${clip.times.durationFormatted(this.timeFormat)}</span></div>`
          return {
            id: 'a-'+clip.id+'_00',
            clipId: clip.id,
            content: clip.name,
            // title: `<span class="tooltip-title">${clip.name}</span><br/><span>${time.getFormated({frames: clip.times.start})}</span> - <span>${time.getFormated({frames: clip.times.end})}</span> = <span>${time.getFormated({frames: clip.times.duration})}</span>`,
            // title: `<span class="tooltip-title">${clip.name}</span><br/><span>${clip.times.startFormatted(this.timeFormat)}</span> - <span>${clip.times.endFormatted(this.timeFormat)}</span> = <span>${clip.times.durationFormatted(this.timeFormat)}</span>`,
            title: tooltipContainer,
            start: STARTTIME + (clip.times.start!/this.activeSequence!.timeBase)*1000,
            end: STARTTIME + (clip.times.end!/this.activeSequence!.timeBase)*1000,
            group: 'a'+i,
            // clipIdChain: JSON.stringify(obj.trackClip.idChain),
            clipIdChain: JSON.stringify(clip.id),
            className: classnames,sequenceId: clip.sequenceId,
          }
        })
      })
      // reduce all to one layer
      .reduce((acc, val) => acc.concat(val), [])
    
      // console.log(visItemsExpected)
    
      // lets get the visible Items
      let visItemsAudible: ReportTS.VisjsItem[] = this.activeSequence.audiotracks.map((aTrack, i) => {
        return aTrack.clips.map(clipId => {
          const clip = this.clipItems.get(clipId.id)!
          let classnames = ''
          classnames += clip.getAttribute('ignore')?.value ? ' ignore' : ''
          // classnames += clip.ignore ? ' ignore' : ''
          classnames += clip.pproColorLabel ? ' pproColorLabel-'+clip.pproColorLabel : ''
          // classnames += clip.isAdjustmentLayer ? ' isAdjustmentLayer' : ''
          // classnames += clip.isGraphicsElement ? ' isGraphicsElement' : ''
          classnames += clip.itemType && clip.itemType === 'adjustmentLayer' ? ' isAdjustmentLayer' : ''
          classnames += clip.itemType && clip.itemType === 'graphic' ? ' isGraphicsElement' : ''
          const tooltipContainer = document.createElement('div')
          tooltipContainer.setAttribute('class', 'tooltip-container')
          tooltipContainer.innerHTML = `<div class="tooltip-header"><span class="tooltip-title">${clip.name}</span><br/><span>${clip.times.startFormatted(this.timeFormat)}</span> - <span>${clip.times.endFormatted(this.timeFormat)}</span> = <span>${clip.times.durationFormatted(this.timeFormat)}</span></div>`
          return {
            id: 'a-'+clip.id,
            clipId: clip.id,
            // fileId: clip.fileId,
            content: clip.name,
            // title: `<span class="tooltip-title">${clip.name}</span><br/><span>${time.getFormated({frames: clip.start.frames})}</span> - <span>${time.getFormated({frames: clip.end.frames})}</span> = <span>${time.getFormated({frames: clip.duration.frames})}</span>`,
            // title: `<span class="tooltip-title">${clip.name}</span><br/><span>${clip.times.startFormatted(this.timeFormat)}</span> - <span>${clip.times.endFormatted(this.timeFormat)}</span> = <span>${clip.times.durationFormatted(this.timeFormat)}</span>`,
            title: tooltipContainer,
            start: STARTTIME + (clip.times.start!/this.activeSequence!.timeBase)*1000,
            end: STARTTIME + (clip.times.end!/this.activeSequence!.timeBase)*1000,
            // duration: {frames: clip.duration.frames},
            // clipIdChain: JSON.stringify(obj.trackClip.idChain),
            clipIdChain: JSON.stringify(clip.id),
            group: 'a'+i,
            // visible: clip.visible,
            // ignore: clip.ignore,
            // sequenceId: clip.sequenceId,
            className: classnames}
        })
      }).reduce((acc, val) => acc.concat(val), [])
      // only audible
      .filter(obj => (obj.end - obj.start > 0) )
      // console.log(visItemsAudible)
      const visItems = visItemsExpected.concat(visItemsAudible)
    
    
      const audiblePartsSorted = this.activeSequence.audiotracks.reduce((acc,val,trackIdx) => acc.concat(val.clips.map(trackClip => {
        const clip = this.clipItems.get(trackClip.id)!
        return {trackClip: new TrackClip({clip}), visibleIndex: 0, group: `a${trackIdx}`}
        // return {...trackClip, times: clip.times, parent: {sequenceId: clip.parent.sequenceId}}
      })), [] as ReportTS.TrackClipPart[]).filter(part => part.trackClip.clipTimes.duration > 0)
      .sort((a,b) => a.trackClip.clipTimes.start - b.trackClip.clipTimes.start)

      // // ***** Start creating for the list
      // const audiblePartsSorted = this.activeSequence.audiotracks.map(track => 
      //   track.clips.map(clipId => {
      //     const clip = this.clipItems.get(clipId.id)!
      //     const res = clip.times.visible.filter(vP => ( vP.duration > 0 ) )
      //       .map((vP,i) => ([{clipId: clip.id, visibleIndex: i, start: vP.start, end: vP.end, fileId: clip.fileId}]))
      //       .reduce((acc, val) => {
      //         const lastVal  = acc.slice(-1)[0]?.slice(-1)[0]
      //         if(lastVal && lastVal.fileId === val[0].fileId && lastVal.end+this.activeSequence!.timeBase*0.6 >= val[0].start && lastVal.start < val[0].start) {
      //           acc.pop()
      //           acc.push([lastVal, val[0]])
      //         } else {
      //           acc.push(val)
      //         }
      //         return acc
      //       }, [] as { clipId: string, visibleIndex: number, start: number, end: number, fileId: string}[][])
      //     return res
      //   })
      //   // reduce all ListParts of the clip arrays to one -> single "clips"
      //   .reduce((acc, val) => acc.concat(val), [])
      // )    
      // // reduce all clip arrays of the tracks to one -> single track
      // .reduce((acc, val) => acc.concat(val), [])
      // .sort( (a,b) => a.map(p => p.start).sort((pa,pb)=> pa-pb)[0] - b.map(p => p.start).sort((pa,pb)=> pa-pb)[0])
      // .map((part, i) => {
      //   return {key: 'listitem'+i, clipParts: part.map(part => ({clipId: part.clipId, visibleIndex: part.visibleIndex}))}
      // })
      // // // get all the clips
      // // const audiblePartsSorted: ReportTS.ClipList[] = this.activeSequence.audiotracks.map(obj => ( obj.clips ) )
      // // // reduce all clip arrays of the tracks to one
      // // .reduce((acc, val) => acc.concat(val), [])
      // // .map(clipId => this.clipItems.get(clipId)!)
      // // // add for each visible part name, id, file, transparence, ignore, rights
      // // .map(obj => {
      // //   // console.log(getRightsInfo(filePaths, obj.fileId))
      // //   // override id with array
      // //   // let rights = obj.rights !== undefined ? obj.rights : getRightsInfo(filePaths, obj.fileId).rights
      // //   // console.log(getRightsInfo(filePaths, obj))
      // //   return( 
      // //   {...obj, ids: [obj.id], sequenceId: undefined, attributes: obj.attributes, originalClipTimes: {
      // //     start: {frames: obj.times.start}, end: {frames: obj.times.end}, duration: {frames: obj.times.duration}
      // //   }, 
      // //   start: {frames: obj.times.start}, end: {frames: obj.times.end}, duration: {frames: obj.times.duration}
      // //   // start: obj.times.start, end: obj.times.end, duration: obj.times.duration
      // // }
      // // )} )
      // // // filter only audible ones
      // // .filter(obj => ( obj.times.duration > 0 ) )
      // // // sort by time - start.frame
      // // .sort( (a,b) => ( a.times.start - b.times.start ) )
      // // // .map( clip => )
      // // // add a key for react
      // // .map( (obj, i) => ( {...obj, key: 'audioListitem'+i} ) )
    
      // // // console.log(audiblePartsSorted)
    
      // // get the last frame to get the total length of the video
      // // let lastFrame = audiblePartsSorted.slice(-1)[0].end.frames
    
      return {
        audiblePartsSorted: {
          tracks: JSON.parse(JSON.stringify(this.activeSequence.audiotracks)),
          list: audiblePartsSorted, 
          visjs: {
            items: visItems, 
            groups: visGroups
          }
        }
      }
    }
  }

  getVisiblePartsSorted () {
    const activeSequence = this.activeSequence
    
    if(activeSequence) {
      // const time = new Time({format: this.timeFormat, timebase: activeSequence.timeBase})
      // input sub-sequences
      const vTracks = this.getVTracks({sequenceId: activeSequence.id})
  
      // **** Start creating for visJS
      const visGroups = vTracks.map( (obj, i) => ({id: 'v'+i, content: 'v'+obj.name, order: i}) )
      
      // take all and put as expected
      const visItemsExpected: ReportTS.VisjsItem[] = vTracks
      .map((track, i) => track.clips.map(trackClip => ({trackClip: trackClip, track: i})))
      .reduce((acc, val) => acc.concat(val), [])
      .map((obj, i) => {
        const parentClipIdStringAdd = obj.trackClip.parentTrackClip ? '-parent-'+obj.trackClip.parentTrackClip.clip.id.match(/\d+/)![0] : ''
        // const parentClip = obj.trackClip.parentTrackClip ? obj.trackClip.parentTrackClip.clip : undefined
        const tooltipContainer = document.createElement('div')
        tooltipContainer.setAttribute('class', 'tooltip-container')
        tooltipContainer.innerHTML = `<div class="tooltip-header"><span class="tooltip-title">${obj.trackClip.nameChain.join( " » " )}</span><br/><span>${obj.trackClip.clipTimes.startFormatted(this.timeFormat)}</span> - <span>${obj.trackClip.clipTimes.endFormatted(this.timeFormat)}</span> = <span>${obj.trackClip.clipTimes.durationFormatted(this.timeFormat)}</span></div>`
        return {
          // id: 'v-'+obj.clip.id+ parentClipIdStringAdd +'_0'+obj.track+i, 
          id: `v-${obj.trackClip.clip.id}${parentClipIdStringAdd}_0${obj.track}${i}`, 
          // content: typeof this.getAttributeOfClip({clip: obj.trackClip.clip, attribute: 'name'}).value === 'string' ? this.getAttributeOfClip({clip: obj.trackClip.clip, attribute: 'name'}).value as string : '',
          // title: `<span class="tooltip-title">${obj.trackClip.clip.name}</span><br/><span>${time.getFormated({frames: obj.trackClip.clip.start.frames})}</span> - <span>${time.getFormated({frames: obj.trackClip.clip.end.frames})}</span> = <span>${time.getFormated({frames: obj.trackClip.clip.duration.frames})}</span>`,
          // title: '<span class="tooltip-title">'+obj.trackClip.clip!.name+'</span><br/><span>'+Globals.getTimecodeMS(obj.trackClip.clip!.start.frames, activeSequence.timeBase)+'</span> - <span>'+Globals.getTimecodeMS(obj.trackClip.clip!.end.frames, activeSequence.timeBase)+'</span> = <span>'+Globals.getTimecodeMS(obj.trackClip.clip!.duration.frames, activeSequence.timeBase)+'</span>',
          content: obj.trackClip.clip.getAttribute('name')?.getValueString() ?? '',
          // title: `<span class="tooltip-title">${obj.trackClip.clip.getAttribute('name')?.getValueString() ?? obj.trackClip.clip.name}</span><br/><span>${obj.trackClip.clip.times.startFormatted(this.timeFormat)}</span> - <span>${obj.trackClip.clip.times.endFormatted(this.timeFormat)}</span> = <span>${obj.trackClip.clip.times.durationFormatted(this.timeFormat)}</span>`,
          title: tooltipContainer,
          // start: STARTTIME + clipTimes.startSecondsAll*1000, 
          // end: STARTTIME + clipTimes.endSecondsAll*1000, 
          start: STARTTIME + obj.trackClip.clipTimes.startSecondsAll*1000, 
          end: STARTTIME + obj.trackClip.clipTimes.endSecondsAll*1000, 
          group: `v${obj.track}`, 
          className: `expected ${obj.trackClip.visjsClassnames.join(' ')}`, 
          clipId: obj.trackClip.clip.id,
          clipIdChain: JSON.stringify(obj.trackClip.idChain),
          // parentClipId: obj.trackClip.clip.parent.clipIds[0] 
        }
      })

      // lets get the visible Items
      const trackClipParts = vTracks.map((vTrack, i) => {
        return vTrack.clips.map(clipPart => ({
          trackClip: clipPart,
          // visjsId: clipId,
          group: 'v'+i, 
        }))
      }).reduce((acc, val) => acc.concat(val), [])

      .reduce((prev, curr) => {
        curr.trackClip.clipVisible.forEach((times,vI) => {
          // const trackClip = new TrackClip({...curr.trackClip, visibleIndex: vI})
          // trackClip.setTime(times)
          prev.push({...curr, visibleIndex: vI})
        })
        return prev
      }, [] as ReportTS.TrackClipPart[])

      // just get the clips that are visible longer than half a second
      // .filter(clipPart => clipPart.trackClip.duration > 0)
      .filter(clipPart => clipPart.trackClip.clipVisible[clipPart.visibleIndex].duration > 0)

      const visItemsVisible : ReportTS.VisjsItem[] = trackClipParts
      .map((obj,j) => ({
          ...obj,
          visjsId: `v-${obj.trackClip.clip.id}_1${j}`,
          className: obj.trackClip.visjsClassnames.join(' ')
        }
      ))
      // all to one layer
      // .reduce((acc, val) => acc.concat(val), [])
      // only visible and existig, because parent-sequences are returned as undefined
      // .filter(obj => (obj && obj.clip.times.duration > 0) )
      .map(obj => {
        const parentClip = obj.trackClip.parentTrackClip?.clip
        const clipTimes: Times = parentClip ? obj.trackClip.clip.times.returnWithOffset(parentClip.times.subClipsOffset).returnInsideRange({rangeStart: parentClip.times.start, rangeOut: parentClip.times.end}) : obj.trackClip.clipTimes
        const titleTimeTotal = `<span class="tooltip-time total">${clipTimes.startFormatted(this.timeFormat)} - ${clipTimes.endFormatted(this.timeFormat)} = ${clipTimes.durationFormatted(this.timeFormat)}</span>`
        // const titleTimeParts = obj? obj.trackClip.clip.times.visible.map((vP,i) => `<span class="tooltip-time parts${true? ' selected':''}">${vP.startFormatted(this.timeFormat)} - ${vP.endFormatted(this.timeFormat)} = ${vP.durationFormatted(this.timeFormat)}</span>`) : []
        const titleTimeParts = obj? obj.trackClip.clipVisible.sort((a,b)=>a.start-b.start).map((vP,i) => `<span class="tooltip-time parts${i===obj.visibleIndex? ' selected':''}">${vP.startFormatted(this.timeFormat)} - ${vP.endFormatted(this.timeFormat)} = ${vP.durationFormatted(this.timeFormat)}</span>`) : []

        // const titleTimeTotal = `<span class="tooltip-time total">${obj?.clip.times.startFormatted(this.timeFormat)} - ${obj?.clip.times.endFormatted(this.timeFormat)} = ${obj?.clip.times.durationFormatted(this.timeFormat)}</span>`
        // const titleTimeParts = obj? obj.clip.times.visible.map((vP,i) => `<span class="tooltip-time parts${true? ' selected':''}">${vP.startFormatted(this.timeFormat)} - ${vP.endFormatted(this.timeFormat)} = ${vP.durationFormatted(this.timeFormat)}</span>`) : []
        const tooltipContainer = document.createElement('div')
        tooltipContainer.setAttribute('class', 'tooltip-container')
        tooltipContainer.innerHTML = `<div class="tooltip-header"><span class="tooltip-title">${obj.trackClip.nameChain.join( " » " )}</span><br/>${titleTimeTotal}</div><div class="tooltip-content">${(titleTimeParts?titleTimeParts:[]).join('<br/>')}</div>`
        return ({
          id: obj.visjsId,
          // content: typeof this.getAttributeOfClip({clip: obj?.clip, attribute: 'name'}).value === 'string' ? this.getAttributeOfClip({clip: obj!.clip, attribute: 'name'}).value as string : '',
          // title: `<span class="tooltip-title">${obj!.name}</span><br/><span>${time.getFormated({frames: obj!.start.frames})}</span> - <span>${time.getFormated({frames: obj!.end.frames})}</span> = <span>${time.getFormated({frames: obj!.duration.frames})}</span>`,
          // title: '<span class="tooltip-title">'+obj!.name+'</span><br/><span>'+Globals.getTimecodeMS(obj!.start.frames, activeSequence.timeBase)+'</span> - <span>'+Globals.getTimecodeMS(obj!.end.frames, activeSequence.timeBase)+'</span> = <span>'+Globals.getTimecodeMS(obj!.duration.frames, activeSequence.timeBase)+'</span>',
          content: obj.trackClip.clip.getAttribute('name')?.getValueString() ?? '',
          title: tooltipContainer,
          start: STARTTIME + obj.trackClip.clipVisible[obj.visibleIndex].startSecondsAll*1000, 
          end: STARTTIME + obj.trackClip.clipVisible[obj.visibleIndex].endSecondsAll*1000, 
          group: obj.group,
          className: obj.className,
          clipId: obj.trackClip.clip.id,
          clipIdChain: JSON.stringify(obj.trackClip.idChain),
          // parentClipId: obj.trackClip.parentTrackClip?.clip.id
        })
      })
      
      return { 
        visiblePartsSorted: {
          tracks: vTracks,
          list: trackClipParts.sort((a,b) => a.trackClip.clipTimes.start - b.trackClip.clipTimes.start), 
          visjs: {
            // reverse keeps order in subsequences for UI
            items: visItemsExpected.reverse().concat(visItemsVisible.reverse()), 
            groups: visGroups
          }
        }
      } 
    }
  }

  getVTracks({sequenceId, range}:{sequenceId: string, range?: {inPoint: number, outPoint: number}}) : ReportTS.SortedTrack[] {
    let seq = this.sequences.find(seq => seq.id === sequenceId )

    // this.clipItems.forEach((clip) => { clip.times.resetVisibleParts() })
    let coveredFrames: {start: number, end: number}[] = []

    const sortedTrack = seq!.videotracks.map(vTrack => {
      let clips = vTrack.clips
      .map(clip => {
        const trackClip = new TrackClip({
          clip: this.clipItems.get( clip.id )!
        })
        // console.log(clip)
        // console.log(trackClip)
        // trackClip.clip.times.resetVisibleParts()
        return trackClip
      })
      .filter(trackClip => {
        // skip clips outside of range
        return ! ( range && ( trackClip.clipTimes.end < range.inPoint || range.outPoint < trackClip.clipTimes.start) )
      })

      // include clips of subsequence if unfolded
      .map(trackClip => {
        return trackClip.childTrackClips.filter(tC => tC.clipTimes.duration > 0) //.reverse()
      })
      // reduce arrays of trackClips to one layer
      .reduce((acc, val) => acc.concat(val), [])
      .map(trackClip => {trackClip.resetVisibleParts(); return trackClip})
      .map(trackClip => {
        // trackClip.resetVisibleParts()
        // console.log(`${trackClip.clip.getAttribute('name')?.getValueString()} in ${seq?.name}`)
        trackClip.clipVisible.forEach((vClipVisible, visibleIndex) => {
          if (!(vClipVisible.start === 0 && vClipVisible.end === 0)) {
            // get all covered frames
            checkFrames: for (let i=0; i < coveredFrames.length; i++) {
              switch (true) {
                case coveredFrames[i].end < vClipVisible.start:
                  // not jet reached the clip .. check next
                  break
                case coveredFrames[i].start <= vClipVisible.start && vClipVisible.end <= coveredFrames[i].end :
                  // completely covered --> not visibile
                  // vClipVisible.setTime({start: 0, end: 0})
                  trackClip.setVisibleTime({start: 0, end: 0, visibleIndex})
                  break checkFrames
                case coveredFrames[i].start <= vClipVisible.start && vClipVisible.start < coveredFrames[i].end :
                  // start is covered
                  // vClipVisible.setTime({start: coveredFrames[i].end})
                  trackClip.setVisibleTime({start: coveredFrames[i].end, visibleIndex})
                  break;
                case vClipVisible.start < coveredFrames[i].start && coveredFrames[i].end < vClipVisible.end:
                  // middle part is covered
                  if(coveredFrames[i].end < vClipVisible.end) {
                    // const last = vClip.times.visible.slice(-1)[0]
                    // vClip.addVisible({start: last.start, end: coveredFrames[i].start})
                      // const last = trackClip.clip.times.visible.slice(-1)[0]
                      // trackClip.clip.addVisible({start: last.start, end: coveredFrames[i].start})
                    // const last = trackClip.clipVisible.slice(-1)[0]
                    // visibleTimesArray.push(new Times({timebase: clip.times.timebase, start: last.start, end: coveredFrames[i].start}))
                    // vClipVisible.setTime({start: coveredFrames[i].end})
                    const first = trackClip.clipVisible[0]
                    trackClip.addVisible({start: first.start, end: coveredFrames[i].start})
                    trackClip.setVisibleTime({start: coveredFrames[i].end, visibleIndex})
                  } else {
                    // vClipVisible.setTime({end: coveredFrames[i].start})
                    trackClip.setVisibleTime({end: coveredFrames[i].start, visibleIndex})
                  }
                  break;
                case coveredFrames[i].start < vClipVisible.end && vClipVisible.end <= coveredFrames[i].end:
                  // end is covered
                  // vClipVisible.setTime({end: coveredFrames[i].start})
                  trackClip.setVisibleTime({end: coveredFrames[i].start, visibleIndex})
                  break checkFrames
                case vClipVisible.end < coveredFrames[i].start:
                  // already passed the clip so stop checking
                  break checkFrames
                default:
                  // no part is covered
                  break;
              }
            }
            if( trackClip.parentTrackClip ) {
              console.log(`${trackClip.clip.name}: ${trackClip.transparence}`)
            }
            if( !trackClip.transparence )
              coveredFrames.push({start: trackClip.clipTimes.start, end: trackClip.clipTimes.end})
            
            // console.log( trackClip.getAttribute('name')?.getValueString() )
            // console.log( {transparence: trackClip.transparence, start: vClipVisible.start, end: vClipVisible.end} )
            // console.log( JSON.parse(JSON.stringify(coveredFrames)) )

            // concat covered Frames
            coveredFrames = coveredFrames.sort((a,b) => a.start - b.start).reduce((prev, curr) => {
              const lastElement = prev.slice(-1)[0]

              if( curr.start <= lastElement?.end ) {
                prev.pop()
                prev.push({...lastElement, end: curr.end > lastElement.end ? curr.end : lastElement.end})
              } else {
                prev.push(curr)
              }
              return prev
            }, [] as {start: number, end: number}[]).filter(cF => !(cF.end === 0 && cF.start === 0) )

            // console.log( JSON.parse(JSON.stringify(coveredFrames)) )
          }
        })
        return trackClip
        
      })

      if(typeof range !== 'undefined') {
        clips = clips
        // .map(clipPart => {
        //   // if(typeof range === 'undefined') return clipPart
        //   return {...clipPart, times: clipPart.times.returnInsideRange(range)}
        // })
        .filter(clipPart => {
          // if(typeof range === 'undefined') return true
          return clipPart.clipTimes.duration > 0
        })
      }

      return {
        name: vTrack.name, 
        clips: clips
      }
    })

    return sortedTrack
  }

  // getVTracks({sequenceId, range}:{sequenceId: string, range?: {inPoint: number, outPoint: number}}) : ReportTS.SortedTrack[] {
  //   let seq = this.sequences.find(seq => seq.id === sequenceId )
  //   // let vTracks = (JSON.parse(JSON.stringify(seq!.videotracks))) as ReportTS.Track[]

  //   // let vTracks = Object.assign(seq!.videotracks) as ReportTS.Track[]
  //   // let vTracks = seq!.videotracks

  //   // console.log(vTracks)

  //   this.clipItems.forEach((clip) => { clip.times.resetVisibleParts() })
  //   let coveredFrames: {start: number, end: number}[] = []

  //   const sortedTrack = seq!.videotracks.map(vTrack => {
  //     return {
  //       name: vTrack.name, 
  //       clips: vTrack.clips
  //       .filter(clip => {
  //         const vClip = this.clipItems.get( clip.id )!
  //         // skip clips outside of range
  //         return ! ( range && ( vClip.times.end < range.inPoint || range.outPoint < vClip.times.start) )
  //         // if (typeof range === 'undefined') return true
  //         // return range && ( range.inPoint < vClip.times.end || vClip.times.start < range.outPoint )
  //       })
  //       .map(clip => {
  //         const vClip = this.clipItems.get( clip.id )!
          
  //         // include clips of subsequence if unfolded
  //         if( vClip.sequenceId && vClip.getAttribute('sequenceUnfold')?.getValueBoolean(false) ) {
  //           const childSequence = this.getVTracks({sequenceId: vClip.sequenceId, range: (vClip.times.inPoint && vClip.times.outPoint ? {inPoint: vClip.times.inPoint, outPoint: vClip.times.outPoint} : undefined)})
  //           const childClips = childSequence.map(track => track.clips)
  //             .reduce((acc, val) => acc.concat(val), [])
  //             .map(clipPart => {
  //               // calculate offset to parent clip
  //               const offset = vClip.times.start - vClip.times.inPoint!
  //               const newStart = clipPart.times.start + offset
  //               const newEnd = clipPart.times.end + offset
  //               clipPart.times.setTime({
  //                 start: newStart < vClip.times.start ? vClip.times.start : newStart, // stay inside parent clip "borders"
  //                 end: newEnd > vClip.times.end ? vClip.times.end : newEnd // stay inside parent clip "borders"
  //               })
  //               clipPart.parent = {clipIds: [vClip.id], sequenceId: vClip.sequenceId!}
  //               return clipPart
  //             })
  //           return childClips
  //         }

  //         return [{...clip, times: new Times({timebase: vClip.times.timebase, start: vClip.times.start, end: vClip.times.end}), parent: { sequenceId }}]
  //       })
  //       .reduce((acc, val) => acc.concat(val), [])
  //       .map(clip => {
  //         const vClip = this.clipItems.get( clip.id )!

  //         // // skip clips outside of range
  //         // if( range && ( vClip.times.end < range.inPoint || range.outPoint < vClip.times.start) ) return {id: clip.id, times: [new Times({timebase: vClip.times.timebase, start: 0, end: 0})]} 

  //         // // include clips of subsequence if unfolded
  //         // if( vClip.sequenceId && vClip.getAttribute('sequenceUnfold')?.getValueBoolean(false) ) {
  //         //   const childSequence = this.getVTracks({sequenceId: vClip.sequenceId, range: (vClip.times.inPoint && vClip.times.outPoint ? {inPoint: vClip.times.inPoint, outPoint: vClip.times.outPoint} : undefined)})
  //         //   const childClips = childSequence.map(track => track.clips)
  //         //     .reduce((acc, val) => acc.concat(val), [])
  //         //     .map(clipPart => {
  //         //       // calculate offset to parent clip
  //         //       const offset = vClip.times.start - vClip.times.inPoint!
  //         //       const newStart = clipPart.times.start + offset
  //         //       const newEnd = clipPart.times.end + offset
  //         //       clipPart.times.setTime({
  //         //         start: newStart < vClip.times.start ? vClip.times.start : newStart, // stay inside parent clip "borders"
  //         //         end: newEnd > vClip.times.end ? vClip.times.end : newEnd // stay inside parent clip "borders"
  //         //       })
  //         //       clipPart.parent = {clipId: vClip.id, sequenceId: vClip.sequenceId!}
  //         //       return clipPart
  //         //     })
  //         // }

  //         let visibleTimes: Times[] = [clip.times]

  //         visibleTimes.map(vClipVisible => {

  //           if (!(vClipVisible.start === 0 && vClipVisible.end === 0)) {
  //             // (5)
  //             // debugger
  //             // get all covered frames
  //             checkFrames: for (let i=0; i < coveredFrames.length; i++) {
  //               switch (true) {
  //                 case coveredFrames[i].end < vClipVisible.start:
  //                   // not jet reached the clip .. check next
  //                   break
  //                 case coveredFrames[i].start <= vClipVisible.start && vClipVisible.end <= coveredFrames[i].end :
  //                   // completely covered --> not visibile
  //                   vClipVisible.setTime({start: 0, end: 0})
  //                   break checkFrames
  //                 case coveredFrames[i].start <= vClipVisible.start && vClipVisible.start < coveredFrames[i].end :
  //                   // start is covered
  //                   vClipVisible.setTime({start: coveredFrames[i].end})
  //                   break;
  //                 case vClipVisible.start < coveredFrames[i].start && coveredFrames[i].end < vClipVisible.end:
  //                   // middle part is covered
  //                   vClip.addVisible({start: coveredFrames[i].end, end: vClipVisible.end, sequenceId})
  //                   vClipVisible.setTime({end: coveredFrames[i].start})
  //                   break;
  //                 case coveredFrames[i].start < vClipVisible.end && vClipVisible.end <= coveredFrames[i].end:
  //                   // end is covered
  //                   vClipVisible.setTime({end: coveredFrames[i].start})
  //                   break checkFrames
  //                 case vClipVisible.end < coveredFrames[i].start:
  //                   // already passed the clip so stop checking
  //                   break checkFrames
  //                 default:
  //                   // no part is covered
  //                   break;
  //               }
  //             }
  //             if( !vClip.getAttribute('transparence')?.value )
  //               coveredFrames.push({start: vClipVisible.start, end: vClipVisible.end})
              
  //             // concat covered Frames
  //             coveredFrames = coveredFrames.sort((a,b) => a.start - b.start).reduce((prev, curr) => {
  //               const lastElement = prev.slice(-1)[0]

  //               if( curr.start <= lastElement?.end ) {
  //                 prev.pop()
  //                 prev.push({...lastElement, end: curr.end > lastElement.end ? curr.end : lastElement.end})
  //               } else {
  //                 prev.push(curr)
  //               }
  //               return prev
  //             }, [] as {start: number, end: number}[])
  //           }
  //         })
  //         return {...clip, times: visibleTimes}

  //         // vClip.times.visible.map(vClipVisible => {

  //           //   if (!(vClipVisible.start === 0 && vClipVisible.end === 0)) {
  //           //     // (5)
  //           //     // debugger
  //           //     // get all covered frames
  //           //     checkFrames: for (let i=0; i < coveredFrames.length; i++) {
  //           //       switch (true) {
  //           //         case coveredFrames[i].end < vClipVisible.start:
  //           //           // not jet reached the clip .. check next
  //           //           break
  //           //         case coveredFrames[i].start <= vClipVisible.start && vClipVisible.end <= coveredFrames[i].end :
  //           //           // completely covered --> not visibile
  //           //           vClipVisible.setTime({start: 0, end: 0})
  //           //           break checkFrames
  //           //         case coveredFrames[i].start <= vClipVisible.start && vClipVisible.start < coveredFrames[i].end :
  //           //           // start is covered
  //           //           vClipVisible.setTime({start: coveredFrames[i].end})
  //           //           break;
  //           //         case vClipVisible.start < coveredFrames[i].start && coveredFrames[i].end < vClipVisible.end:
  //           //           // middle part is covered
  //           //           vClip.addVisible({start: coveredFrames[i].end, end: vClipVisible.end, sequenceId})
  //           //           vClipVisible.setTime({end: coveredFrames[i].start})
  //           //           break;
  //           //         case coveredFrames[i].start < vClipVisible.end && vClipVisible.end <= coveredFrames[i].end:
  //           //           // end is covered
  //           //           vClipVisible.setTime({end: coveredFrames[i].start})
  //           //           break checkFrames
  //           //         case vClipVisible.end < coveredFrames[i].start:
  //           //           // already passed the clip so stop checking
  //           //           break checkFrames
  //           //         default:
  //           //           // no part is covered
  //           //           break;
  //           //       }
  //           //     }
  //           //     if( !vClip.getAttribute('transparence')?.value )
  //           //       coveredFrames.push({start: vClipVisible.start, end: vClipVisible.end})
                
  //           //     // concat covered Frames
  //           //     coveredFrames = coveredFrames.sort((a,b) => a.start - b.start).reduce((prev, curr) => {
  //           //       const lastElement = prev.slice(-1)[0]

  //           //       if( curr.start <= lastElement?.end ) {
  //           //         prev.pop()
  //           //         prev.push({...lastElement, end: curr.end > lastElement.end ? curr.end : lastElement.end})
  //           //       } else {
  //           //         prev.push(curr)
  //           //       }
  //           //       return prev
  //           //     }, [] as {start: number, end: number}[])
  //           //   }
  //           // })
          
  //       }).reduce((prev, curr) => {
  //         curr.times.forEach(times => {
  //           prev.push({...curr, times})
  //         })
  //         return prev
  //       }, [] as {id: string, times: Times, parent: {sequenceId: string, clipId?: string } }[])
  //       // .filter(clipPart => clipPart.times.duration > clipPart.times.timebase/2)
  //     }
  //   })
  //   // for (var i = 0; i < seq!.videotracks.length; i++) {
  //   //   // console.log(i +": " + vTracks[i].numberOfItems)

  //   //   // (2) for each clip in the track
  //   //   for (let j in seq!.videotracks[i].clips) {
  //   //     const vClip = this.clipItems.get( seq!.videotracks[i].clips[j] )!

  //   //     for (let vClipVisible of vClip.times.visible) {

          
  //   //     }
  //   //   }
  //   // }

  //   // delete empty visibles
  //   // this.clipItems.forEach((clip) => { clip.times.cleanVisibleParts() })

  //   return sortedTrack
  
  //   // // add Clips of SubSequence first
  //   // for (let i in vTracks) {
  //   //   let vClips = vTracks[i].clips
  //   //   for (let j in vClips) {
  //   //     const vClipId = vClips[j]
  //   //     const vClip = this.clipItems.get(vClipId)
  //   //     // let { sequenceId, sequenceUnfold } = vClip
        
  //   //     if (vClip?.sequenceId && vClip.getAttribute('sequenceUnfold')?.value) {
  //   //       // get vTracks of sub-sequence and input it
  //   //       const subVTracks = this.getVTracks({activeSequenceId: vClip.sequenceId})
  //   //       const result: Clip[] = subVTracks.map(obj => ( obj.clips ) )
  //   //         // reduce all clip arrays of the tracks to one
  //   //         .reduce((acc, val) => acc.concat(val), [])

  //   //         // add for each visible part name, id, file, transparence, ignore, rights
  //   //         .map(clipId => ( 
  //   //           this.clipItems.get(clipId)?.times.visible.map(visiblePart => ({}))
  //   //           clipId.times.visible!.map(o => {
  //   //             return ({
  //   //               ...clipId,
  //   //               ...o
  //   //               })}
  //   //             )
  //   //           )
  //   //         )
  //   //         // all parts to one layer
  //   //         .reduce((acc, val) => acc.concat(val), [])
  //   //         // filter only visible ones
  //   //         .filter(obj => ( obj.times.duration > 0 ) )
          
  //   //         // concat parts of similar clips which were cut into parts
  //   //         .reduce((acc: Clip[], val) => {
  //   //           if (acc.length > 0) {
  //   //             // get last element 
  //   //             let lastVal = acc.slice(-1)[0]
  //   //             // if (lastVal.id === val.id && lastVal.end.frames+15 >= val.start.frames) {
  //   //             if (lastVal.fileId === val.fileId && lastVal.times.end+seq!.timeBase*0.6 >= val.start) {
  //   //               let start = lastVal.start.frames
  //   //               acc.pop()
  //   //               let newVal = val
  //   //               newVal.start.frames = start
  //   //               newVal.duration.frames = lastVal.duration.frames + val.duration.frames
  //   //               newVal.id = lastVal.id.concat(val.id)
  //   //               acc.push(new Clip(newVal))
  //   //             } else {
  //   //               // ignore clips visible less than 15 frames
  //   //               if (val.duration.frames >= seq!.timeBase*0.6){
  //   //                 acc.push(new Clip(val))
  //   //               } else {
  //   //                 acc.push(new Clip({...val, attributes: {ignore: {value: true, type: 'clip'}} }) )
  //   //               }
  //   //             }
  //   //           } else {
  //   //             // ignore clips visible less than 15 frames
  //   //             if (val.duration.frames >= seq!.timeBase*0.6){
  //   //               acc.push(new Clip(val))
  //   //             } else {
  //   //               acc.push(new Clip({...val, attributes: {ignore: {value: true, type: 'clip'}} }) )
  //   //             }
  //   //           }
  //   //           return acc
  //   //         }, [])
  //   //         .map(o => {
  //   //           // put start and end inside of sub-sequence and ignore stuff outside in and out
  //   //           let startFrames = o.start.frames + vClip.start.frames - vClip.inPoint!.frames
  //   //           let endFrames = o.end.frames + vClip.start.frames - vClip.inPoint!.frames
  //   //           if (startFrames < vClip.start.frames && endFrames > vClip.start.frames) {
  //   //             startFrames = vClip.start.frames}
  //   //           if (endFrames > vClip.end.frames && startFrames < vClip.end.frames) {
  //   //             endFrames = vClip.end.frames}
  //   //           if (endFrames < vClip.start.frames || startFrames > vClip.end.frames) {
  //   //             startFrames = 0
  //   //             endFrames = 0
  //   //           }
  //   //           return(new Clip({
  //   //             ...o,
  //   //             visible: o.visible!.map( c => {
  //   //               // also of the visible parts
  //   //               let startFrames2 = c.start.frames + vClip.start.frames - vClip.inPoint!.frames
  //   //               let endFrames2 = c.end.frames + vClip.start.frames - vClip.inPoint!.frames
  //   //               if (startFrames2 < vClip.start.frames && endFrames2 > vClip.start.frames) {
  //   //                 startFrames2 = vClip.start.frames}
  //   //               if (endFrames2 > vClip.end.frames && startFrames2 < vClip.end.frames) {
  //   //                 endFrames2 = vClip.end.frames}
  //   //               if (endFrames2 < vClip.start.frames || startFrames2 > vClip.end.frames) {
  //   //                 startFrames2 = 0
  //   //                 endFrames2 = 0
  //   //               }
  //   //               return ( { ...c,
  //   //                 start: { frames: startFrames2 },
  //   //                 end: { frames: endFrames2 },
  //   //                 duration: { frames: endFrames2 - startFrames2 },
  //   //                 itemType: o.itemType
  //   //             } )} ),
  //   //             start: { frames: startFrames },
  //   //             end: { frames: endFrames },
  //   //             duration: { frames: endFrames - startFrames },
  //   //             parentSequence: vClip.sequenceId,
  //   //             parentClipId: vClip.id
  //   //           }))
  //   //         })
  //   //         .filter(o => 
  //   //           o.visible && o.visible.filter( c => 
  //   //             (c.start.frames < vClip.end.frames && c.end.frames > vClip.start.frames) || 
  //   //               (c.start.frames > vClip.end.frames && c.end.frames > vClip.start.frames) )
  //   //               .length > 0 ? true : false
  //   //           // get only in range  
  //   //           // (o.start.frames < vClip.end.frames && o.end.frames > vClip.start.frames) || (o.start.frames > vClip.end.frames && o.end.frames > vClip.start.frames)
  //   //         )
  //   //         // if sequence as clip is set to transparent the clips visible in the sub-sequence should be transparent too
  //   //         .map(o => {
  //   //           if (this.getAttributeOfClip({clip: vClip, attribute: 'transparence'}).value){
  //   //             o.attributes = {...o.attributes, transparence: {value: true, type: 'clip'}}
  //   //           }
  //   //           return o
  //   //         })
  
  //   //       vClip.attributes = {transparence: {value: true, type: 'clip'}, ignore: {value: true, type: 'clip'}}
  //   //       // vClip = {...vClip, attributes: {transparence: {value: true, type: 'clip'}, ignore: {value: true, type: 'clip'}} }
  //   //       vClips.push(...result)
  //   //       // console.log(vClips)
  //   //     }     
  //   //   }
  //   // }

  //   // --> End of add subsequences
  
  //   // add itemGroup element to items of Groups
  //   // vTracks = vTracks.map(track => (
  //   //   {...track, clips: track.clips.map(clip => {
  //   //     const itemGroup = seq.itemGroups.find(group => group.items.find(item => item.id === clip.id))
  //   //     return itemGroup ? {...clip, itemGroup: itemGroup} : clip
  //   //   })}
  //   // ))
  //   // console.log(JSON.parse(JSON.stringify(vTracks)))
  
  //   // (1) for each track
  //   // for (var i = vTracks.length-1; i >= 0; i--) {
  //     // return vTracks.map((vTrack) => {
  //     //   vTrack.clips.map((vClip, index) => {
  //     //     if (vClip.visible === undefined) {
  //     //       vClip.visible = []
  //     //       vClip.visible.push({'start': {'frames': vClip.start.frames}, 'end': {'frames': vClip.end.frames}, 'duration': {'frames': vClip.end.frames - vClip.start.frames}})
  //     //     }
  //     //     if (vClip.transparence === undefined || vClip.transparence === false) {
  //     //       let vClipVisibles = vClip.visible
  //     //       // (4)
  //     //       for (let k in vClipVisibles) {
  //     //         let vClipVisible = vClipVisibles[k]
    
  //     //         if (!(vClipVisible.start.frames === 0 && vClipVisible.end.frames === 0)) {
  //     //           // (5)
  //     //           // for (var l = i-1; l >= 0; l--) {
  //     //           for (var l = index + 1; l < vTracks.length; l++) {
  //     //             // console.log(l +": " + vTracks[l].numberOfItems)
  //     //             let vClipsLower = vTracks[l].clips
  //     //             // (6)
  //     //             for (let m in vClipsLower) {
  //     //               let vClipLower = vClipsLower[m]
  //     //               if (vClipLower.visible === undefined) {
  //     //                 vClipLower.visible = []
  //     //                 vClipLower.visible.push({'start': {'frames': vClipLower.start.frames}, 'end': {'frames': vClipLower.end.frames}, 'duration': {'frames': vClipLower.end.frames - vClipLower.start.frames}})
  //     //               }
  //     //               let vClipVisiblesLower = vClipLower.visible
  //     //               // (7)
  //     //               for (let n in vClipVisiblesLower) {
  //     //                 let vClipVisibleLower = vClipVisiblesLower[n]
  //     //                 let vcvl = vClipVisibleLower
    
  //     //                 // console.log(vClip.name)
    
  //     //                 if (vcvl.start.frames >= vClipVisible.start.frames) {
  //     //                   if (vcvl.end.frames <= vClipVisible.end.frames) {
  //     //                     // console.log("cut off " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //     //                     vcvl.start.frames = 0
  //     //                     vcvl.end.frames = 0
  //     //                     vcvl.duration.frames = 0
  //     //                   } 
  //     //                   else if (vcvl.start.frames < vClipVisible.end.frames) {
  //     //                     if (vcvl.end.frames >= vClipVisible.end.frames) {
  //     //                       // console.log("trimmed start.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //     //                       vcvl.start.frames = vClipVisible.end.frames
  //     //                       vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
  //     //                     }
  //     //                   } 
  //     //                 } 
  //     //                 else if (vcvl.start.frames <= vClipVisible.start.frames) {
  //     //                   if (vcvl.end.frames >= vClipVisible.end.frames) {
  //     //                     // console.log("cut in parts " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //     //                     vClipVisiblesLower.push({'start': { 'frames': vClipVisible.end.frames} , 'end': {'frames': vcvl.end.frames}, 'duration': {'frames': vcvl.end.frames - vClipVisible.end.frames}})
  //     //                     vcvl.end.frames = vClipVisible.start.frames
  //     //                     vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
  //     //                   }
  //     //                   else if (vcvl.end.frames > vClipVisible.start.frames && vcvl.end.frames <= vClipVisible.end.frames) {
  //     //                     // console.log("trimmed end.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //     //                     vcvl.end.frames = vClipVisible.start.frames
  //     //                     vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
  //     //                   }
  //     //                 }
  //     //               }
  //     //             }
  //     //           }
  //     //         }
  //     //       }
  //     //     }
  //     //   })
  //     // })

    

  //   // for (var i = 0; i < vTracks.length; i++) {
  //   //   // console.log(i +": " + vTracks[i].numberOfItems)
  //   //   const vClips = vTracks[i].clips
  //   //   // (2) for each clip in the track
  //   //   for (let j in vClips) {
  //   //     const vClipId = vClips[j]
  //   //     const vClip = this.clipItems.get(vClipId)!
  //   //     // (3)
  //   //     // if (!vClip.hasTransparence) {
  //   //     // if (vClip.visible === undefined) {
  //   //     //   vClip.visible = []
  //   //     //   vClip.visible.push({start: {frames: vClip.start.frames}, end: {frames: vClip.end.frames}, duration: {frames: vClip.end.frames - vClip.start.frames}})
  //   //     // }
  //   //     // if (!this.getAttributeOfClip({clip: vClip, attribute: 'transparence'}).value) {
  //   //     if (!vClip.getAttribute('transparence')?.value) {
  //   //       // (4) 
  //   //       for (let vClipVisible of vClip.times.visible) {
  //   //         // let vClipVisible = vClip.times.visible[k]
  
  //   //         if (!(vClipVisible.start === 0 && vClipVisible.end === 0)) {
  //   //           // (5)
  //   //           // for (var l = i-1; l >= 0; l--) {
  //   //           for (var l = i+1; l < vTracks.length; l++) {
  //   //             // console.log(l +": " + vTracks[l].numberOfItems)
  //   //             const vClipsLower = vTracks[l].clips
  //   //             // (6)
  //   //             for (let m in vClipsLower) {
  //   //               const vClipLower = this.clipItems.get(vClipsLower[m])!
  //   //               // if (vClipLower.times.visible === undefined) {
  //   //               //   vClipLower.times.visible = []
  //   //               //   vClipLower.times.visible.push({start: {frames: vClipLower.start.frames}, end: {frames: vClipLower.end.frames}, duration: {frames: vClipLower.end.frames - vClipLower.start.frames}})
  //   //               // }
  //   //               // let vClipVisiblesLower = vClipLower.visible
  //   //               // (7)
  //   //               let vcvlIdx = 0
  //   //               for (const vcvl of vClipLower.times.visible) {
  //   //                 // let vClipVisibleLower = vClipLower.times.visible[n]
  //   //                 // let vcvl = vClipVisibleLower
  
  //   //                 // console.log(vClip.name)
  
  //   //                 if (vcvl.start >= vClipVisible.start) {
  //   //                   if (vcvl.end <= vClipVisible.end) {
  //   //                     // console.log("cut off " + vClipVisible.start+" - "+vClipVisible.end + " *** " + vcvl.start+" - "+vcvl.end + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //   //                     // vcvl.start = 0
  //   //                     // vcvl.end = 0
  //   //                     vClipLower.modifyVisible({id: vcvlIdx, start: 0, end: 0})
  //   //                   } 
  //   //                   else if (vcvl.start < vClipVisible.end) {
  //   //                     if (vcvl.end >= vClipVisible.end) {
  //   //                       // console.log("trimmed start " + vClipVisible.start+" - "+vClipVisible.end + " *** " + vcvl.start+" - "+vcvl.end + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //   //                       // vcvl.start = vClipVisible.end
  //   //                       // vcvl.duration = vcvl.end - vcvl.start
  //   //                       vClipLower.modifyVisible({id: vcvlIdx, start: vClipVisible.end})
  //   //                     }
  //   //                   } 
  //   //                 } 
  //   //                 else if (vcvl.start <= vClipVisible.start) {
  //   //                   if (vcvl.end >= vClipVisible.end) {
  //   //                     // console.log("cut in parts " + vClipVisible.start+" - "+vClipVisible.end + " *** " + vcvl.start+" - "+vcvl.end + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //   //                     // vClipVisiblesLower.push({start: { frames: vClipVisible.end} , end: {frames: vcvl.end}, duration: {frames: vcvl.end - vClipVisible.end}})
  //   //                     // vcvl.end = vClipVisible.start
  //   //                     // vcvl.duration = vcvl.end - vcvl.start
  //   //                     vClipLower.addVisible({start: vClipVisible.end , end: vcvl.end})
  //   //                     vClipLower.modifyVisible({id: vcvlIdx, end: vClipVisible.start})
  //   //                   }
  //   //                   else if (vcvl.end > vClipVisible.start && vcvl.end <= vClipVisible.end) {
  //   //                     // console.log("trimmed end " + vClipVisible.start+" - "+vClipVisible.end + " *** " + vcvl.start+" - "+vcvl.end + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
  //   //                     // vcvl.end = vClipVisible.start
  //   //                     // vcvl.duration = vcvl.end - vcvl.start
  //   //                     vClipLower.modifyVisible({id: vcvlIdx, end: vClipVisible.start})
  //   //                   }
  //   //                 }
  //   //                 vcvlIdx += 1
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //       }
  //   //     } 
  //   //   }
  //   // }

  //   // console.log(this.clipItems)
  //   // // // delete empty visibles

  //   // this.clipItems.forEach((clip) => { clip.times.cleanVisibleParts() })

  //   // vTracks = vTracks.map(vTrack => 
  //   //   ({...vTrack, clips: vTrack.clips.map( clipId => {
  //   //     clip.times.visible = clip.times.visible ? clip.times.visible.filter(visiblePart => visiblePart.duration > 0) : clip.times.visible
  //   //     return clip
  //   //   }
  //   //     // ({...clip, visible: clip.visible ? 
  //   //     //   clip.visible.filter(visiblePart => visiblePart.duration.frames > 0) :
  //   //     //   clip.visible
  //   //     // })
  //   //   )
  //   // }))

  //   // this.sequences.find(seq => seq.id === activeSequenceId )!.videotracks = vTracks
  //   // return vTracks
  // }

  createItemGroup({ids, groupName}: {ids: string[], groupName: string}){
    if(this.activeSequence){
      this.actualOperationStack = []
      // sequence -> itemGroups
      const groupCounter = this.activeSequence.itemGroups.size
      const itemGroup: ReportTS.ItemGroup = {id: groupCounter, name: groupName, type: 'singleClips', clipItems: ids.map(id => this.clipItems.get(id)!)}
      this.modifySequenceAndStackIt({sequence: this.activeSequence, modification: {mode: 'arrayAdd', key: 'itemGroups', value: {search: {id: groupCounter}, replace: itemGroup}}})
      // this.modifySequenceAndStackIt({sequence: this.activeSequence, modification: {mode: 'arrayAdd', key: 'itemGroups', value: {search: {id: groupCounter}, replace: {name: groupName, type: 'singleClips'}}}})
      console.log(itemGroup)
      // Clip -> group
      this.modifyClips({name: 'Gruppe erstellen', ids: ids, modification: {
        mode: Globals.MOD.MODE.ARRAYADD, 
        key: 'groups', 
        value: {
          search: { groupId: groupCounter },
          replace: { showInList: true, 
            // group: itemGroup
            groupId: groupCounter
           }
          }
        },
        options: {startReset: false, endSave: true}
      })
    }
  }

  getClipItemsOfFileId(fileId: string) {
    const clipIds = Array.from(this.clipItems.entries()).filter(([id,clip]) => clip.fileId === fileId).map(([id])=>id)
    return clipIds.map(clipId => this.clipItems.get(clipId)!)
  }

  linkClips(){
    Array.from(this.filePaths.keys()).forEach(id => {
      this.getClipItemsOfFileId(id)
      .sort((a,b) => a.times.start - b.times.start)
      .forEach((clip,index,clipArray) => {
        if(index === 0) return
        const beforeClip = clipArray[index-1]
        if( beforeClip && Math.abs(clip.times.start - (beforeClip.times.start)) < beforeClip.times.timebase/2 ) {
          clip.link(beforeClip)
        }
      })
    })
  }

  clipIsCompletelyVisible(clipId: string) {
    // const activeSequence = this.activeSequence
    // let completelyVisible = false
    const clipTimes = this.clipItems.get(clipId)?.times
    return (
      clipTimes?.visible.length === 1 &&
      clipTimes?.start === clipTimes?.visible[0].start &&
      clipTimes?.end === clipTimes?.visible[0].end)

    // if(activeSequence?.visiblePartsSorted){
    //   const tracksWithAllVisibleClips = activeSequence.visiblePartsSorted.videotracks
    //   tracksWithAllVisibleClips.forEach(track => {
    //     track.clips.forEach(clip => {
    //       if(clip.id === clipId &&
    //         clip.times.visible.length === 1 && 
    //         clip.times.start === clip.times.visible[0].start && 
    //         clip.times.end === clip.times.visible[0].end) {
    //           completelyVisible = true
    //       }
    //     })
    //   })
    // }
    // return completelyVisible
  }

  makeClipVisible(clipIds: string[]){
    this.actualOperationStack = []

    const activeSequence = this.activeSequence

    if(activeSequence){
      clipIds.forEach(clipId => {
        if (this.clipIsCompletelyVisible(clipId)) return false
        const notJetVisibleClip = this.clipItems.get(clipId)
        // console.log(notJetVisibleClip)
        if(notJetVisibleClip){
          const trackOfClip = Globals.getTrackOfClip(activeSequence.videotracks, {key: 'id', val: notJetVisibleClip.id}, this.clipItems)
          if(trackOfClip) {
            const overlayingTracks = activeSequence.videotracks.map(track => track.name).filter(trackname => trackname.match(/\d+/)![0] > trackOfClip.name.match(/\d+/)![0])
            this.forEachVideoClip(clip => {
              const clipTimes = clip.times
              const notJetVisibleClipTimes = notJetVisibleClip.times
              if((clipTimes.start >= notJetVisibleClipTimes.start && clipTimes.start < notJetVisibleClipTimes.end) || 
                (clipTimes.end > notJetVisibleClipTimes.start && clipTimes.end <= notJetVisibleClipTimes.end) || 
                (clipTimes.start <= notJetVisibleClipTimes.start && clipTimes.end >= notJetVisibleClipTimes.end)){
                  // console.log(clip.name)
                  // clip.transparence = true
                  this.modifyClipAndStackIt({clip, modification: {key: 'transparence', value: true, mode: 'change'}})
                }
              return clip
            }, true, overlayingTracks)
          }
          
        }
      })
    }
    // console.log(JSON.parse(JSON.stringify(this.actualOperationStack)))
    this.operationStack.push({id: 1, time: new Date(), name: 'Clip sichtbar machen', operations: this.actualOperationStack})
  }

  // setClipAttribute(clipId: string, modification: ReportTS.Modification) {
  //   const clip = this.clipItems.get(clipId)
  //   if (clip?.attributes[modification.key]?.value && typeof modification.value !== 'undefined')
  //     clip.attributes[modification.key].value = modification.value
  // }

  setClipsAttribute(clipIds: string[], modification: ReportTS.Modification) {
    // clipIds.forEach(clipId => this.setClipAttribute(clipId, attribute))
    clipIds.forEach(clipId => {
      const clip = this.clipItems.get(clipId)
      if(clip)
        clip.modifyAttribute(modification)
    })
  }

  modifyClips({name, ids, clipType, same, modification, options = {startReset: true, endSave: true}}:{name?: string, ids: string[], clipType?: ReportTS.TrackType, same?: ReportTS.AllOfSame, modification: ReportTS.Modification, options?: {startReset?: boolean, endSave?: boolean}}) {
    // get all ids / clipObjs
    console.log(ids)
    if (options.startReset){
      this.actualOperationStack = []
    }
    // const activeSequence = this.sequences.find(seq => seq.id === this.activeSequenceId)
    const activeSequence = this.getSequenceOfClip(ids[0])
    // let originClipObj: ReportTS.ClipProps | undefined

    let clipIds: string [] = ids

    if(activeSequence) {
      // let allClipObjs: ReportTS.ClipProps[] = []
      if (ids.length > 1) {
        // get ClipObjs by id
        // allClipObjs = this.allClipItemsOriginal.filter(clip => ids.find(id => id === clip.id))
        // allClipObjs = this.allClipItems.filter(clip => ids.find(id => id === clip.id))
        // console.log( JSON.parse(JSON.stringify(allClipObjs)) )
        // console.log( ids.map( id => this.allClipItems.find(clip => clip.id === id)! ) )
        // console.log( this.allClipItems.filter(clip => ids.find(id => id === clip.id)) )
        // clipIds = ids
      } else {
        if(ids.length === 1 && this.clipItems.has(ids[0])) {
          const clip = this.clipItems.get(ids[0])!
          switch (same) {
            case 'file':
              if(typeof clip.fileId === 'undefined') {
                clipIds = clip.sequenceId ? Array.from(this.clipItems.entries()).filter(([id,clipItem]) => clipItem.sequenceId === clip.sequenceId).map(([id])=>id) : clipIds
              } else {
                clipIds = Array.from(this.clipItems.entries()).filter(([id, clip]) => clip.fileId).map(([id])=>id)
              }
              break;
            case 'dir':
              const fileObj = this.filePaths.get(clip.fileId)
              if (fileObj?.pathurl) {
                let fullPathParent = fileObj.pathurl.split('/')
                fullPathParent.pop()
                const fullPathParentWithoutChild = fullPathParent.join('/')
                const fileObjs = Array.from(this.filePaths.values()).filter(o => ( o.pathurl ? o.pathurl.includes(fullPathParentWithoutChild) : false ))
                clipIds = Array.from(this.clipItems.entries()).filter(([id, clip]) => fileObjs.find(fileObj => fileObj.id === clip.fileId)).map(([id])=>id)
              }
              break
            case 'track':
              const track = Globals.getTrackOfClip([...activeSequence.videotracks, ...activeSequence.audiotracks], {key: 'id', val: ids[0]}, this.clipItems)
              if (track) {
                clipIds = track.clips.map(c => c.id)
                // clipIds = track.clips.map(clip => clip.id)
              }
              break
            default:
              break;
          }
        }
      }
      //   // get ClipObj by id
      //   originClipObj = this.allClipItemsOriginal.find(clip => clip.id === ids[0])
      //   if (originClipObj) {
      //     switch(same) {
      //       case Globals.ALLOFSAME.FILE:
      //         // get ClipObjs with same fileId
      //         if(originClipObj.fileId === undefined) {
      //           allClipObjs = originClipObj.sequenceId ? this.allClipItemsOriginal.filter(clip => clip.sequenceId === originClipObj!.sequenceId) : [originClipObj]
      //         } else {
      //           allClipObjs = this.allClipItemsOriginal.filter(clip => clip.fileId === originClipObj!.fileId)
      //         }
      //         break
      //       case Globals.ALLOFSAME.DIR:
      //         // get fileObj of originClipObj
      //         const fileObj = this.filePaths.get(originClipObj!.fileId)

      //         if (fileObj && fileObj.pathurl) {
      //           let fullPathParent = fileObj.pathurl.split('/')
      //           fullPathParent.pop()
      //           const fullPathParentWithoutChild = fullPathParent.join('/')
      //           const fileObjs = Array.from(this.filePaths.values()).filter(o => ( o.pathurl ? o.pathurl.includes(fullPathParentWithoutChild) : false ))
      //           allClipObjs = this.allClipItemsOriginal.filter(clip => fileObjs.find(fileObj => fileObj.id === clip.fileId))
      //         }
      //         break
      //       case Globals.ALLOFSAME.TRACK:
      //         const track = Globals.getTrackOfClip([...activeSequence.videotracks, ...activeSequence.audiotracks], {key: 'id', val: ids[0]})
      //         if (track) {
      //           allClipObjs = track.clips
      //         }
      //         break
      //       default:
      //         allClipObjs = [ this.allClipItemsOriginal.find(clip => clip.id === ids[0])! ]
      //     }
      //   }
      // }
      // console.log(JSON.parse(JSON.stringify(allClipObjs)))
      // console.log(JSON.parse(JSON.stringify( [ this.allClipItems.find(clip => clip.id === ids[0])! ] )))
      if(clipIds.length === 0) return

      // const attribute = this.rootStore.attributeRulesetStore.availableAttributes.find(a => a.key === modification.key)
      // if(attribute) {
      //   if(typeof modification.value === attribute.valueType) {
      //     switch (modification.mode) {
      //       case 'toggle':
      //         const value = this.clipItems.get(clipIds[0])!.attributes[modification.key].value
      //         if(typeof value === 'boolean')
      //           this.setClipsAttribute(clipIds, {...modification, value})
      //         break;
      //       case 'change':
      //         this.setClipsAttribute(clipIds, modification)
      //         break
      //       default:
      //         break;
      //     }
      //   }
      // }

      clipIds.forEach(clipId => {
        const clip = this.clipItems.get(clipId)
        if(clip) {
          console.log(clip)
          console.log(modification)
          const operation = clip.modifyAttribute(modification)
          if(operation)
            this.actualOperationStack.push(operation)
          console.log(operation)

        }
      })
      

      // switch(modification.key) {
      //   case 'name':
      //   case 'transparence':
      //   case 'ignore':
      //   case 'sequenceUnfold':
      //   case 'rights':
      //   case 'owner':
      //     // freeze originClipObj first because it will be modified
      //     originClipObj = originClipObj ? JSON.parse(JSON.stringify(originClipObj)) : undefined
      //     allClipObjs.forEach(clip => {
      //       // const clipValueBefore = clip[modification.key] ? JSON.parse(JSON.stringify(clip[modification.key])) : undefined
      //       switch(modification.mode) {
      //         case 'toggle':
      //           const newVal = this.getAttributeOfClip({clip, attribute: modification.key}).value === undefined ? true : (
      //             originClipObj ? !this.getAttributeOfClip({clip: originClipObj, attribute: modification.key}).value : !this.getAttributeOfClip({clip, attribute: modification.key}).value
      //           )
      //           this.modifyClipAndStackIt({clip, modification: {...modification, newVal}})
      //           break
      //         case 'change':
      //           this.modifyClipAndStackIt({clip, modification})
      //           break
      //         case 'arrayAdd':
      //           // if ((clip[modification.key] as any) === undefined) {
      //           //   (clip[modification.key] as Array<any>) = []
      //           // }
      //           // (clip[modification.key] as Array<any>).push(modification.newVal)
      //           this.modifyClipAndStackIt({clip, modification})
      //           break
      //         case 'arrayChange':
      //           if (this.getAttributeOfClip({clip, attribute: modification.key}).value === undefined) {
      //             // (clip[modification.key] as Array<any>) = [modification.newVal]
      //             this.modifyClipAndStackIt({clip, modification})
      //           } else {
      //             this.modifyClipAndStackIt({clip, modification})
      //           }
      //           break
      //       }
      //     })
      //     break
      //   // case 'name':
      //   case 'groups':
      //   // case 'ignore':
      //   // case 'transparence':
      //   // case 'sequenceUnfold':
      //     // freeze originClipObj first because it will be modified
      //     originClipObj = originClipObj ? JSON.parse(JSON.stringify(originClipObj)) : undefined
      //     allClipObjs.forEach(clip => {
      //       // const clipValueBefore = clip[modification.key] ? JSON.parse(JSON.stringify(clip[modification.key])) : undefined
      //       switch(modification.mode) {
      //         case 'toggle':
      //           const newVal = clip[modification.key] === undefined ? true : (
      //             originClipObj ? !originClipObj[modification.key] : !clip[modification.key]
      //           )
      //           this.modifyClipAndStackIt({clip, modification: {...modification, newVal}})
      //           break
      //         case 'change':
      //           this.modifyClipAndStackIt({clip, modification})
      //           break
      //         case 'arrayAdd':
      //           // if ((clip[modification.key] as any) === undefined) {
      //           //   (clip[modification.key] as Array<any>) = []
      //           // }
      //           // (clip[modification.key] as Array<any>).push(modification.newVal)
      //           this.modifyClipAndStackIt({clip, modification})
      //           break
      //         case 'arrayChange':
      //           if (clip[modification.key] === undefined) {
      //             // (clip[modification.key] as Array<any>) = [modification.newVal]
      //             this.modifyClipAndStackIt({clip, modification})
      //           } else {
      //             this.modifyClipAndStackIt({clip, modification})
      //           }
                
      //           // console.log(modification.newVal)
      //           // const nV = {
      //           //   search: {groupId: 0},
      //           //   replace: {showInList: true}
      //           // }
      //           // if(typeof modification.newVal === 'object' && modification.newVal.search) {
      //           //   const keys = Object.keys(modification.newVal.search)
      //           //   const values = Object.values(modification.newVal.search)
      //           //   // console.log(keys)
      //           //   // console.log(values)
      //           //   // clip[(modification.key as keyof ReportTS.Clip)].find((arr: any) => arr[modification.newVal.search])
      //           //   if(keys.length === 1 && values.length === 1) {
      //           //     (clip[modification.key] as Array<any>).forEach((arr: any, index: number) => {
      //           //       if (arr[keys[0]] === values[0]) {
      //           //         // console.log({...arr, ...modification.newVal.replace})
      //           //         // (clip[(modification.key as keyof ReportTS.Clip)] as Array<any>)[index] = {...arr, ...modification.newVal.replace}
      //           //         if(typeof modification.newVal === 'object' && modification.newVal.search) {
      //           //           this.modifyClipAndStackIt({clip, key: modification.key, value: {index, value: {...arr, ...modification.newVal.replace} }, modMode: modification.mode})
      //           //         }
      //           //       }
      //           //     })
      //           //     // console.log(JSON.parse(JSON.stringify( clip[(modification.key as keyof ReportTS.Clip)] )) )
      //           //     // console.log(clip)
      //           //   }
      //           // }
      //           break
      //       }
      //       // console.log(clip[modification.key])
      //       // console.log(clipValueBefore)
      //       // if(clip[modification.key] !== clipValueBefore){
      //       //   this.actualOperationStack.push({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: clip[modification.key], before: clipValueBefore})
      //       // }
      //     })
      //     break
      //   // case 'rights':
      //   // case 'owner':
      //   //   this.sequences.filter(seq => allClipObjs.find(clip => clip.sequenceId && clip.sequenceId === seq.id))
      //   //     .forEach(seq => {
      //   //       switch(modification.mode) {
      //   //         case Globals.MOD.MODE.CHANGE:
      //   //           console.log(seq.source[modification.key as keyof ReportTS.Source])
      //   //           console.log(modification.newVal)
      //   //           // seq.source[modification.key as keyof ReportTS.Source] = modification.newVal
      //   //           this.modifySequenceAndStackIt({sequence: seq, modification})
      //   //           break
      //   //       }
      //   //     })
      //   //   this.filePaths.filter(fp => allClipObjs.find(clip => clip.fileId === fp.id))
      //   //     .forEach(file => {
      //   //       switch(modification.mode) {
      //   //         case Globals.MOD.MODE.CHANGE:
      //   //           // console.log(file.source[modification.key  as keyof ReportTS.Source])
      //   //           // console.log(modification.newVal)
      //   //           // file.source[modification.key] = modification.newVal
      //   //           this.modifyFileAndStackIt({file, modification})
      //   //           break
      //   //       }
      //   //     })
      //   //   break
      // }
      if(options.endSave) {
        this.operationStack.push({id: 1, time: new Date(), name, operations: this.actualOperationStack})
      }
      this.updateVisiblePartsSorted()
    }
  }

  modifyClipAndStackIt({clip, modification}: {clip: Clip, modification: ReportTS.Modification}){
    let valueBefore = clip.attributes[modification.key]? clip.attributes[modification.key]?.value : undefined

    // if(modification.key === 'transparence' || modification.key === 'ignore' || modification.key === 'sequenceUnfold' || modification.key === 'owner' || modification.key === 'rights' || modification.key === 'name') {
    //   valueBefore = this.getAttributeOfClip({clip, attribute: modification.key}).value
    // } else {
    //   valueBefore = clip[modification.key] ? JSON.parse(JSON.stringify(clip[modification.key])) : undefined
    // }

    const operation = clip.modifyAttribute(modification)
    if(operation)
      this.actualOperationStack.push(operation)

    // switch (modification.mode) {
    //   case 'change':
    //     if (valueBefore === modification.value) break
    //     if (modification.value && typeof modification.value === 'string' || typeof modification.value === 'boolean') {
    //       clip.attributes[modification.key].value = modification.value
    //       this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore}))
    //     }
    //     break
    //     // if (valueBefore !== modification.newVal) {
    //     //   if(modification.key === 'transparence' || modification.key === 'ignore' || modification.key === 'sequenceUnfold' || modification.key === 'owner' || modification.key === 'rights' || modification.key === 'name'){
    //     //     if(typeof modification.newVal === 'boolean' || typeof modification.newVal === 'string') {
    //     //       const changedAttribute: ReportTS.Attribute = clip.attributes && clip.attributes[modification.key] ?
    //     //         {[modification.key]: {...clip.attributes[modification.key], value: modification.newVal}} :
    //     //         {[modification.key]: {value: modification.newVal, type: 'clip'}}
    //     //       clip['attributes'] = clip.attributes ? Object.assign(clip.attributes, changedAttribute) : changedAttribute
    //     //     }
    //     //   } else {
    //     //     (clip[modification.key] as any) = modification.newVal
    //     //   }
    //     //   this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.newVal, before: valueBefore}))
    //     // }
    //     // break
    //   case 'toggle':
    //     if (typeof valueBefore === 'boolean') {
    //       clip.attributes[modification.key].value = !valueBefore
    //       this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore}))
    //     }
    //     if (typeof valueBefore === 'undefined') {
    //       clip.attributes[modification.key].value = true
    //       this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore}))
    //     }
    //     break
    //   case 'arrayAdd':
    //     if (valueBefore !== modification.value && modification.key !== 'transparence' && modification.key !== 'ignore' && modification.key !== 'sequenceUnfold' && modification.key !== 'owner' && modification.key !== 'rights') {
    //       // if (modification.value && modification.value.index !== undefined && typeof modification.value.index === 'number' && modification.value.value) {
    //       //   (clip[modification.key] as Array<any>)[value.index] = value.value
    //       //   this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore }))
    //       // } else
          
    //       if(typeof modification.value === 'object' && modification.value.search) {
    //         console.log([{...modification.value.search, ...modification.value.replace}]);
    //         (clip[modification.key] as Array<any>) = [{...modification.value.search, ...modification.value.replace}]
    //         this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: {noIndex: (clip[modification.key] as Array<any>).length-1} }))
    //       }
    //     }
    //     break
    //   case 'arrayChange':
    //     if (valueBefore !== modification.value) {
    //       if(typeof modification.value === 'object' && modification.value.search && modification.key !== 'transparence' && modification.key !== 'ignore' && modification.key !== 'sequenceUnfold' && modification.key !== 'owner' && modification.key !== 'rights') {
    //         const keys = Object.keys(modification.value.search)
    //         const values = Object.values(modification.value.search)
    //         // console.log(keys)
    //         // console.log(values)
    //         // clip[(modification.key as keyof ReportTS.Clip)].find((arr: any) => arr[modification.value.search])
    //         if(keys.length === 1 && values.length === 1) {
    //           (clip[modification.key] as Array<any>).forEach((arr: any, index: number) => {
    //             if (arr[keys[0]] === values[0]) {
    //               // console.log({...arr, ...modification.value.replace})
                  
    //               if(typeof modification.value === 'object' && modification.value.search) {
    //                 (clip[modification.key as ReportTS.ClipKeysMod] as Array<any>)[index] = {...arr, ...modification.value.replace}
    //                 this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore }))
    //               }
    //             }
    //           })
    //         }
    //       }
    //     }
    //     break
    // }
  }

  modifySequenceAndStackIt({sequence, modification}: {sequence: ReportTS.Sequence, modification: ReportTS.Modification}){
    let valueBefore = sequence.source[modification.key as keyof ReportTS.Source] ? JSON.parse(JSON.stringify(sequence.source[modification.key as keyof ReportTS.Source])) : undefined
    if (valueBefore !== modification.value) {
      if(modification.key === 'itemGroups' && typeof modification.value === 'object' && modification.value.search) {
        const keys = Object.keys(modification.value.search)
        const values = Object.values(modification.value.search)
        console.log(keys)
        console.log(values)
        // clip[(modification.key as keyof ReportTS.Clip)].find((arr: any) => arr[modification.value.search])
        if(keys.length === 1 && values.length === 1) {
          if((sequence[modification.key] as Map<number,ReportTS.ItemGroup>).size < 1 && typeof values[0] === 'number' && modification.value?.replace.hasOwnProperty('name') && modification.value?.replace.hasOwnProperty('type') ) {
            sequence[modification.key].set(values[0], {name: (modification.value.replace as {[propName: string]: any}).name, type: (modification.value.replace as {[propName: string]: any}).type, id: (modification.value.replace as {[propName: string]: any}).id})
            valueBefore = {noIndex: sequence[modification.key].size-1}
          }
          (sequence[modification.key] as Map<number,any>).forEach((arr, key) => {
            if (arr[keys[0]] === values[0]) {
              // console.log({...arr, ...modification.value.replace})
              
              if(modification.key === 'itemGroups' && typeof modification.value === 'object' && modification.value.search) {
                // const obj = (sequence[modification.key] as Map<number,any>).get(key)
                arr = {...arr, ...modification.value.replace}
                this.actualOperationStack.push(new Operation({op: modification.mode, id: sequence.id, type: 'sequence', key: modification.key, value: modification.value, before: valueBefore }))
              }
            }
          })
        }
      } else {
        (sequence.source[modification.key as keyof ReportTS.Source] as any) = modification.value
        this.actualOperationStack.push(new Operation({op: modification.mode, id: sequence.id, type: 'sequence', key: modification.key, value: modification.value, before: valueBefore}))
      }
    }
  }

  // // not in use until now
  // getTrackOfClip(clipId: string, clipToFind: {key: keyof ReportTS.ClipProps, val: string}) {
  //   const sequence = this.getSequenceOfClip(clipId)
  //   if(sequence) {
  //     const videotrack = sequence.videotracks.find(track => track.clips.find(clip => clip[clipToFind.key] === clipToFind.val)) as ReportTS.Track
  //     if(videotrack) return videotrack
  //     return sequence.audiotracks.find(track => track.clips.find(clip => clip[clipToFind.key] === clipToFind.val)) as ReportTS.Track
  //   }
  // }

  // getTimeBase(clipId: string) {
  //   const sequence = this.getSequenceOfClip(clipId)
  //   return sequence ? sequence.timeBase : 0
  // }

  get listForCSVexportVideo(){
    return this.getlistForCSVexport(this.activeListVideo)
  }

  get listForCSVexportAudio(){
    return this.getlistForCSVexport(this.activeListAudio)
  }

  getlistForCSVexport(visibleList: ReportTS.TrackClipPart[]){

    const activeRulesetAttributeOrder = this.rootStore.attributeRulesetStore.activeRulesetAttributeOrder.length > 0 ? this.rootStore.attributeRulesetStore.activeRulesetAttributeOrder : this.rootStore.attributeRulesetStore.defaultAttributeOrder
    const ignoreAttributes = ['actionButtons','ignore','transparence','icon']
    const csvAttributeOrder = activeRulesetAttributeOrder.filter(attr => ignoreAttributes.find(igA => igA === attr) ? false : true )

    // const visibleListFormatted: typeof Globals.csvHeaders[] = visibleList
    // console.log(JSON.parse(JSON.stringify(visibleList)))
    const visibleListFormatted = visibleList
    .map(cL => ({clip: this.clipItems.get(cL.trackClip.clip.id)!, clipParts: cL}))
    // don't export hidden clips
    .filter(obj => ! (typeof obj.clip.attributes.ignore?.value === 'boolean' && obj.clip.attributes.ignore.value) )
    // don't export parent sequences if unfolded
    .filter(obj => ! (typeof obj.clip.attributes.sequenceUnfold?.value === 'boolean' && obj.clip.attributes.sequenceUnfold.value) )
    // .map(clipList => ({...clipList, clipObj: this.activeClipItems.find(clipId => clipId.id === clipList.clipParts[0].clipId) }))
    .map(clipList => ({...clipList, fileObj: this.filePaths.get(clipList.clip.fileId) }))
    .map(obj => {
      // const arrayOfVisiblesForKey = obj.clipObj ? (obj.clipObj.groups ? this.getValuesOfGroupIfVisible(obj.clipObj.groups[0].groupId) : [] ) : []
      // const timeBase = this.getTimeBase(obj.ids[0])
      return csvAttributeOrder.map(attrString => {
        const attribute = obj.clipParts.trackClip.clip.getAttribute(attrString, this.rootStore.attributeRulesetStore.getAttributeByKey(attrString).type, {timeFormat: this.rootStore.timelineStore.timeFormat, partTimes: obj.clipParts.trackClip.clipVisible[obj.clipParts.visibleIndex]})

        // const attr = obj.clip.getAttribute(attrString)
        return {[attrString]: attribute ? attribute.getValueString('') : ''} 
        // return {[attrString]: obj.clip ? this.clipItems.get(obj.clip.id)?.getAttribute(attrString)?.getValueString() : ''}      
        // const attr = this.getAttributeOfClip({clip: {...obj, id: obj.ids[0], attributes: obj.attributes}, attribute: attrString})
        // return {[attrString]: attr.getValueString()}
      }).reduce((acc, val) => Object.assign(acc, val), {})
      // return {
      //   name: (obj.attributes.name.value as string).replace(/;/g, ','),
      //   // name: arrayOfVisiblesForKey.length > 0 ? arrayOfVisiblesForKey.map(a => a.name).join(' + ') : obj.name.replace(/;/g, ','), // remove semicolon to avoid errors of csv
      //   source: arrayOfVisiblesForKey.length > 0 ? arrayOfVisiblesForKey.map(a => a.source).join(' + ') : (obj.fileObj ? this.getOwnerString(obj.clipObj!) : ''),
      //   tcIn: Globals.getTimecodeHMS(obj.start.frames, timeBase),
      //   tcOut: Globals.getTimecodeHMS(obj.end.frames, timeBase),
      //   dur: Globals.getTimecodeHMS(obj.duration.frames, timeBase),
      //   new: obj.fileObj ? (obj.fileObj!.source.stock ? obj.fileObj!.source.stock.isNew : '') : '',
      //   rights: arrayOfVisiblesForKey.length > 0 ? arrayOfVisiblesForKey.map(a => a.rights).join(' + ') : ( obj.fileObj ? obj.fileObj!.source.rights : '' )
      // }
    })

    // const ret = {header: csvAttributeOrder, list: visibleListFormatted}
    return visibleListFormatted
  }

  get project(){
    return {
      fileName: this.fileName,
      activeSequenceId: this.activeSequenceId,
      sequences: this.sequences,
      filePaths: this.filePaths
    }
  }

  get parsedProject(){
    return {
      reportJSONversion: process.env.REACT_APP_CLIPREPORT_JSON,
      fileName: this.fileName,
      activeSequenceId: this.activeSequenceId,
      sequences: this.sequences.map(sequence => ({...sequence, visiblePartsSorted: undefined, audiblePartsSorted: undefined, itemGroups: undefined, itemGroupsData: Array.from(sequence.itemGroups.entries()).map(([id, itemGroup]) => {

        console.log(itemGroup)
        return ({...itemGroup, clipItems: undefined, clipIds: itemGroup.clipItems?.map(clip => clip.id)})
      }) })),
      // fileItemsData: Array.from(this.filePaths.entries()).map(([id,fileItem]) => ({...fileItem})),
      fileItems: this.filePaths,
      clipItemsData: Array.from(this.clipItems.values()).map(clip => clip.rawData),
      timeFormat: this.timeFormat
    }
  }

  setParsedProject(jsonFile: any): {success: boolean, msg?: string}{
    switch (jsonFile.reportJSONversion) {
      case "1.1":
        // this.fileName = jsonFile.fileName
        this.activeSequenceId = jsonFile.activeSequenceId
        this.filePaths = new Map<string,ReportTS.FilePathItem>()
        jsonFile.fileItems.forEach((fileItem: [string, ReportTS.FilePathItem]) => {
          this.filePaths.set(fileItem[0],fileItem[1])
        })
        this.clipItems = new Map<string,Clip>()
        console.log(jsonFile.clipItemsData)
        jsonFile.clipItemsData.forEach((clipItem: any) => {
          this.clipItems.set(clipItem.id, new Clip({...clipItem, times: new ClipTimes({...clipItem.times, visible: clipItem.visible?.map((cvp: any)=>new VisiblePart(cvp))}), store: this}))
        })
        
        this.sequences = jsonFile.sequences.map((sequence: any) => {
          const itemGroups = new Map<number,ReportTS.ItemGroup>()
          if(sequence.itemGroupsData?.length > 0){
            console.log(sequence.itemGroupsData)
            sequence.itemGroupsData.forEach((igd: {name: string, id: number, type: ReportTS.ItemGroupType, clipIds: string[]}) => {
              itemGroups.set(igd.id, {...igd, 
                // clipItems: igd.clipIds.map(clipId => this.clipItems.get(clipId)!)
              })
            })
          }
          return { ...sequence, itemGroups }
        })

        // set groups of clipItems
        jsonFile.clipItemsData.forEach((clipItem: any) => {
          const clip = this.clipItems.get(clipItem.id)!
          if(clipItem.groups?.length > 0)
          clip.groups = clipItem.groups.map((group: any) => //new ClipGroup(this, group.id, this.sequences.map(s => s.itemGroups.get(group.id)).find(s => typeof s !== 'undefined')!)
            new ClipGroup(this, group.id)
          )
        })
        return {success: true}
      default:
      case "1.0":
        // this.fileName = jsonFile.fileName
        this.activeSequenceId = jsonFile.activeSequenceId
        this.clipItems = new Map<string,Clip>()
        this.sequences = jsonFile.sequences.map((sequence: any) => {
          return {...sequence,
            videotracks: sequence.videotracks.map((videotrack: any) => {
              return {name: videotrack.name, clips: videotrack.clips.map((clip: any)=>{
                  // create clipItems on the fly
                  this.clipItems.set(clip.id, new Clip({...clip, store: this, times: new ClipTimes({
                    timebase: sequence.timeBase,
                    start: clip.start.frames,
                    end: clip.end.frames,
                    inPoint: clip.inPoint.frames,
                    outPoint: clip.outPoint.frames,
                    sequenceId: sequence.id
                  }), parent: {
                    sequenceId: sequence.id,
                    clipIds: []
                  }}))
                  return {id: clip.id}
                })
              }
            }),
            audiotracks: sequence.audiotracks.map((videotrack: any) => {
              return {name: videotrack.name, clips: videotrack.clips.map((clip: any)=>{
                  // create clipItems on the fly
                  this.clipItems.set(clip.id, new Clip({...clip, store: this, times: new ClipTimes({
                    timebase: sequence.timeBase,
                    start: clip.start.frames,
                    end: clip.end.frames,
                    inPoint: clip.inPoint.frames,
                    outPoint: clip.outPoint.frames,
                    sequenceId: sequence.id
                  }), parent: {
                    sequenceId: sequence.id,
                    clipIds: []
                  }}))
                  return {id: clip.id}
                })
              }
            }),
            itemGroups: new Map<number,ReportTS.ItemGroup>(),
            attributes: {}
          }
        })
        this.filePaths = new Map<string,ReportTS.FilePathItem>()
        jsonFile.filePaths.forEach((filePath: any) => {
          const clipItem = Array.from(this.clipItems.values()).find(clip => clip.fileId === filePath.id)
          console.log(clipItem)
          console.log(clipItem?.itemType)
          this.filePaths.set(filePath.id,{...filePath, type: Array.from(this.clipItems.values()).find(clip => clip.fileId === filePath.id)?.itemType})
        })
        console.log(this.clipItems)
        console.log(this.sequences)
        return {success: true}
        // return {success: false, msg: 'could not find json version'}
    }

  }

  getGroupId(group: ReportTS.ItemGroup){
    return this.activeSequence ? Array.from(this.activeSequence.itemGroups.entries()).find(([id, itemGroup]) => itemGroup === group)?.map(val => val) : undefined
  }

  applyRulesOnFileItems(filePathItems: Map<string,ReportTS.FilePathItem>): Map<string,ReportTS.FilePathItem> {
    return new Map(Array.from(filePathItems).map(([id,filePathItem]) => {
      // const sortingResult = this.getSortingResult(filePathItem)
      let returnFilePathItem: ReportTS.FilePathItem = filePathItem
      //  = {...filePathItem, source: sortingResult.source}

      const attributesResult = this.rootStore.attributeRulesetStore.getAttributesByFilepaths(filePathItem)
      // if(Object.keys(attributesResult).length > 0 && attributesResult.constructor === Object ){
      //   console.log(filePathItem)
      //   console.log(attributesResult)
      // }

      if( Object.keys(attributesResult).length > 0 && attributesResult.constructor === Object ) {
        returnFilePathItem = {...returnFilePathItem, clipAttributes: attributesResult}
        this.clipItems.forEach(clip => {
          if(clip.fileId !== filePathItem.id) return
          clip.attributes = clip.attributes ? {...clip.attributes, ...attributesResult} : attributesResult
          Object.entries(attributesResult).forEach(([key,value]) =>{
            this.modifyClipAndStackIt({clip: clip, modification: {key, value: value.value as string | boolean, mode: 'change'}})
          })
          
        })
        // this.allClipItemsOriginal.filter(clip => clip.fileId === filePathItem.id)
        // .forEach(clip => {
        //   clip.attributes = clip.attributes ? {...clip.attributes, ...attributesResult} : attributesResult
        // })
      }

      // if(sortingResult.fileItem){
      //   returnFilePathItem = ({...returnFilePathItem, ...sortingResult.fileItem})
      // }

      // console.log(returnFilePathItem)
      return [id, {...returnFilePathItem, type: (returnFilePathItem.type === '' && returnFilePathItem.pathurl) ? Globals.getFileTypeByPath(returnFilePathItem.pathurl) : returnFilePathItem.type}]
    }))
  }

  getTextFieldsOfClip(clipId: string){
    // const clipItem = this.allClipItems.find(clip => clip.id === clipId)
    const clipItem = this.clipItems.get(clipId)
    if(clipItem && clipItem.filter){
      return clipItem.filter.filter(filter => filter.effectid === 'GraphicAndType' && filter.name !== '' ).map(filter => filter.name)
    }
    return []
  }

  applyRulesOnClipItems() {
    // first check if ruleset available
    if(!this.rootStore.attributeRulesetStore.hasActiveRuleset) return
    this.filePaths = this.applyRulesOnFileItems(this.filePaths)
    this.actualOperationStack = []

    this.forEachVideoClip(clip => {
      const fileObj = this.getFileObj(clip.fileId)
      const sequence = this.getSequenceOfClip(clip.id)
      let coversAll = true
      if (sequence?.media) {
        coversAll = Globals.checkFiltersForTransparency(clip, fileObj ? fileObj.media : undefined, sequence.media)
        
        if(!coversAll) {
          // return {...clip, transparence: true}
          // clip['transparence'] = true
          this.modifyClipAndStackIt({clip: clip, modification: {key: 'transparence', value: true, mode: 'change'}})
        }
      }

      if(clip.itemType && clip.itemType === 'graphic') {
        this.modifyClipAndStackIt({clip: clip, modification: {key: 'transparence', value: true, mode: 'change'}})
      }
      if(clip.itemType && clip.itemType === 'generator') {
        this.modifyClipAndStackIt({clip: clip, modification: {key: 'ignore', value: true, mode: 'change'}})
      }

      if(clip.itemType && clip.itemType === 'adjustmentLayer') {
        this.modifyClipAndStackIt({clip: clip, modification: {key: 'transparence', value: true, mode: 'change'}})
        this.modifyClipAndStackIt({clip: clip, modification: {key: 'ignore', value: true, mode: 'change'}})
      }

      return clip
    }, true)
    this.forEachAudioClip(clip => {
      let ignore: boolean = false
      if(clip.getAttribute('ignore')?.value === undefined) {
        ignore = this.isAudioOfVideo(clip.fileId)
        if(!ignore) {
          const fileObj = this.getFileObj(clip.fileId)
          if (fileObj && fileObj.clipAttributes && 'ignore' in fileObj.clipAttributes) {
            ignore = fileObj.clipAttributes['ignore'].value as boolean
          }
        }
        
        if(ignore) {
          this.modifyClipAndStackIt({clip: clip, modification: {key: 'ignore', value: true, mode: 'change'}})
        }
      }
      return clip
    }, true)
    this.operationStack.push({id: 1, time: new Date(), name: 'automatische Regeln', operations: this.actualOperationStack})
  }

  undoLastOperationCollection() {
    if (this.operationStack.length > 0) {
      this.operationStack[this.operationStack.length - 1].operations.forEach(operation => {
        switch(operation.type) {
          case 'clip':
            const clip = this.clipItems.get(operation.id)
            // const clip = this.allClipItemsOriginal.find(clip => clip.id === operation.id)
            if (clip) {
              clip.undo(operation)
              // console.log(operation)
              // if (operation.before && operation.before.noIndex !== undefined) {
              //   clip.removeAttribute(operation.key)
              //   // (clip[ operation.key as keyof Clip ] as Array<any>).splice(operation.before.noIndex,1)
              // } else {
              //   // if((operation.key === 'transparence' || operation.key === 'ignore' || operation.key === 'sequenceUnfold' || operation.key === 'rights' || operation.key === 'owner') && clip.attributes){
              //   //   clip.attributes[operation.key] = operation.before
              //   // }
              //   // (clip[ operation.key as keyof Clip ] as any) = operation.before
              //   clip.modifyAttribute({mode: 'change', key: operation.key, value: operation.before})
              // }
            }
            break
          case 'file':
            const file = this.filePaths.get(operation.id)
            if (file) {
              console.log(operation);
              console.log(file);
              // (file.source[ operation.key as keyof ReportTS.Source ] as any) = operation.before
            }
            break
          case 'sequence':
            const sequence = this.sequences.find(sequence => sequence.id === operation.id)
            if (sequence) {
              if (operation.before && operation.before.noIndex !== undefined) {
                (sequence[ operation.key as keyof ReportTS.Sequence ] as Array<any>).splice(operation.before.noIndex,1)
              } else {
                // (sequence[ operation.key as keyof ReportTS.Clip ] as any) = operation.before
                (sequence.source[ operation.key as keyof ReportTS.Source ] as any) = operation.before
              }
            }
            break
          default:
            break
        }
      })
      this.operationStack.pop()
    }
    this.updateVisiblePartsSorted()
  }

  // getAttributeOfClipId({clipId, attribute}: {clipId: string, attribute: string}){
  //   const clip = this.clipItems.get(clipId)
  //   return clip?.getAttribute(attribute)
  // }

  // getAttributeOfClip({clip, clipListItem, attribute}: {clip?: ReportTS.ClipAttributes, clipListItem?: ReportTS.ClipList, attribute: string}): ClipAttribute {
  //   const type = this.rootStore.attributeRulesetStore.getAttributeByKey(attribute).type

  //   if(clipListItem)
  //     clip = this.allClipItems.find(fP => fP.id === clipListItem.ids[0])!

  //   if(clip){
  //     const time = new Time({timebase: this.getTimeBase(clip.id), format: this.timeFormat})
  //     switch (attribute) {
  //       case 'TCin':
  //         const framesStart = clipListItem ? clipListItem.start.frames : clip.start.frames
  //         // console.log(clipListItem?.originalClipTimes? Globals.getTimecodeMS(clipListItem.originalClipTimes.start.frames, this.getTimeBase(clip.id)) : undefined)
  //         const originalValueStart = clipListItem?.originalClipTimes? time.getFormated({frames: clipListItem.originalClipTimes.start.frames}) : undefined
  //         return new ClipAttribute({
  //           // value: Globals.getTimecodeMS(framesStart, this.getTimeBase(clip.id)), 
  //           value: time.getFormated({frames: framesStart}), 
  //           // originalValue: clipListItem?.originalClipTimes? Globals.getTimecodeMS(clipListItem.originalClipTimes.start.frames, this.getTimeBase(clip.id)) : undefined,
  //           originalValue: originalValueStart? originalValueStart : undefined, 
  //           type: type, name: attribute})
  //       case 'TCout':
  //         const framesEnd = clipListItem ? clipListItem.end.frames : clip.end.frames
  //         const originalValueEnd = clipListItem?.originalClipTimes? time.getFormated({frames: clipListItem.originalClipTimes.end.frames}) : undefined
  //         return new ClipAttribute({
  //           // value: Globals.getTimecodeMS(framesEnd, this.getTimeBase(clip.id)), 
  //           value: time.getFormated({frames: framesEnd}), 
  //           // originalValue: clipListItem?.originalClipTimes? Globals.getTimecodeMS(clipListItem.originalClipTimes.end.frames, this.getTimeBase(clip.id)) : undefined, 
  //           originalValue: originalValueEnd? originalValueEnd : undefined,
  //           type: type, name: attribute})
  //       case 'duration':
  //         const framesDuration = clipListItem ? clipListItem.duration.frames : clip.duration.frames
  //         const originalValueDuration = clipListItem?.originalClipTimes? time.getFormated({frames: clipListItem.originalClipTimes.duration.frames}) : undefined
  //         return new ClipAttribute({
  //           // value: Globals.getTimecodeMS(framesDuration, this.getTimeBase(clip.id)), 
  //           value: time.getFormated({frames: framesDuration}), 
  //           // originalValue: clipListItem?.originalClipTimes? Globals.getTimecodeMS(clipListItem.originalClipTimes.duration.frames, this.getTimeBase(clip.id)) : undefined, 
  //           originalValue: originalValueDuration? originalValueDuration : undefined,
  //           type: type, name: attribute})
  //       default:
  //         if ( attribute in clip.attributes && clip.attributes[attribute] )
  //           return new ClipAttribute({...clip.attributes[attribute], type: type, name: attribute})
  //     }
  //   }
  //   return new ClipAttribute({value: undefined, name: attribute, type: 'clip'})
  // }

  setTimeFormat(timeFormat:string){
    // const checkTimeFormat = new RegExp('((hh|h|H|mm|m|M|rss|rs|rS|ss|s|S|ff|f|F):?)')
    // if(timeFormat.match(checkTimeFormat))
    this.timeFormat = timeFormat
    this.updateVisiblePartsSorted()
    return true
  }

  setTimelistOption(key: string, value: boolean) {
    this.timelistOptions.set(key, value)
  }
  getTimelistOption(key: string) {
    return this.timelistOptions.has(key) ? this.timelistOptions.get(key)! : false
  }
}