import React, { useState, useRef, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next'

import { AttributeRule } from '../__tsTimelineExport'
import { useStore } from '../../store'
import ModalCheckAction from '../../ModalCheckAction'

interface ModalRulesDropdownProps {
  rulesetId: string
  // ruleId: number
  // listItem: SortingRule
  rule: AttributeRule
}

const ModalRulesDropdown = (props: ModalRulesDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  
  const {attributeRulesetStore} = useStore()
  const { t } = useTranslation('clipReport')

  const toggle = (event?: any) => {
    if ( !deleteModalVisible ) {
      setDropdownOpen(prevState => !prevState)
    }
  }

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevDeleteModalVisible = usePrevious(deleteModalVisible)
  if (dropdownOpen && !deleteModalVisible && prevDeleteModalVisible) {
    toggle()
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret outline></DropdownToggle>
      <DropdownMenu right>
        <ModalCheckAction 
          modalTitle={t('ruleset.deleteConfirmRule')} 
          callback={() => attributeRulesetStore.setAttributeRulesetRule(props.rulesetId, props.rule, true, true)}
          isVisible={setDeleteModalVisible}
          >
          <DropdownItem toggle={false}>{t('ruleset.delete')}</DropdownItem>
        </ModalCheckAction>
        
        <DropdownItem onClick={() => {
          attributeRulesetStore.setAttributeRulesetRule(props.rulesetId, {...props.rule, enabled: !props.rule.enabled }, undefined, true)
        }}>{
          props.rule.enabled ? t('ruleset.disable') : t('ruleset.enable')
        }</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ModalRulesDropdown;