import ClipGroup from "./ClipGroup"

export default class ClipAttribute {

  name: string
  value?: string | boolean | ClipGroup [] | null
  type: 'clip' | 'file'
  originalValue?: string

  constructor({name, value, type, originalValue}: {name: string, value?: string | boolean | ClipGroup [] | null, type: 'clip' | 'file', originalValue?: string}) {
    this.name = name
    this.value = value
    this.type = type
    this.originalValue = originalValue
  }

  public getValue = (valueType?: 'string' | 'boolean' | 'ClipGroupArray') => {
    switch (valueType) {
      case 'string':
        return typeof this.value === 'string' ? this.value : ''
      case 'boolean':
        return typeof this.value === 'boolean' ? this.value : false
      case 'ClipGroupArray':
        return typeof this.value === 'object' ? this.value : []
      default:
        return this.value
    }
  }

  get valueString() {
    return typeof this.value === 'string' ? this.value : ''
  }

  getValueString = (ifNotSet?: string) => {
    return typeof this.value === 'string' ? this.value : (ifNotSet !== undefined ? ifNotSet : '')
  }

  getOriginalValueString = (ifNotSet?: string) => {
    return typeof this.originalValue === 'string' ? this.originalValue : (ifNotSet !== undefined ? ifNotSet : '')
  }

  get valueBoolean() {
    return typeof this.value === 'boolean' ? this.value : false
  }

  getValueBoolean = (ifNotSet?: boolean) => {
    return typeof this.value === 'boolean' ? this.value : (ifNotSet !== undefined ? ifNotSet : false)
  }

  getValueClipGroupArray = () => {
    return typeof this.value === 'object' ? this.value : []
  }

}