import React, { useState } from 'react';
import { Row, Col, ButtonGroup, FormGroup, CustomInput, Button, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next'

// import * as Globals from './Functions'
import { AttributeAvailable } from '../__tsTimelineExport'
import TextInput from '../TextInput'
import { useStore } from '../../store'
import ModalCheckAction from '../../ModalCheckAction'
// import ModalRulesDropdown from './ModalRulesDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ModalEditProps  {
  index: number
  rulesetId: string
  attribute: AttributeAvailable
}

const ModalRulesAttribute = (props: ModalEditProps) => {
  const index = props.index
  const [attribute, setAttribute] = useState( props.attribute )

  const {attributeRulesetStore} = useStore()
  const { t } = useTranslation('clipReport')

  // const changeAttribute = () => {
  //   // store.setAttributeRulesetRule(rulesetId, rule)
  // }

  return (
    <div className={'attribute'} key={'attribute-'+index}>
      <ModalCheckAction 
        modalTitle='Soll das Attribut wirklich gelöscht werden?' 
        callback={() => attributeRulesetStore.setAttributeRulesetAttribute(props.rulesetId, props.attribute, true, true)}
        >
        <Button color='secondary' outline size="sm" className='attribute-options-btn'><FontAwesomeIcon icon="trash" /></Button>
      </ModalCheckAction>
      {/* <ModalRulesDropdown rulesetId={rulesetId} rule={rule}/> */}
      <h4>{t('ruleset.attribute')}: {attribute.displayName ? attribute.displayName : attribute.key}</h4>
      <Row className='attribute-key-name-header'>
        <Col>
          <TextInput id={'attribute-display-name-'+index} value={attribute.displayName ? attribute.displayName : ''} label={t('ruleset.attributeName')} labelSize={3}
            callback={({newVal}) => {
              setAttribute({...attribute, displayName: newVal})
              // changeRule()
            }} />
        </Col>
        <Col>
          <TextInput id={'attribute-key-'+index} value={attribute.key} label='Schlüssel' labelSize={3}
            callback={({newVal}) => {
              setAttribute({...attribute, key: newVal})
              // changeRule()
            }} />
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          {/* <FormGroup>
            <CustomInput type="switch" id={"attribute-switch-type-"+index} name="customSwitch" label="Attribut für: Clip <–> Datei" 
              checked={attribute.type === 'file'}
              onChange={(evt: any) => {
                evt.target.blur()
                setAttribute({...attribute, type: evt.target.checked ? 'file' : 'clip'})
                // changeRule()
              }}
              />
          </FormGroup> */}
          <FormGroup row>
            <Label size="sm" sm={3}>{t('ruleset.attributeFor')}</Label>
            <Col sm={9}>
              <ButtonGroup>
                <Button outline size="sm" color="primary" onClick={() => setAttribute({...attribute, type: 'clip'})} active={attribute.type === 'clip'}>{t('ruleset.clip')}</Button>
                <Button outline size="sm" color="primary" onClick={() => setAttribute({...attribute, type: 'file'})} active={attribute.type === 'file'}>{t('ruleset.file')}</Button>
              </ButtonGroup>
            </Col>
          </FormGroup>
        </Col>
        <Col md='6'>
          <FormGroup>
            <CustomInput type="switch" id={"attribute-switch-not-editable-"+index} name="customSwitch" label={t('ruleset.lockForUser')}
              checked={attribute.notEditable}
              onChange={(evt: any) => {
                evt.target.blur()
                setAttribute({...attribute, notEditable: evt.target.checked })
                // changeRule()
              }}
              />
          </FormGroup>
        </Col>
        <Col md='6'>
          {/* <FormGroup>
            <CustomInput type="switch" id={"attribute-switch-value-type-"+index} name="customSwitch" label="Wert-Typ: Zeichen – Wahr/Falsch" 
              checked={attribute.valueType !== undefined && attribute.valueType === 'string'}
              onChange={(evt: any) => {
                evt.target.blur()
                setAttribute({...attribute, valueType: evt.target.checked ? 'string' : 'boolean'})
                // changeRule()
              }}
              />
          </FormGroup> */}
          <FormGroup row>
            <Label size="sm" sm={3}>{t('ruleset.valueType')}</Label>
            <Col sm={9}>
              <ButtonGroup>
                <Button outline size="sm" color="primary" onClick={() => setAttribute({...attribute, valueType: 'string'})} active={typeof attribute.valueType === 'undefined' || attribute.valueType === 'string'}>{t('ruleset.string')}</Button>
                <Button outline size="sm" color="primary" onClick={() => setAttribute({...attribute, valueType: 'boolean'})} active={attribute.valueType === 'boolean'}>{t('ruleset.boolean')}</Button>
                <Button outline size="sm" color="primary" onClick={() => setAttribute({...attribute, valueType: 'ClipGroupArray'})} active={attribute.valueType === 'ClipGroupArray'} disabled>{t('ruleset.group')}</Button>
              </ButtonGroup>
            </Col>
          </FormGroup>
        </Col>
        <Col md='6'>
          <FormGroup>
            <CustomInput type="switch" id={"attribute-switch-hide-in-export-"+index} name="customSwitch" label={t('ruleset.hideInExport')} 
              checked={attribute.hideInExport}
              onChange={(evt: any) => {
                evt.target.blur()
                setAttribute({...attribute, hideInExport: evt.target.checked })
                // changeRule()
              }}
              />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default ModalRulesAttribute;