import { makeAutoObservable } from "mobx"
import randomstring from 'randomstring'

import * as GlobalTS from '../../ts/__tsGlobal'
import * as StoryboardTS from '../../ts/__storyboard'
import * as StoryboardFunctions from '../storyboard/StoryboardFuntions'
import { RootStore } from './RootStore'
import UserService from '../../auth/user-service'
// import { now } from "jquery"
// import StoryboardScene, { StoryboardSceneItem } from '../../classes/storyboard/StoryboardScene'

// interface ItemArr extends StoryboardTS.Item {
//   id: string
// }

interface Column {
  name: string
  types: StoryboardTS.ColumnType[]
  droppable: boolean
  generateFunction?: Function
  pdfWidth?: number | string
}

export class StoryboardStore {
  rootStore: RootStore
  // scenes: StoryboardScene[]
  // items: StoryboardSceneItem[]
  edit: {
    available: boolean
    active: boolean
  }

  items: {
    [id: string]: StoryboardTS.Item
  }
  videos: {
    [id: string]: StoryboardTS.Video
  }
  columns: {
    [id: string]: Column
  }
  columnOrder: string[]
  columnTypes: {
    [id: string]: {
      name: string
    }
  }
  scenes: {
    [id: string]: {
      title: string
      cells: {
        [columnId: string]: {
          itemsOrder: string[]
        }
      }
    }
  }
  sceneOrder: string[]
  // items objects: generated, text or image
  // cell objects with ordered array of items and has a columnId
  // Columns objects with columnId, name
  // columnOrder array
  // row/scene objects with all the cell ids
  // row/sceneOrder array
  selecetedItemId?: string
  selectedVideo?: StoryboardTS.VideoSelected
  // selectedVideoId: string | undefined
  // selectedVideoSelection: {
  //   inPoint: number
  //   outPoint: number
  // } | undefined

  metadata: {
    storyboardId?: number
    version: number
    name: string
    date: string
    client?: string
    projectId?: string
  }

  serverStoryboards: {
    request: GlobalTS.ServerRequest
    data: StoryboardTS.ServerStoryboards[]
  }

  loadingStoryboard: GlobalTS.ServerRequestStatus

  constructor(rootStore: RootStore){
    this.rootStore = rootStore
    // this.table = {cols: ['#','Bezeichnung','Bild','Text','Dauer','Total'], rows: []}
    this.metadata = {
      storyboardId: 2,
      name: 'neues Projekt',
      version: 1,
      date: today()
    }
    this.scenes = {}
    this.columnOrder = defaultColumnOrder
    this.columns = defaultColumns
    this.columnTypes = defaultColumnTypes
    this.items = {}
    this.sceneOrder = []
    this.videos = {}
    this.selectedVideo = undefined
    this.selecetedItemId = undefined
    // this.selectedVideoId = undefined
    // this.selectedVideoSelection = undefined
    this.serverStoryboards = {request: {status: 'not requested', timestamp: new Date().getTime()}, data: []}
    this.loadingStoryboard = 'not requested'
    this.edit = {available: true, active: false}
    makeAutoObservable(this)
  }

  resetStoryboardData(){
    this.metadata = {
      storyboardId: 2,
      name: 'neues Projekt',
      version: 1,
      date: today()
    }
    this.scenes = {}
    this.columnOrder = defaultColumnOrder
    this.columns = defaultColumns
    this.columnTypes = defaultColumnTypes
    this.items = {}
    this.sceneOrder = []
    this.videos = {}
    this.selectedVideo = undefined
    this.selecetedItemId = undefined
  }

  updateMetadata({name, version, storyboardId, client, projectId}: {name?: string, version?: number, storyboardId?: number, client?: string, projectId?: string}) {
    if(name)          this.metadata.name = name
    if(version)       this.metadata.version = version
    if(storyboardId)  this.metadata.storyboardId = storyboardId
    if(client)        this.metadata.client = client
    if(projectId)     this.metadata.projectId = projectId
  }

  toggleEditActive(){
    if(!this.edit.available) this.edit.active = false
    this.edit.active = ! this.edit.active
  }

  get droppableColumnIds() {
    return Object.entries(this.columns).filter((col)=>col[1].droppable).map(col=>col[0])
  }

  get droppableColumns() {
    return Object.entries(this.columns).filter((col)=>col[1].droppable).map(col=>({id: col[0], ...col[1]}))
  }

  addScene({name, duration}: {name: string, duration?: number}){
    const sceneId = randomstring.generate(5)
    let cells: {
      [columnId: string]: {
        itemsOrder: string[]
      }
    } = {}
    for (const key in this.columns) {
      if (Object.prototype.hasOwnProperty.call(this.columns, key)) {
        const element = this.columns[key];
        if (element.droppable) {
          cells[key] = {itemsOrder: []}
        }
      }
    }
    this.scenes[sceneId] = {
      title: name,
      cells
    }
    this.sceneOrder.push(sceneId)
  }

  removeScene(sceneId: string){
    // remove of sceneOrder
    const scenePosition = this.sceneOrder.indexOf(sceneId)
    if ( scenePosition >= 0 ) {
      this.sceneOrder.splice( scenePosition, 1 )
    }

    // remove items
    const allItemIds = Object.values(this.scenes[sceneId].cells).map((cell)=>cell.itemsOrder).flat(1)
    allItemIds.forEach(itemId=> {
      this.removeItem(itemId, sceneId)
    })

    // remove of scenes
    delete this.scenes[sceneId]
  }

  getItemIdsOfScene(sceneId: string){
    return Object.values(this.scenes[sceneId].cells).map((cell)=>cell.itemsOrder).flat(1)
  }

  sceneDuration(sceneId: string) {
    if(this.scenes.hasOwnProperty(sceneId)){
      const allItemIds = Object.values(this.scenes[sceneId].cells).map((cell)=>cell.itemsOrder).flat(1)
      return allItemIds.map(itemId => this.items[itemId]).filter(item=>item !== undefined).reduce((sum, item) => StoryboardFunctions.getItemDuration(item) + sum, 0)
    }
    return 0
  }

  addItem({type, name, content, duration, columnId, sceneId, video, linkedSceneItems}:{type: StoryboardTS.ItemType, name: string, content?: string, duration?: number, video?: StoryboardTS.VideoSelected, columnId: string, sceneId: string, linkedSceneItems?: string[]}){
    if(this.scenes.hasOwnProperty(sceneId)){
      if(this.scenes[sceneId].cells.hasOwnProperty(columnId)) {
        const itemId = randomstring.generate(5)
        this.items[itemId] = {
          name,
          type,
          linkedSceneItems: linkedSceneItems ? linkedSceneItems : [],
          content,
          duration,
          video
        }
        this.scenes[sceneId].cells[columnId].itemsOrder.push(itemId)
        return itemId
      }
    }
  }

  removeItem(itemId: string, sceneId: string) {
    if(this.scenes.hasOwnProperty(sceneId)){
      for (const key in this.scenes[sceneId].cells) {
        if (Object.prototype.hasOwnProperty.call(this.scenes[sceneId].cells, key)) {
          const cell = this.scenes[sceneId].cells[key]
          const itemPosition = cell.itemsOrder.indexOf(itemId)
          if ( itemPosition >= 0 ) {
            cell.itemsOrder.splice( itemPosition, 1 )
            delete this.items[itemId]
          }
        }
      }
    }
  }

  moveItem(destination: StoryboardTS.ItemDragDrop, source: StoryboardTS.ItemDragDrop) {
    const itemId = this.scenes[source.scenceId].cells[source.columnId].itemsOrder[source.index]
    if ( destination.scenceId === source.scenceId && destination.columnId === source.columnId ) {
      // move in cell
      console.log(destination.scenceId)
      console.log(JSON.stringify(this.scenes))
      const newItemsOrder = Array.from(this.scenes[destination.scenceId].cells[destination.columnId].itemsOrder)
      newItemsOrder.splice(source.index, 1)
      newItemsOrder.splice(destination.index, 0, itemId)
      this.scenes[destination.scenceId].cells[destination.columnId].itemsOrder = newItemsOrder
    } else {
      // move to other cell
      const newItemsOrderDestination = Array.from(this.scenes[destination.scenceId].cells[destination.columnId].itemsOrder)
      newItemsOrderDestination.splice(source.index, 0, itemId)
      this.scenes[destination.scenceId].cells[destination.columnId].itemsOrder = newItemsOrderDestination
      const newItemsOrderSource = Array.from(this.scenes[source.scenceId].cells[source.columnId].itemsOrder)
      newItemsOrderSource.splice(source.index, 1)
      this.scenes[source.scenceId].cells[source.columnId].itemsOrder = newItemsOrderSource
    }
  }

  updateItemValue(itemId: string, {duration, content, video, linkedSceneItems}:{duration?: number, content?: string, video?: StoryboardTS.VideoSelected, linkedSceneItems?: string[]|string }) {
    // console.log(JSON.parse(JSON.stringify(this.items[itemId])))
    if(this.items.hasOwnProperty(itemId)){
      // console.log('item exists')
      if (typeof duration !== 'undefined') {
        console.log('update duration')
        console.log(duration)
        this.items[itemId].duration = duration
      }
      if (typeof content !== 'undefined') {
        if(content.startsWith('blob:')){
          // const blob = 
          fetch(content).then(r => {
            r.blob().then(blob => {
              // its a blob letz upload it
              const reader = new FileReader()
              reader.addEventListener('load', ()=>{
                if(typeof reader.result === 'string')
                  UserService.uploadImage({dataUri: reader.result}).then(body => {
                    const previewUrl = 'http://' + body.replace(/\\\//g, "/").replace(/^"|"$/g, "")
                    this.items[itemId].content = previewUrl
                  })
              })
              reader.readAsDataURL(blob)
            })
          })
        } else {
          this.items[itemId].content = content
        }        
      }
      if (typeof video !== 'undefined') {
        console.log(video)
        this.items[itemId].video = video
        this.items[itemId].duration = video.selection ? Math.round(video.selection.duration) : 0
        console.log(video.selection ? Math.round(video.selection.duration) : 0)

        // if video preview url is data uri upload image and update url
        if(video.preview?.url && video.preview.url.search(/^http/) === -1) {
          const filename = this.items[itemId].content 
            ? this.items[itemId].content!.match(/([^\/]+)(?=\.\w+$)/)![0] 
            : undefined
          UserService.uploadImage({ dataUri: video.preview.url, filename: filename }).then((body: any) =>{
            const previewUrl = 'http://' + body.replace(/\\\//g, "/").replace(/^"|"$/g, "")
            this.items[itemId].content = previewUrl
            if (this.items[itemId].video?.preview?.url) {
              this.items[itemId].video!.preview!.url = previewUrl
            }
            // console.log('update image to uploaded url', itemId)
            // this.updateItemValue(itemId,{content: previewUrl, video: this.selectedVideo})
          })
        }
      }
      if (typeof linkedSceneItems !== 'undefined') {
        console.log(linkedSceneItems)
        if (typeof linkedSceneItems === 'string') {
          this.items[itemId].linkedSceneItems.push( linkedSceneItems )
        } else {
          this.items[itemId].linkedSceneItems = linkedSceneItems
        }
      }
    }
  }

  getSceneOfItem(itemId: string) {
    const scene = Object.entries( this.scenes ).find(scene => 
      Object.entries(scene[1].cells).filter(cell => cell[1].itemsOrder.find(item => item === itemId))
    )
    return scene ? scene[0] : undefined
  }

  addVideo({origin, originId, url, duration, fileName}:{origin: 'ppro' | 'shutterstock' | 'videohive', originId: string, url: string, duration: number, fileName?: string}){
    const videoId = randomstring.generate(5)
    this.videos[videoId] = {
      origin,
      originId,
      url,
      duration,
      fileName
    }
    return videoId
  }

  updateVideo(videoId: string, {preview}: {preview?: StoryboardTS.VideoPreview}){
    if(videoId in this.videos && preview){
      let res: RegExpMatchArray | null 
      const filename = this.videos[videoId].preview && (res = this.videos[videoId].preview!.url.match(/([^\/]+)(?=\.\w+$)/))
        ? res[0]
        : undefined

      this.videos[videoId].preview = preview

      // if video preview url is data uri upload image and update url
      if(preview?.url && preview.url.search(/^http/) === -1) {
        UserService.uploadImage({ dataUri: preview.url, filename: filename }).then((body: any) =>{
          const previewUrl = 'http://' + body.replace(/\\\//g, "/").replace(/^"|"$/g, "")
          
          if (this.videos[videoId].preview) {
            this.videos[videoId].preview!.url = previewUrl
          } else {
            this.videos[videoId].preview = {second: 0, url: previewUrl}
          }
          // console.log('update image to uploaded url', itemId)
          // this.updateItemValue(itemId,{content: previewUrl, video: this.selectedVideo})
        })
      }
    }
  }

  checkVideoUsage(videoId: string) {
    return Object.entries(this.items).filter(item => 
      item[1].video?.videoId
    ).map(item => ({id: item[0], ...item[1]}))
  }

  removeVideo(videoId: string) {
    if(this.videos.hasOwnProperty(videoId)){
      delete this.videos[videoId]
      const itemUsingVideo = this.checkVideoUsage(videoId)
      itemUsingVideo.forEach(item => {
        const sceneId = this.getSceneOfItem(item.id)
        if(sceneId)
        this.removeItem(item.id, sceneId)
      })
    }
  }

  // get selectedVideo() {
  //   return this.selecetedItemId && this.selecetedItemId in this.items ? this.items[this.selecetedItemId].video : undefined
  // }

  setSelectedItem(itemId?: string){
    this.selecetedItemId = itemId
  }

  setSelectedVideo(selectedVideo?: StoryboardTS.VideoSelected) {
    this.selectedVideo = selectedVideo
  }
  
  // setSelectedVideo(videoId?: string) {
  //   // this.selectedVideoId = videoId
  //   if(videoId && this.selectedVideo) {
  //     if(this.selectedVideo){
  //     // if(this.items[this.selecetedItemId].video){
  //     //   this.items[this.selecetedItemId].video!.videoId = videoId
  //       this.selectedVideo.videoId = videoId
  //     } else {
  //       this.selectedVideo = { videoId }
  //       // this.items[this.selecetedItemId].video = { videoId }
  //     }
  //   } 
  //   // else {
  //   //   // this.selectedVideo = undefined
  //   //   this.selecetedItemId = undefined
  //   // }
  // }

  updateSelectedVideo({selection, preview}: {selection?: StoryboardTS.Selection, preview?: StoryboardTS.VideoPreview}) {
    if(this.selectedVideo){
      if(selection){
        this.selectedVideo.selection = {...selection, duration: selection.outPoint - selection.inPoint}
      }
      if(preview){
        console.log('add dataUri')
        this.selectedVideo.preview = preview
      }
      console.log(JSON.parse(JSON.stringify(this.selectedVideo)))
    }
  }

  get selectedVideoOrigin() {
    if(typeof this.selectedVideo === 'undefined') return undefined
    return this.videos[this.selectedVideo.videoId]
  }

  // setSelectedVideoSelection(selection?: {inPoint: number, outPoint: number}) {
  //   if(selection && this.selectedVideo)
  //   this.items[this.selecetedItemId!].video = {
  //       ...this.selectedVideo,
  //       selection: {
  //         inPoint: selection.inPoint,
  //         outPoint: selection.outPoint,
  //         duration: selection.outPoint - selection.inPoint
  //       }
  //     }
  // }

  // addItem({sceneName}:{sceneName: string}){
  //   const findScene = this.scenes.filter(s => s.name === sceneName)
  //   if( findScene ){
  //     findScene[0].addItem({type: 'text', colName: 'Text'})
  //   }
  // }

  requestServerStoryboards() {
    // this.setServerStoryboards({...this.serverStoryboards, request: {status: 'loading', timestamp: new Date().getTime()}})
    this.serverStoryboards.request = {status: 'loading', timestamp: new Date().getTime()}
    UserService.getStoryboards().then((data: StoryboardTS.ServerStoryboards[]) =>{
      this.setServerStoryboards({
        request: {status: 'loaded', timestamp: this.serverStoryboards.request.timestamp}, 
        data
      })
    })
  }

  setServerStoryboards(sS: {
    request: GlobalTS.ServerRequest
    data: StoryboardTS.ServerStoryboards[]
  }) {
    this.serverStoryboards = sS
  }

  saveStoryboard() {
    //isset($content["storyboardId"]) && isset($content["userGroupId"]) && isset($content["version"]) && isset($content["jsonString"]) && isset($content["name"])
    const storyboard = {
      ...this.metadata,
      jsonString: {
        scenes: this.scenes,
        sceneOrder: this.sceneOrder,
        columns: this.columns,
        columnOrder: this.columnOrder,
        columnTypes: this.columnTypes,
        items: this.items,
        videos: this.videos 
      }
    }
    // console.log(JSON.parse(JSON.stringify(this.metadata)))
    UserService.saveStoryboard(JSON.stringify(storyboard)).then((body: any) =>{
      console.log(body)
    })
  }

  loadStoryboard(id: number) {
    this.loadingStoryboard = 'loading'
    UserService.getStoryboard(id).then((data: any) =>{
      console.log(data)
      if( data.hasOwnProperty('jsonString') && data.jsonString) {
        
        this.items = data.jsonString.items ? data.jsonString.items : {}
        this.videos = data.jsonString.videos ? data.jsonString.videos : {}
        this.columnTypes = data.jsonString.columnTypes ? data.jsonString.columnTypes : defaultColumnTypes
        this.columns = data.jsonString.columns ? data.jsonString.columns : defaultColumns
        this.columnOrder = data.jsonString.columnOrder ? data.jsonString.columnOrder : defaultColumnOrder
        this.scenes = data.jsonString.scenes ? data.jsonString.scenes : {}
        this.sceneOrder = data.jsonString.sceneOrder ? data.jsonString.sceneOrder : []

        console.log(data.jsonString)
        this.loadingStoryboard = 'loaded'

      } else {
        this.loadingStoryboard = 'error'
      }
    })
  }

  cleanupSession(){
    this.scenes = {}
    this.columnOrder = defaultColumnOrder
    this.columns = defaultColumns
    this.columnTypes = defaultColumnTypes
    this.items = {}
    this.sceneOrder = []
    this.videos = {}
    this.selectedVideo = undefined
    this.selecetedItemId = undefined
    this.serverStoryboards = {request: {status: 'not requested', timestamp: new Date().getTime()}, data: []}
    this.loadingStoryboard = 'not requested'
    this.edit = {available: true, active: false}
  }

}

// //**blob to dataURL**
// function blobToDataURL(blob: Blob, callback: Function) {
//   var a = new FileReader();
//   a.onload = function(e) {callback(e.target!.result); console.log(e.target?.result)}
//   a.readAsDataURL(blob);
//   console.log('read')
// }

const today = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return mm + '.' + dd + '.' + yyyy;
}

const defaultColumnOrder = ['position','description','image','text','durationScene','durationTotal']

const defaultColumns: { [id: string]: Column } = {
  'position': {
    name: '#',
    droppable: false,
    types: ['generated', 'headline'],
    pdfWidth: '0.3cm'
  },
  'description': {
    name: 'Bezeichnung',
    droppable: true,
    types: ['text'],
    pdfWidth: '6cm'
  },
  'image': {
    name: 'Bild',
    droppable: true,
    types: ['text','image','video'],
    pdfWidth: '5cm'
  },
  'text': {
    name: 'Text',
    droppable: true,
    types: ['text'],
    pdfWidth: '10cm'
  },
  'durationScene': {
    name: 'Dauer',
    droppable: false,
    types: ['generated', 'time'],
    pdfWidth: '1cm'
  },
  'durationTotal': {
    name: 'Total',
    droppable: false,
    types: ['generated', 'time'],
    pdfWidth: '1cm'
  },
}

const defaultColumnTypes = {
  text: {
    name: 'Text'
  },
  image: {
    name: 'Bild'
  },
  video: {
    name: 'Video'
  },
  headline: {
    name: 'Überschrift'
  },
  time: {
    name: 'Zeit'
  },
  generated: {
    name: 'Automatisch erzeugt'
  }
}