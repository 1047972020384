import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, CustomInput, Button, Input, Label, Form, Container } from 'reactstrap';
// import { observer } from "mobx-react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

// import * as Globals from './Functions'
import { AttributeRule } from '../__tsTimelineExport'
import TextInput from '../TextInput'
import { useStore } from '../../store'
import ModalCheckAction from '../../ModalCheckAction'
import ModalRulesDropdown from './ModalRulesDropdown'
import AttributeText from './AttributeText';
import ClipGroup from '../../../classes/ClipGroup';
// import TextEditable from '../TextEditable'

interface ModalEditProps  {
  index: number
  rulesetId: string
  rule: AttributeRule
}

const ModalRulesRule = (props: ModalEditProps) => {
  const index = props.index
  const rulesetId = props.rulesetId
  const [rule, setRule] = useState( props.rule )

  const {attributeRulesetStore} = useStore()
  const { t } = useTranslation('clipReport')
  const classActive = rule.enabled ? '' : ' rule-disabled'

  useEffect(()=>{
    // console.log('change Rule')
    // console.log(JSON.parse(JSON.stringify(rule)))
    attributeRulesetStore.setAttributeRulesetRule(rulesetId, rule, undefined, true)
  },[rulesetId, rule, attributeRulesetStore])

  return (
    <div className={'rule'+classActive} key={'rule-'+index}>
      <ModalRulesDropdown rulesetId={rulesetId} rule={rule}/>
      {/* <h4>Regel: {rule.search.regex.toString().replace(/\//g,'')}</h4> */}
      <h4>{t('ruleset.rule')}: {rule.name}</h4>
      {/* <div>
        <TextEditable
          initValue={rule.name ? rule.name : ''}
          callback={(name: string) => {
            console.log(name)
            setRule({...rule, name})
            changeRule()
          }}
          // htmlClosure={{before: '<h4>', after: '</h4>'}}
        />
      </div> */}
      <TextInput id={'rule-search-name-'+index} value={rule.name?rule.name:''} label={t('ruleset.ruleName')} labelSize={3}
        callback={({newVal}) => setRule({...rule, name: newVal}) } />
        
      <h5>{t('ruleset.searchFor')}:</h5>
      {rule.search.map((src, srcIdx) => (
        <div className='rule-search' key={'rule-search-'+index+'-'+srcIdx}>
          <div className='rule-search-header'>
            <Row>
              <Col lg='5'>
                <TextInput id={'rule-search-name-'+index+'-'+srcIdx} value={src.name} label={t('ruleset.searchParamName')} labelSize={3}
                callback={({newVal}) => {
                  setRule({...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, name: (newSrcIdx === srcIdx) ? newVal : newSrc.name}))})
                }} />
              </Col>
              <Col lg='3' className='rule-search-header-required'>
                <FormGroup className={srcIdx === 0 ? 'disabled' : ''}>
                  <CustomInput type="switch" id={`rule-search-required-${index}-${srcIdx}`} name="customSwitch" label={t('ruleset.required')}
                    disabled={srcIdx === 0 ? true : false}
                    checked={src.required}
                    onChange={(evt: any) => {
                      evt.target.blur()
                      setRule({...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, required: (newSrcIdx === srcIdx) ? evt.target.checked : newSrc.required}))})
                    }}
                    />
                </FormGroup>
              </Col>
              <Col lg='2'>
                {/* <span>{src.varIndex}</span> */}
              </Col>
              <Col lg='2'>
                {srcIdx !== 0 &&
                <ModalCheckAction 
                  modalTitle={t('ruleset.deleteConfirmSearchParam')}
                  callback={() => {
                    setRule({...rule, search: rule.search.filter(s => s.name !== src.name)})
                  }}
                  >
                    <Button title={t('ruleset.remove')} color='secondary' size='sm' outline><FontAwesomeIcon icon="trash" /></Button>
                </ModalCheckAction>}
              </Col>
            </Row>
            
            {/* <ButtonGroup size='sm'>
              <UncontrolledButtonDropdown>
                <DropdownToggle caret size='sm'>
                  Nötig
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Ja</DropdownItem>
                  <DropdownItem>Nein</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </ButtonGroup> */}
            
          </div>
          <div className='rule-search-body'>
            {/* <Form className='text-input'> */}
            <Form style={{marginTop: '10px'}}>
              <Row form>
                <Col md={8}>
                  <TextInput id={'rule-search-'+index+'-'+srcIdx} value={src.regex.pattern} label={t('ruleset.searchString')} options={{justFormGroup: true}}
                    callback={({newVal}) => {
                      setRule({...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, regex: {...newSrc.regex, pattern: (newSrcIdx === srcIdx) ? newVal : newSrc.regex.pattern}}))})
                      // rule.search[srcIdx].regex.pattern = newVal
                      // store.setAttributeRulesetRule(rulesetId, {...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, regex: {...newSrc.regex, pattern: (newSrcIdx === srcIdx) ? newVal : newSrc.regex.pattern}}))})
                    }} />
                </Col>
                <Col md={4}>
                  <FormGroup row>
                    <Label sm={2} size='sm'>
                      in
                    </Label>
                    <Col sm={10}>
                      <Input type="select" name="selectType" id={"rule-selectType-"+index+'-'+srcIdx} bsSize="sm"
                        value={src.type}
                        onChange={(evt: any) => {
                          setRule({...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, type: newSrcIdx === srcIdx ? evt.target.value : newSrc.type}))})
                          // store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, search: {...rule.search, type: evt.target.value}}})
                          }}>
                        {Object.entries(attributeRulesetStore.attributeSearchTypes).map((type, idx) => <option value={type[0]} key={'rule-'+index+'-reg-'+idx}>{type[1]}</option>)}
                        {/* {Object.values(Globals.REGEXLIST.TYPE).map((el, i) => <option key={'rule-'+index+'-reg-'+i}>{el}</option>)} */}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              
              <Row form>
                <Col md={6}>
                  <FormGroup check>
                    <Input type='checkbox' checked={src.regex.flags === 'i' ? true : false} 
                      onChange={(evt: any) => {
                        setRule({...rule, search: rule.search.map((newSrc, newSrcIdx) => ({...newSrc, regex: {...newSrc.regex, flags: newSrcIdx === srcIdx ? (evt.target.checked ? 'i' : '') : newSrc.regex.flags}}))})
                        // rule.search[srcIdx].regex.flags = evt.target.checked ? 'i' : ''
                        // store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, search: {...rule.search, caseSensitive: evt.target.checked}}})
                        }}/>
                    <Label size='sm'>
                      {t('ruleset.ignoreUpperLowerCase')}
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup row>
                    <Label sm={4} size='sm' for={'search-group-index-'+index+'-'+srcIdx}>
                      {t('ruleset.regexIndex')}
                    </Label>
                    <Col sm={8}>
                      <Input
                        bsSize="sm"
                        type="number"
                        name="number"
                        id={'search-group-index-'+index+'-'+srcIdx}
                        placeholder="Standard 0"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ))}
      <div className='add-new-rule' onClick={() => {
        setRule({...rule, search: [...rule.search, {
          name: t('ruleset.newSearchParam'),
          type: 'folderAndFile',
          regex: {pattern: '', flags: ''},
          required: false
        }]})
      }}>
        <span><FontAwesomeIcon icon='plus-circle' size='2x'/><br/>
        {t('ruleset.addSearchParam')}</span>
      </div>
      <h5>{t('ruleset.setAttribute')}:</h5>
      {rule.result.map((res, resIdx) => 
        <div className='rule-result' key={'rule-result-'+index+'-'+resIdx}>
          <div className='rule-result-header'>
            <Container>
              <Row>
                <Col lg='4'>
                  <Form inline>
                    <FormGroup row>
                      <Label for={"attributeSelect"+index+'_'+resIdx} size="sm">{t('ruleset.attribute')}</Label>
                      <Col><Input type="select" bsSize='sm' name="select" id={"attributeSelect"+index+'_'+resIdx} value={res.key} onChange={(evt: any) => setRule({...rule, result: rule.result.map((newRes, newResIdx) => ({...newRes, key: newResIdx === resIdx ? evt.target.value : newRes.key}))}) }>
                        {attributeRulesetStore.availableAttributes.map((attribute, attrIdx) => {
                          return <option value={attribute.key} key={'rule-result-attribute-option-'+attrIdx} disabled={
                            res.key === attribute.key ? false : (typeof rule.result.find(r=>r.key === attribute.key) === 'object')
                          }>{attribute.displayName ? attribute.displayName : attribute.key}</option>})}
                      </Input></Col>
                    </FormGroup>
                  </Form>
                  {/* <TextInput id={'result-key-'+index+'-'+resIdx} value={res.key} label='Attribut' labelSize={3}
                    callback={({newVal}) => {
                      setRule({...rule, result: rule.result.map((newRes, newResIdx) => ({...newRes, key: newResIdx === resIdx ? newVal : newRes.key}))})
                      changeRule()
                      // store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, result: {...rule.result, source: {...rule.result.source!, owner: newVal} }}})
                      }} /> */}
                </Col>
                <Col lg={{ size: 2, offset: 6 }}>
                  <ModalCheckAction 
                    modalTitle={t('ruleset.deleteConfirmAttribute')}
                    callback={() => {
                      setRule({...rule, result: rule.result.filter(r => r.key !== res.key)})
                    }}
                    >
                    <Button title={t('ruleset.remove')} color='secondary' size='sm' outline><FontAwesomeIcon icon="trash" /></Button>
                  </ModalCheckAction>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='rule-result-body'>
            {(()=>{
              const attr = attributeRulesetStore.availableAttributes.find(a => a.key === res.key)
              if(attr) {
                switch(attr.valueType) {
                  case 'boolean':
                    return <FormGroup row>
                        <Label sm={2} size='sm'>
                          {t('ruleset.boolean')}
                        </Label>
                        <Col sm={10}>
                          <Input className='checkbox-right' type='checkbox' checked={typeof res.value === 'boolean' ? res.value : false}
                            onChange={(evt: any) => {
                              setRule({...rule, result: rule.result.map((newRes, newResIdx) => ({...newRes, value: newResIdx === resIdx ? evt.target.checked : newRes.value}))})
                            }}/>
                        </Col>
                      </FormGroup>
                  default: 
                    return <FormGroup row>
                        <Label sm={2} size='sm'>
                          {t('ruleset.value')}
                        </Label>
                        <Col sm={10}>
                          <AttributeText rule={rule} initValue={res.value as string} callback={(newVal)=>setRule({...rule, result: rule.result.map((newRes, newResIdx) => ({...newRes, value: newResIdx === resIdx ? newVal : newRes.value}))})}/>
                        </Col>
                      </FormGroup>
                  // default:
                  //   return <TextInput id={'result-value-'+index+'-'+resIdx} value={res.value as string} label='Wert'
                  //     callback={({newVal}) => {
                  //       console.log(newVal)
                  //       setRule({...rule, result: rule.result.map((newRes, newResIdx) => ({...newRes, value: newResIdx === resIdx ? newVal : newRes.value}))})
                  //       // store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, result: {...rule.result, source: {...rule.result.source!, rights: newVal} }}})
                  //     }} />
                }
              }
            })()}
          </div>
        </div>
      )}
      <div className='add-new-rule' onClick={() => {
        const attribute = attributeRulesetStore.availableAttributes.find(attr =>
          typeof rule.result.find(r=>r.key === attr.key) === 'undefined'
        )
        if(attribute) {
          let value: string | boolean | ClipGroup[]
          switch(attribute.valueType){
            case 'string':
              value = 'wert'
              break
            case 'boolean':
              value = false
              break
            case 'ClipGroupArray':
              value = []
              break
            default:
              value = ''
              break
          }
          setRule({...rule, result: [...rule.result, {
            key: attribute.key, 
            value: value
          }]})
        }
      }}>
        <span><FontAwesomeIcon icon='plus-circle' size='2x'/><br/>
        {t('ruleset.setValueForAttribute')}</span>
      </div>
      
      {/* <div className='rule-stock'>
        {rule.search.stock ? (
          <div>
            <ModalCheckAction
              modalTitle='Soll wirklich keine Stock Nummer gesucht werden?' 
              callback={() => store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {enabled:rule.enabled, search: {...rule.search, stock: undefined}, result: rule.result}})}
              >
              <Button style={{marginBottom: '5px',}} outline color='danger'>
                keine Stock Nummer suchen
              </Button>
            </ModalCheckAction>
            <TextInput id={'rule-stock-'+index} value={rule.search.stock.idSearchRegExp.toString()} label='Suchtext' 
              callback={({newVal}) => store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, search: {...rule.search, stock: {idSearchRegExp: stringToRegex(newVal) }}}})} />
          </div>
        ) : (
          <Button color="secondary" outline onClick={() => 
            store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {enabled:rule.enabled, search: {...rule.search, stock: {idSearchRegExp: new RegExp('\\d+')}}, result: rule.result}})
          }>Stock Nummer suchen</Button>
        )}
      </div>
      <h5>Setzen als ...</h5>
      <TextInput id={'rule-owner-'+index} value={rule.result.source!.owner} label='Urheber'
        callback={({newVal}) => store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, result: {...rule.result, source: {...rule.result.source!, owner: newVal} }}})} />
      <TextInput id={'rule-rights-'+index} value={rule.result.source!.rights} label='Rechte'
        callback={({newVal}) => store.setSortingRule({rulesetId: rulesetId, ruleId: index, rule: {...rule, result: {...rule.result, source: {...rule.result.source!, rights: newVal} }}})} /> */}
    </div>
  )
}

// const stringToRegex = (string: string) => {
//   const first = string.match(/^\//)
//   const start = first ? first.index!+1 : 0
  
//   const last = string.match(/\/$/)
//   const end = last ? last.index! : string.length

//   return new RegExp(string.substring(start,end))
// }

export default ModalRulesRule;