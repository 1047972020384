import React, { lazy, Suspense } from 'react';
import { Container, Row, Col } from "reactstrap";
import { observer } from "mobx-react"
// import 'mobx-react-lite/batchingForReactDom'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Switch, Route, useLocation } from 'react-router';

import { User } from './../ts/__tsGlobal' 
import NavbarGlobal from './Navbar'
import { useStore } from './store'
import FooterGlobal from './Footer';
import Loading from './Loading';
import Landing from '../pages/Landing';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Home = lazy(()=> import('../pages/Home'))
const Login = lazy(()=> import('../pages/Login'))
const About = lazy(()=> import('../pages/About'))
const TimelineExportVM = lazy(()=> import('./clipReport/TimelineExportVM'))
const StoryboardVM = lazy(()=> import('./storyboard/StoryboardVM'))
const Dashboard = lazy(()=> import('../pages/Dashboard'))
const Imprint = lazy(()=> import('../pages/Imprint'))
const Privacy = lazy(()=> import('../pages/Privacy'))
const Register = lazy(()=> import('../pages/Register'))
const Account = lazy(()=> import('../pages/Account'))
const Error404 = lazy(()=> import('../pages/Error404'))
const TimelineExportVersioninfos = lazy(()=> import('./clipReport/TimelineExportVersioninfos'))
const Development = lazy(()=> import('../pages/Development'))


const ViewManager = observer(() => {
  const {globalStore} = useStore()

  // check localStorage
  if(!globalStore.loggedIn && localStorage.getItem('user')) {
    const user: User = JSON.parse(localStorage.getItem('user')!)
    const jwtPayload = parseJwt( user.accessToken )
    // const user = JSON.parse(localStorage.getItem('user')!)
    if(jwtPayload.hasOwnProperty('userId') && user.hasOwnProperty('id') && jwtPayload.userId === user.id){
      globalStore.setLoggedIn(user)
    }
    // console.log(jwtPayload)
  }

  const location = useLocation()

  return (
    <>
    <Container fluid={true}>
      <Row>
        <Col>
          <Suspense fallback={<Loading />}>
            <NavbarGlobal />
          </Suspense> 
        </Col>
      </Row>
    </Container>
    <SwitchTransition mode={'out-in'}>
      <CSSTransition key={location.key} classNames='fade' timeout={300}>
        <Suspense fallback={<Loading />}>
          <Switch location={location}>
            <Route exact path='/'>
              <Home/>
            </Route>
            <Route exact path='/imprint'>
              <Imprint/>
            </Route>
            <Route exact path='/privacy'>
              <Privacy/>
            </Route>
            <Route path='/about'>
              <Container>
                <About/>
              </Container>
            </Route>
            <Route path='/landing'>
              <Landing />
            </Route>
            <PublicRoute path='/register'>
              <Container>
                <Register/>
              </Container>
            </PublicRoute>
            <PublicRoute path='/login'>
              <Container>
                <Login/>
              </Container>
            </PublicRoute>
            <PrivateRoute path='/account'>
              <Container>
                <Account/>
              </Container>
            </PrivateRoute>
            <PrivateRoute path='/dashboard'>
              <Dashboard/>
            </PrivateRoute>
            <PrivateRoute path='/timeline-export'>
              <TimelineExportVM/>
            </PrivateRoute>
            <Route path='/storyboard'>
              <StoryboardVM/>
            </Route>
            <Route path='/versioninfos'>
              <TimelineExportVersioninfos />
            </Route>
            <Route path='/development'>
              <Container>
                <Row>
                  <Col>
                    <Development/>
                  </Col>
                </Row>
              </Container>
            </Route>
            <Route path='*'>
              <Container>
                <Row>
                  <Col>
                    <Error404/>
                  </Col>
                </Row>
              </Container>
            </Route>
          </Switch>
        </Suspense>
      </CSSTransition>
    </SwitchTransition>
    <Suspense fallback={<Loading />}>
      <FooterGlobal />
    </Suspense>
    
    </>
  )
})

const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export default ViewManager;