import React from 'react';
import { Route, Redirect } from 'react-router';

import { useStore } from './store'

const PrivateRoute = ({ children, path, exact, ...rest }: {children: any, path: string, exact?: boolean, rest?: any}) => {
  const {globalStore} = useStore()
  // let auth = useAuth();
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={({ location }) =>
        globalStore.loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute