// import { ClipGroup } from '../components/clipReport/__tsTimelineExport'
import Times from './Times'
import ClipVisiblePart from './ClipVisiblePart'
// import * as Globals from '../components/timelineExport/Functions'
// import Operation from './Operation'

// interface VisiblePart extends Times {
//   name?: string
// }

export default class ClipTimes extends Times {

  visible: ClipVisiblePart[]
  sequenceId: string
  sequenceParentClip?: string

  constructor({timebase, start, end, inPoint, outPoint, visible, sequenceId}: {timebase: number, start: number, end: number, inPoint?: number, outPoint?: number, visible?: ClipVisiblePart[], sequenceId: string}) {
    super({timebase, start, end, inPoint, outPoint})

    this.sequenceId = sequenceId
    this.visible = visible ?? [ new ClipVisiblePart({timebase, start, end}) ]
  }

  cleanVisibleParts(){
    this.visible = this.visible.filter(vP => vP.duration > this.timebase/2)
    // if(this.visible.length === 0) {
    //   console.log('not visible')
    // }
  }

  resetVisibleParts(){
    this.visible = [ new ClipVisiblePart({timebase: this.timebase, start: this.start, end: this.end}) ]
  }

}