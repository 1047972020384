import React from "react"
import { Container, Jumbotron, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap';
import landingHeroImg from '../assets/images/landing_hero_en.jpg'

const Landing = () => {

  return (
    <div>
      <Container fluid>
        <Row>
        <img src={landingHeroImg} alt ='Screenshot working process with ClipReport' style={{width: '100%'}}/>
        </Row>
      </Container>
      <Jumbotron fluid>
        <Container>
          <h1 className="display-4">SaaS: PPro to CVS</h1>
          <ul className="lead">
            <li>Helps reporting all video and audio files in your videos</li>
            <li>No more parsing EDLs or manual creating Excel lists</li>
            <li>Best tool for the process of licensing and reporting media your videos</li>
          </ul>
          <p className="lead">
            <Button color="primary">Register now for free as Beta Tester</Button>
          </p>
        </Container>
      </Jumbotron>
      <Container>
        <Row style={{marginBottom: '50px'}}>
          <Col sm="12">
            <h2>What you get</h2>
            <p>Create with a easy to use UI directly in your browser shot lists of your PremierePro projects.</p>
            <ul>
              <li>Use automatic rules to speedup the process</li>
              <li>Create conditional rules on filename, filepath or clipname</li>
              <li>Create custom attributes / columns in your exported list</li>
              <li>Automatic calculation of screentime if a clip is in timeline but not visible</li>
            </ul>
          </Col>
        </Row>
        <Row style={{marginBottom: '50px'}}>
          <Col sm="12">
            <h2>What we offer</h2>
            <p>Sequence Clip Reporter is a Producer’s best friend! It takes the tedious work out of creating clip usage reports and music use reports as well as Filters and Markers directly to a fully formatted Excel spreadsheet, without the convoluted use of an EDL or copy/paste techniques.</p>
            <p>Why spend hours doing a boring task when it can take minutes?</p>
          </Col>
        </Row>
        <Row style={{marginBottom: '50px'}}>
          <Col sm="12">
            <h2>Mac or Windows?</h2>
            <p>Our <abbr title="Software as a Service">SaaS</abbr> runs in the Browser, so you don't need a specific operating system. All you need is: Premiere Pro and a modern browser (like Chrome or Firefox) on your machine.</p>
          </Col>
        </Row>
        <Row style={{marginBottom: '50px'}}>
          <Col sm="12">
            <h2>Actual offers</h2>
          </Col>
          <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }}>
            <Card>
              {/* <CardImg top width="100%" src="/assets/318x180.svg" alt="Card image cap" /> */}
              <CardBody className="text-center">
                <CardTitle tag="h5">Become Beta Tester</CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">Help us testing</CardSubtitle>
                <CardText>
                  We need people that can use and test our <abbr title="Software as a Service">SaaS</abbr> at their work to help us finding problems and improving the workflow even more.<br />
                  After the end of the beta phase we will give you 3 months free service if you we're already registered.
                </CardText>
                <Button color="primary">Start today for free</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Landing