import { GlobalStore } from './GlobalStore'
import { TimelineStore } from './TimelineStore'
import { AttributeRulesetStore } from './AttributeRulesetStore'
import { StoryboardStore } from './StoryboardStore'
import { RouterStore } from '@superwf/mobx-react-router';

export class RootStore {
  globalStore: GlobalStore
  timelineStore: TimelineStore
  attributeRulesetStore: AttributeRulesetStore
  storyboardStore: StoryboardStore
  routingStore: RouterStore

  constructor() {
    this.globalStore = new GlobalStore(this)
    this.timelineStore = new TimelineStore(this)
    this.attributeRulesetStore = new AttributeRulesetStore(this)
    this.storyboardStore = new StoryboardStore(this)
    this.routingStore = new RouterStore()
  }
}