import React from 'react'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import packageJson from '../../package.json'
import { useStore } from './store'
import ModalCheckAction from './ModalCheckAction';
import { useObserver } from 'mobx-react-lite';

const FooterGlobal = () => {
  const {globalStore} = useStore()
  const { t, i18n } = useTranslation();

  return useObserver(() => (
    <footer>
      <Container fluid={true} >
        <Row className="text-center">
          <Col sm={3}>
            <p>&copy; by drehbankmedia 2021</p>
          </Col>
          <Col sm={5} className="footer-links">
            <Link to="/about">{t('footer.about')}</Link>|
            <Link to="/privacy">{t('footer.privacy')}</Link>|
            <Link to="/imprint">{t('footer.legalNotice')}</Link>|
            {globalStore.loggedIn
              ? <ModalCheckAction
                modalTitle={t('user.wantToLogoutQuestion')}
                confirmButton={t('user.logoutConfirm')}
                confirmButtonColor="primary"
                modalBody={<>{t("user.loggedInAs", {username: globalStore.loggedIn.username})}</>}
                callback={() => {
                  globalStore.setLoggedIn()
                  localStorage.removeItem('user')
                }}
                style={{display: 'inline'}}>
                  <span className="link">{t('logout')}</span>
              </ModalCheckAction>
              : <Link to="/login">{t('login')}</Link>}
          </Col>
          <Col sm={2} className="footer-links">
            <Link to="/versioninfos">Version: {packageJson.version}</Link>
          </Col>
          <Col sm={2}>
            <div>
              {globalStore?.lngs && Object.keys(globalStore.lngs).map((lng) => (
                <button className={'lang-select'} key={lng} style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                  {globalStore.lngs[lng].nativeName}
                  {/* {
                    import(`./../assets/flags/${lng}.svg`).then(flag =>
                      // <img src={flag} alt="flag of ..." />
                      {console.log(flag)}
                    )
                  } */}
                </button>
              ))}
              <button className='lang-select' onClick={() => globalStore.setDarkmode()}>{globalStore.darkmode ? <FontAwesomeIcon icon="moon"/> : <FontAwesomeIcon icon="sun"/>}</button>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
    
  ))
}
export default FooterGlobal