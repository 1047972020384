import * as StoryboardTS from '../../ts/__storyboard'
import captureVideoFrame from 'capture-video-frame'
// import { Text } from '@react-pdf/renderer';
import React from 'react';
// import { JSXElement } from '@babel/types';
// import UserService from '../../auth/user-service'

export const getItemDuration = (item: StoryboardTS.Item) => {
  let value = 0
  switch (typeof item.duration) {
    case 'number':
      value = item.duration
      break;
    case 'string':
      if(item.duration === 'video' && item.video?.selection)
      value = item.video.selection.duration
      break;
  }
  return value
}

export const captureVideoFrameOf = ({videoRef, videoUrl, saveFrame}: {videoRef?: React.RefObject<HTMLVideoElement>, videoUrl?: string, saveFrame: (frame: {blob: Blob, dataUri: string, format: string})=>void}) => {
  console.log('capture Frame')
  if(typeof videoRef === 'undefined' && videoUrl) {
    const videoElement = document.createElement('video')
    videoElement.src = videoUrl
    console.log(videoElement)

    let initial = true
    videoElement.oncanplay = (ev) => {
      console.log('oncanplay')
      if(initial){
        // console.log(videoElement)
        videoElement.currentTime = 0.1
        setTimeout(()=>{
          const frame: {blob: Blob, dataUri: string, format: string} = captureVideoFrame(videoElement, 'jpeg', 0.67)
          saveFrame(frame)
          videoElement.remove()
        }, 100)
        initial = false
      }
    }
    videoElement.load()
    // videoElement.src = ''
  }

  if (videoRef?.current) {
    const frame: {blob: Blob, dataUri: string, format: string} = captureVideoFrame(videoRef.current, 'jpeg', 0.67)
    saveFrame(frame)
  }
}

// const findClosingTag = ({html, tag, stackCount=0}:{html: string, stackCount: number, tag: string}) => {
//   // const allOpeningTags = Array.from( html.matchAll(new RegExp('<(\\w+)>')) )
//   // const allClosingTags = Array.from( html.matchAll(new RegExp('</(\\w+)>')) )

//   // if(allOpeningTags === allClosingTags){
//   //   allOpeningTags.forEach(res =>{
//   //     res[1]
//   //   })
//   // }

//   const nextOpeningTag = html.match(new RegExp('<(\\w+)>'))
//   const nextClosingTag = html.match(new RegExp('</(\\w+)>'))
  
//   // no next opening tag 
//   if(nextOpeningTag !== null && nextClosingTag) return nextClosingTag.index!

//   // next closing tag before next opening tag
//   if(nextOpeningTag && nextClosingTag && nextOpeningTag.index! > nextClosingTag.index!) return nextClosingTag.index!

//   // next closing tag after next opening tag
//   if(nextOpeningTag && nextClosingTag && nextOpeningTag.index! < nextClosingTag.index!){
//     // dig deeper
//     const htmlAfterOpeningTag = html.substr(nextOpeningTag[0].length+nextOpeningTag.index!)
//     findClosingTag({html: htmlAfterOpeningTag, tag, stackCount: stackCount+1})
//   }
//   return {position: 0, remainingHtml: nextOpeningTag ? html.substr(nextOpeningTag[0].length+nextOpeningTag.index!) : html}
// }

// const convertHTMLtoReactPDF = (html: string, openedTag?: string): {jsx: JSX.Element, remainingHtml?: string} => {
//   // find opening tag
//   const openingTag = html.match(new RegExp('<(\\w+)>'))
//   if(openingTag){
//     const htmlBefore = html.substr(0, (openingTag.index?openingTag.index:0))
//     const htmlAfter = html.substr(openingTag[0].length)

//     // search for closing
//     const nextOpeningTag = htmlAfter.match(new RegExp('<(\\w+)>'))
//     const closingTag = htmlAfter.match(new RegExp('</('+openingTag[1]+')>'))
//     if(openedTag){
//       // we are inside another tag.. let's search if it get closed
//       const parentClosingTag = htmlAfter.match(new RegExp('</('+openedTag+')>'))

//       if (nextOpeningTag && parentClosingTag && nextOpeningTag[1] === openedTag && nextOpeningTag.index! > parentClosingTag.index!){
//         // parent gets closed
//         const enclosedContent = htmlAfter.substr(0, (parentClosingTag.index?parentClosingTag.index:0))
//         const htmlAfterContent = html.substr(parentClosingTag[0].length)
//       }
//     }
    
//     if(nextOpeningTag && closingTag && nextOpeningTag.index! < closingTag.index!){
//       // here is another opening tag first
//       let res = convertHTMLtoReactPDF(htmlAfter)
//       // if(res.remainingHtml){
//       //   res = convertHTMLtoReactPDF(res.remainingHtml)
//       // }
//       // return {jsx: <>{res.jsx}</>, remainingHtml: res.remainingHtml }
//       switch(openingTag[1]) {
//         case 'p':
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//         case 'strong':
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//         default:
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//       }
//     } else {
//       // let's close it
//       if(closingTag){
//         const enclosedContent = htmlAfter.substr(0, (closingTag.index?closingTag.index:0))
//         const htmlAfterContent = html.substr(closingTag[0].length)
//         switch(openingTag[1]) {
//           case 'p':
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//           case 'strong':
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//           default:
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//         }
//       }
//     }
//   }
//   return {jsx: <>{html}</>}
// }

export const dateToEdited = (date: Date) => {
  const today = new Date()
  switch(true){
    case today.getDay() === date.getDay() && today.getTime() - date.getTime() < 24*60*60*1000:
      // today.getDay()
      return 'Heute ' + date.toLocaleTimeString()
    case getSaveDay(today.getDay()-1) === date.getDay() && today.getTime() - date.getTime() < 24*60*60*1000*2:
      // yesterday
      return 'Gestern'
    case getSaveDay(today.getDay()-2) === date.getDay() && today.getTime() - date.getTime() < 24*60*60*1000*3:
      // the day before yesterday
      return 'Vorgestern'
    case today.getTime() - date.getTime() < 24*60*60*1000*7:
      // last 7 Days
      return weekDays[date.getDay()]
    default:
      // date
      return date.toLocaleDateString()
  }
}
const getSaveDay = (day: number) => {
  if(day < 0) return (day+7)%7
  return day % 7
}
// const returnWithLeadingZero = (tcInt: number) => {
//   return tcInt < 10 ? '0'+tcInt : tcInt.toString();
// }
const weekDays = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag']

// const convertHTMLtoReactPDF = (html: string, openedTag?: string): {jsx: JSX.Element, remainingHtml?: string} => {
//   // find opening tag
//   const openingTag = html.match(new RegExp('<(\\w+)>'))
//   if(openingTag){
//     const htmlBefore = html.substr(0, (openingTag.index?openingTag.index:0))
//     const htmlAfter = html.substr(openingTag[0].length)

//     // search for closing
//     const nextOpeningTag = htmlAfter.match(new RegExp('<(\\w+)>'))
//     const closingTag = htmlAfter.match(new RegExp('<(/'+openingTag[1]+')>'))
//     if(openedTag){
//       // we are inside another tag.. let's search if it get closed
//       const parentClosingTag = htmlAfter.match(new RegExp('<(/'+openedTag+')>'))

//       if (nextOpeningTag && parentClosingTag && nextOpeningTag[1] === openedTag && nextOpeningTag.index! > parentClosingTag.index!){
//         // parent gets closed
//         const enclosedContent = htmlAfter.substr(0, (parentClosingTag.index?parentClosingTag.index:0))
//         const htmlAfterContent = html.substr(parentClosingTag[0].length)
//       }
//     }
    
//     if(nextOpeningTag && closingTag && nextOpeningTag.index! < closingTag.index!){
//       // here is another opening tag first
//       let res = convertHTMLtoReactPDF(htmlAfter)
//       // if(res.remainingHtml){
//       //   res = convertHTMLtoReactPDF(res.remainingHtml)
//       // }
//       // return {jsx: <>{res.jsx}</>, remainingHtml: res.remainingHtml }
//       switch(openingTag[1]) {
//         case 'p':
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//         case 'strong':
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//         default:
//           return {jsx: <>{htmlBefore}<Text>{res.jsx}</Text>{res.remainingHtml ? convertHTMLtoReactPDF(res.remainingHtml).jsx : ''}</>}
//       }
//     } else {
//       // let's close it
//       if(closingTag){
//         const enclosedContent = htmlAfter.substr(0, (closingTag.index?closingTag.index:0))
//         const htmlAfterContent = html.substr(closingTag[0].length)
//         switch(openingTag[1]) {
//           case 'p':
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//           case 'strong':
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//           default:
//             return {jsx: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).jsx}</>}
//         }
//       }
//     }
//   }
//   return {jsx: <>{html}</>}
// }

// const convertHTMLtoReactPDF = (html: string, content?: JSX.Element): {content?: JSX.Element, html?: string} => {
//   // find opening tag
//   const openingTag = html.match(new RegExp('<(\\w+)>'))
//   if(openingTag){
//     const htmlBefore = html.substr(0, (openingTag.index?openingTag.index:0))
//     const htmlAfter = html.substr(openingTag[0].length)

//     // search for closing
//     const nextOpeningTag = htmlAfter.match(new RegExp('<(\\w+)>'))
//     const closingTag = htmlAfter.match(new RegExp('<(/'+openingTag[1]+')>'))
//     if(nextOpeningTag && closingTag && nextOpeningTag.index! < closingTag.index!){
//       // here is another opening tag first
//       convertHTMLtoReactPDF(htmlAfter)
//     } else {
//       // let's close it
//       if(closingTag){
//         const enclosedContent = htmlAfter.substr(0, (closingTag.index?closingTag.index:0))
//         const htmlAfterContent = html.substr(closingTag[0].length)
//         switch(openingTag[1]) {
//           case 'p':
//             const red = {content: <>{htmlBefore}<Text>{enclosedContent}</Text>{convertHTMLtoReactPDF(htmlAfterContent).content!}</>, html: htmlAfterContent}
//         }
//         const res = {content: <>{htmlBefore}{enclosedContent}</>, html: htmlAfterContent}
//       }
      
//     }
//   }
  


//   const firstTag = html.match(new RegExp('<(\\w+)>'))
//   if(firstTag){
//     // if there is an opening tag go inside and dig deep
//     const openingTagIndex = html.indexOf('<'+firstTag[1]+'>')
//     const closingTagIndex = html.indexOf('</'+firstTag[1]+'>')
//     const contentInTag = html.match(new RegExp('<'+firstTag[1]+'>(.*?)</'+firstTag[1]+'>'))
//     const remainingHtml = html.replace(new RegExp('<'+firstTag[1]+'>(.*?)</'+firstTag[1]+'>'), '')

//     const htmlBefore = html.substr(0,openingTagIndex)
//     const htmlAfter = html.substr(closingTagIndex+3+firstTag[1].length)

//     if(contentInTag && contentInTag[0] !== ''){
//       switch(firstTag[0]){
//         case 'p':
//           const child = convertHTMLtoReactPDF(contentInTag[0])
//           // convertHTMLtoReactPDF(remainingHtml, <Text>{contentInTag[0]}</Text>)
//           return {content: <>{htmlBefore}<Text>{child.content}</Text>{htmlAfter}</>, html: remainingHtml}
//           // return {content: child.content ? <Text>{child.content}</Text> : <Text>{contentInTag[0]}</Text>, html: remainingHtml}
//         default:
//           break
//       }
//     } else {
//       return {content: undefined, html: remainingHtml}
//     }
//   } else {
//     return {content: <>{html}</>}
//   }
//   return {content: undefined, html}
// }

// convertHTMLtoReactPDF('<p>Test <strong>3</strong> bitte</p><p>2</p>')