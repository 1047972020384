// import ClipTimes from "./ClipTimes"

export default class Times {

  timebase: number
  start: number
  end: number
  inPoint?: number
  outPoint?: number

  constructor({timebase, start, end, inPoint, outPoint}: {timebase: number, start: number, end: number, inPoint?: number, outPoint?: number}) {
    this.timebase = timebase
    this.start = start
    this.end = end
    this.inPoint = inPoint
    this.outPoint = outPoint
  }
  
  get duration() {
    return this.end - this.start
  }

  setTime({start, end}: {start?: number, end?: number}){
    this.start = typeof start !== 'undefined' ? start : this.start
    this.end = typeof end !== 'undefined' ? end : this.end
  }

  returnWithOffset(offset: number) {
    return new Times ({
      start: this.start + offset,
      end: this.end + offset,
      timebase: this.timebase,
      inPoint: this.inPoint,
      outPoint: this.outPoint
    })
  }

  returnInsideRange({rangeStart, rangeOut}: {rangeStart: number, rangeOut: number}) {
    const outOfRange = this.end <= rangeStart || rangeOut <= this.start
    // console.log({start: this.start, end: this.end, rangeStart, rangeOut, outOfRange})
    return new Times ({
      start: outOfRange ? 0 : (this.start < rangeStart ? rangeStart : this.start),
      end: outOfRange ? 0 : (rangeOut < this.end ? rangeOut : this.end),
      timebase: this.timebase,
      inPoint: this.inPoint,
      outPoint: this.outPoint
    })
  }

  returnSubClipTimes(parentClipTimes: Times) {
    // check range
    const inPoint = parentClipTimes.inPoint
    const outPoint = parentClipTimes.outPoint
    const outOfRange = inPoint && outPoint && (this.end <= inPoint || outPoint <= this.start)

    const offset = parentClipTimes.subClipsOffset
    const startOffsetted = this.start + offset
    const endOffsetted = this.end + offset
    return new Times ({
      // start: outOfRange ? 0 : (this.start < inPoint ? inPoint : this.start + offset),
      // end: outOfRange ? 0 : (outPoint < this.end ? outPoint : this.end + offset),
      start: outOfRange ? 0 : (startOffsetted < parentClipTimes.start ? parentClipTimes.start : startOffsetted),
      end: outOfRange ? 0 : (parentClipTimes.end < endOffsetted ? parentClipTimes.end : endOffsetted),
      timebase: this.timebase,
      inPoint: this.inPoint,
      outPoint: this.outPoint
    })
  }

  get subClipsOffset () {
    return typeof this.inPoint !== 'undefined' ? this.start - this.inPoint : 0
  }

  startFormatted(format: string) {
    return this.getFormatted(format, this.start)
  }

  endFormatted(format: string) {
    return this.getFormatted(format, this.end)
  }

  durationFormatted(format: string) {
    return this.getFormatted(format, this.duration)
  }

  inPointFormatted(format: string) {
    return this.inPoint? this.getFormatted(format, this.inPoint) : undefined
  }

  outPointFormatted(format: string) {
    return this.outPoint? this.getFormatted(format, this.outPoint) : undefined
  }

  getFormatted(format: string, frames: number) {
    const formatParts = format.split(':')
    return formatParts.map(formatPart => {
      let fPstr = ''
      switch (formatPart) {
        case 'h':
          // get hours without leading zero
          fPstr = this.getHours(frames,this.timebase).toString()
          break;
        case 'hh':
          // get hours with leading zero
          fPstr = this.returnWithLeadingZero( this.getHours(frames,this.timebase) ).toString()
          break;
        case 'H':
          // get all hours
          let secondsTotalH = Math.floor( frames / this.timebase )
          let secondsH = secondsTotalH % 60
          let minutesH = (secondsTotalH - secondsH) % 3600
          fPstr = ((secondsTotalH - secondsH - minutesH) / 3600 ).toString()
          break;
        case 'm':
          // get minutes without leading zero
          // fPstr = this.getMinutes(frames,this.timebase).toString()
          fPstr = formatParts.find(fP=>fP==='rss') ? this.getMinutesOfSecondsRounded(frames!,this.timebase).toString() : this.getMinutes(frames!,this.timebase).toString()
          break;
        case 'mm':
          // get minutes with leading zero
          // fPstr = this.returnWithLeadingZero( this.getMinutes(frames,this.timebase) ).toString()
          fPstr = this.returnWithLeadingZero( formatParts.find(fP=>fP==='rss') ? this.getMinutesOfSecondsRounded(frames!,this.timebase) : this.getMinutes(frames!,this.timebase) ).toString()
          break;
        case 'M':
          // get all minutes
          let secondsTotalM = Math.floor( frames / this.timebase )
          let secondsM = secondsTotalM % 60
          fPstr = ((secondsTotalM - secondsM) / 60).toString()
          break;
        case 's':
          // get seconds without leading zero
          fPstr = this.getSeconds(frames,this.timebase).toString()
          break;
        case 'rs':
          // get seconds without leading zero
          fPstr = this.getSecondsRounded(frames,this.timebase).toString()
          break;
        case 'ss':
          // get seconds with leading zero
          fPstr = this.returnWithLeadingZero( this.getSeconds(frames,this.timebase) ).toString()
          break;
        case 'rss':
          // get seconds with leading zero
          fPstr = this.returnWithLeadingZero( this.getSecondsRounded(frames,this.timebase) ).toString()
          break;
        case 'S':
          // get all seconds
          fPstr = Math.floor( frames / this.timebase ).toString()
          break;
        case 'rS':
          // get all seconds
          fPstr = Math.round( frames / this.timebase ).toString()
          break;

        case 'f':
          // get fraction frames without leading zero
          fPstr = this.getFractionFrames(frames,this.timebase).toString()
          break;
        case 'ff':
          // get fraction frames with leading zero
          fPstr = this.returnWithLeadingZero( this.getFractionFrames(frames,this.timebase) ).toString()
          break;
        case 'F':
          // get all frames
          fPstr = frames.toString()
          break;
        default:
          fPstr = frames.toString()
          break;
      }
      return fPstr
    }).join(':')
  }

  getFractionFrames = (frames: number, timebase: number) => {
    return frames % timebase
  }

  getSecondsRounded = (frames: number, timebase: number) => {
    const secondsTotal = Math.round( frames / timebase )
    return secondsTotal % 60
  }

  getSeconds = (frames: number, timebase: number) => {
    const secondsTotal = Math.floor( frames / timebase )
    return secondsTotal % 60
  }

  getMinutesOfSecondsRounded = (frames: number, timebase: number) => {
    const secondsTotal = Math.round( frames / timebase )
    const seconds = secondsTotal % 60
    return ((secondsTotal - seconds) % 3600 ) / 60
  }

  getMinutes = (frames: number, timebase: number) => {
    const secondsTotal = Math.floor( frames / timebase )
    const seconds = secondsTotal % 60
    return ((secondsTotal - seconds) % 3600 ) / 60
  }

  getHours = (frames: number, timebase: number) => {
    const secondsTotal = Math.floor( frames / timebase )
    const seconds = secondsTotal % 60
    const minutes = (secondsTotal - seconds) % 3600
    return ((secondsTotal - seconds - minutes) % 86400 ) / 3600
  }
  
  returnWithLeadingZero = (tcInt: number) => {
    return tcInt < 10 ? '0'+tcInt : tcInt.toString()
  }

  get startSecondsAll() {
    return this.start / this.timebase
  }

  get endSecondsAll() {
    return this.end / this.timebase
  }
}