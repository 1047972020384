import Times from './Times'

export default class VisiblePart extends Times {

  name?: string

  constructor({timebase, start, end, inPoint, outPoint, name}: {timebase: number, start: number, end: number, inPoint?: number, outPoint?: number, name?: string}) {
    super({timebase, start, end, inPoint, outPoint})

    this.name = name
  }

}