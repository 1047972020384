import React, { useRef } from 'react'
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
} from 'reactstrap'
import { useObserver } from "mobx-react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

import * as Globals from './functions/Functions'
import { useStore } from '../store'
import ModalRules from './rules/ModalRules'
import ModalSettingsTimeFormat from './ModalSettingsTimeFormat';


const NavItemsClipReport = () => {
  const {timelineStore,attributeRulesetStore,routingStore} = useStore()
  const refModalRules = useRef<any>(null)
  const { t } = useTranslation('clipReport');

  const getHeaders = () => {
    const attributeListOrder = attributeRulesetStore.activeAttributeRuleset ? attributeRulesetStore.activeAttributeRuleset.attributeListOrder : []
    if(attributeListOrder.length <= 0) return {}
    return attributeListOrder.map(attr => attributeRulesetStore.getAttributeByKey(attr))
      .filter(attr => attr.hideInExport === undefined || attr.hideInExport === false)
      .map(attr => ({[attr.key]: attr.i18nKey ? t(`${attr.i18nKey}`) : ( attr.displayName ? attr.displayName : attr.key ) }))
      .reduce((acc, val) => Object.assign(acc, val), {})
  } 

  return useObserver(() => (
    <div className='nav-timeline-export'>
      <NavItem className='nav-title'>
        <NavLink disabled href="#">{t("timelineExport")}</NavLink>
      </NavItem>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {t("navbar.settings")}
        </DropdownToggle>
        <DropdownMenu right>
          {/* <ModalRules /> */}
          <DropdownItem onClick={()=>{
            if(refModalRules.current)
              refModalRules.current.toggleChildModal()
          }}>
            {t("navbar.rulesets")}
          </DropdownItem>
          <ModalSettingsTimeFormat />
        </DropdownMenu>
      </UncontrolledDropdown>
        {(timelineStore.sequences && timelineStore.sequences.length !== 0) && (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {t("navbar.file")}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" active={routingStore.location.pathname === '/timeline-export'}>
              <div onClick={() => {
                routingStore.push('/timeline-export')
              }}>
                {t("navbar.fileOpen")}
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>{t("navbar.export")}</DropdownItem>
            <DropdownItem tag="a">
              <div onClick={() => {
                const headers = getHeaders()
                if(Object.keys(headers).length > 0) {
                  Globals.exportCSVFile({headers, list: timelineStore.listForCSVexportVideo, fileTitle: timelineStore.activeSequence!.name + '_video'})
                  Globals.exportCSVFile({headers, list: timelineStore.listForCSVexportAudio, fileTitle: timelineStore.activeSequence!.name + '_audio'})
                }
              }}>
                {t("navbar.downloadLists")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a">
              <div onClick={() => {
                const headers = getHeaders()
                if(Object.keys(headers).length > 0) {
                  Globals.exportCSVFile({headers, list: timelineStore.listForCSVexportVideo, fileTitle: timelineStore.activeSequence!.name + '_video'})
                }
              }}>
                {t("navbar.downloadListVideo")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a">
              <div onClick={() => {
                const headers = getHeaders()
                if(Object.keys(headers).length > 0) {
                  Globals.exportCSVFile({headers, list: timelineStore.listForCSVexportAudio, fileTitle: timelineStore.activeSequence!.name + '_audio'})
                }
              }}>
                {t("navbar.downloadListAudio")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a">
              <div onClick={() => Globals.exportJSONFile(timelineStore.parsedProject, timelineStore.fileName)}>
                {t("navbar.downloadJson")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {(timelineStore.sequences && timelineStore.sequences.length !== 0) && (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
          {t("navbar.edit")}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a">
              <div onClick={() => timelineStore.setForAll({key: 'transparence', value: true})}>
              {t("navbar.allTransparent")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a" disabled={!attributeRulesetStore.hasActiveRuleset}>
              <div onClick={() => {
                // console.log(attributeRulesetStore.activeAttributeRuleset?.name); console.log(JSON.parse(JSON.stringify(attributeRulesetStore.activeAttributeRuleset))); 
                timelineStore.applyRulesOnClipItems()}}>
                {t("navbar.applyAutoRules")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a" disabled={timelineStore.operationStack.length <= 0}>
              <div onClick={() => timelineStore.undoLastOperationCollection()}>
                {(timelineStore.operationStack.length > 0 && timelineStore.operationStack[timelineStore.operationStack.length-1].name) && <em>"{timelineStore.operationStack[timelineStore.operationStack.length-1].name}"</em>} {t("navbar.undo")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {(timelineStore.sequences && timelineStore.sequences.length !== 0) && (
        <NavItem>
          <NavLink onClick={() => { timelineStore.undoLastOperationCollection() }} disabled={timelineStore.operationStack.length <= 0}><FontAwesomeIcon icon="undo-alt"/></NavLink>
        </NavItem>
      )}
      {/* {(timelineStore.sequences && timelineStore.sequences.length !== 0) && (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
          {t("navbar.view")}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" active={routingStore.location.pathname === '/timeline-export/timeline'}>
              <div onClick={() => {
                // timelineStore.setActiveView('TimeLine')
                routingStore.push('/timeline-export/timeline')
              }}>
                {t("navbar.timeline")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a" active={routingStore.location.pathname === '/timeline-export/timelist'}>
              <div onClick={() => {
                // timelineStore.setActiveView('TimeList')
                routingStore.push('/timeline-export/timelist')
              }}>
                {t("navbar.timelist")}
              </div>
            </DropdownItem>
            <DropdownItem tag="a" active={routingStore.location.pathname === '/timeline-export/filelist'}>
              <div onClick={() => {
                // timelineStore.setActiveView('FileList')
                routingStore.push('/timeline-export/filelist')
              }}>
                {t("navbar.filelist")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )} */}
      {/* {(timelineStore.sequences && timelineStore.sequences.length !== 0) && (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {t("navbar.selectSequence")}
          </DropdownToggle>
          <DropdownMenu right>
            {timelineStore.sequences
              // .sort( (a: GlobalTS.Sequence ,b: GlobalTS.Sequence) => a.id > b.id )
              .map((s:any, i:any) => (
              <DropdownItem tag="a" active={s.id === timelineStore.activeSequenceId} key={'drpdwnSeq-'+i}>
                <div onClick={() => timelineStore.setActiveSequenceId(s.id)}>
                  {s.name}
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )} */}
      <ModalRules ref={refModalRules}/>
    </div>
  ))
}
export default NavItemsClipReport