import React, { useState } from 'react';
import { Col, FormGroup, Label, FormText, Button, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, Input, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useStore } from '../store'

const ModalSettingsTimeFormat = () => {
  const {timelineStore} = useStore()
  const [modal, setModal] = useState(false);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [timeFormat, setTimeformat] = useState(()=>timelineStore.timeFormat)

  const { t } = useTranslation('clipReport');

  const toggle = () => setModal(!modal);
  const detailsToggle = () => setDetailsIsOpen(!detailsIsOpen);

  return (
    <div>
      <DropdownItem onClick={toggle}>
        {t("navbar.timeformat")}
      </DropdownItem>
      {/* <Button color="danger" onClick={toggle}>Zeitformat</Button> */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Zeitformat einstellen</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={3} for="timeformat">Zeitformat</Label>
            <Col sm={9}>
              <Input id="timeformat" onChange={evt => setTimeformat(evt.target.value)} value={timeFormat} />
            </Col>
            <Col>
              <FormText color="muted">
                Erlaubte Werte sind: H, h, hh, M, m, mm, S, s, ss, rS, rs, rss, f, ff, F
              </FormText>
            </Col>
          </FormGroup>
          <Button color="muted" size="sm" onClick={detailsToggle} style={{ marginBottom: '1rem' }}>Mehr Details</Button>
          <Collapse isOpen={detailsIsOpen}>
            <ul>
              <li><strong>H:</strong> Stunden</li>
              <li><strong>h:</strong> Stunden bis 24</li>
              <li><strong>hh:</strong> Stunden bis 24; zweistellig mit vorgestellter Null</li>
              <li><strong>M:</strong> Minuten</li>
              <li><strong>m:</strong> Minuten bis 60</li>
              <li><strong>mm:</strong> Minuten bis 60; zweistellig mit vorgestellter Null</li>
              <li><strong>S:</strong> Sekunden</li>
              <li><strong>s:</strong> Sekunden bis 60</li>
              <li><strong>ss:</strong> Sekunden bis 60; zweistellig mit vorgestellter Null</li>
              <li><strong>rS:</strong> Sekunden gerundet</li>
              <li><strong>rs:</strong> Sekunden gerundet bis 60</li>
              <li><strong>rss:</strong> Sekunden gerundet bis 60; zweistellig mit vorgestellter Null</li>
              <li><strong>f:</strong> Frames zwischen Sekunden</li>
              <li><strong>ff:</strong> Frames zwischen Sekunden; zweistellig mit vorgestellter Null</li>
              <li><strong>F:</strong> nur Frames</li>
            </ul>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Abbrechen</Button>{' '}
          <Button color="primary" onClick={()=>{
            timelineStore.setTimeFormat(timeFormat)
            toggle()
          }}>Speichern</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalSettingsTimeFormat;