import { Koordinates } from '../components/clipReport/__tsTimelineExport'

export default class Rectangle {
  width: number
  height: number
  centerX: number
  centerY: number

  pointA: [number, number]
  pointB: [number, number]
  pointC: [number, number]
  pointD: [number, number]

  constructor(width: number, height: number){
    this.width = width
    this.height = height
    this.centerX = 0
    this.centerY = 0
    this.pointA = [-this.width/2,-this.height/2]
    this.pointB = [-this.width/2, this.height/2]
    this.pointC = [ this.width/2, this.height/2]
    this.pointD = [ this.width/2,-this.height/2]
  }

  coords(): [number,number][] {
    return [ this.pointA, this.pointB, this.pointC, this.pointD, this.pointA ]
  }

  scale(percentage: number) {
    this.pointA = this.scaleSinglePoint(this.pointA, [this.centerX,this.centerY], percentage)
    this.pointB = this.scaleSinglePoint(this.pointB, [this.centerX,this.centerY], percentage)
    this.pointC = this.scaleSinglePoint(this.pointC, [this.centerX,this.centerY], percentage)
    this.pointD = this.scaleSinglePoint(this.pointD, [this.centerX,this.centerY], percentage)
  }

  scaleSinglePoint(point: [number,number], origin: [number,number], percentage: number): [number, number] {
    const newX = - (origin[0]-point[0])*percentage + origin[0]
    const newY = - (origin[1]-point[1])*percentage + origin[1]
    return [Math.round(newX), Math.round(newY)]
  }

  rotate(rotation: number) {
    this.pointA = this.rotateSinglePoint(this.pointA, [this.centerX,this.centerY], rotation)
    this.pointB = this.rotateSinglePoint(this.pointB, [this.centerX,this.centerY], rotation)
    this.pointC = this.rotateSinglePoint(this.pointC, [this.centerX,this.centerY], rotation)
    this.pointD = this.rotateSinglePoint(this.pointD, [this.centerX,this.centerY], rotation)
  }

  rotateSinglePoint(point: [number,number], origin: [number,number], rotation: number): [number, number] {
    // const distance = Math.sqrt( Math.pow( (point[0] - origin[0]) , 2 ) + Math.pow( (point[1] - origin[1]) , 2 ) )
    const angle = rotation * Math.PI / 180
    const newX = -1 * Math.cos(angle) * (origin[0]-point[0]) - Math.sin(angle) * (origin[1]-point[1]) + origin[0]
    const newY = Math.sin(angle) * (origin[0]-point[0]) - Math.cos(angle) * (origin[1]-point[1]) + origin[1]
    return [Math.round(newX), Math.round(newY)]
  }

  translate(translate: Koordinates) {
    this.pointA = this.translateSinglePoint(this.pointA, translate)
    this.pointB = this.translateSinglePoint(this.pointB, translate)
    this.pointC = this.translateSinglePoint(this.pointC, translate)
    this.pointD = this.translateSinglePoint(this.pointD, translate)
  }

  translateSinglePoint(point: [number,number], translate: Koordinates): [number, number] {
    const newX = point[0] + translate.horiz * this.width
    const newY = point[1] + translate.vert * this.height
    return [Math.round(newX), Math.round(newY)]
  }
}