import * as ReportTS from '../components/clipReport/__tsTimelineExport'

export interface OperationParams {
  op: ReportTS.OperationMode
  id: string
  type: 'clip' | 'file' | 'group' | 'sequence'
  key: keyof ReportTS.Source | string
  value: any
  before?: any
}

export default class Operation {

  op: ReportTS.ModMode | 'add' | 'delete'
  id: string
  type: 'clip' | 'file' | 'group' | 'sequence'
  key: string
  value: any
  before?: any

  constructor(operation: OperationParams) {
    this.op = operation.op
    this.id = operation.id
    this.type = operation.type
    this.key = operation.key
    this.value = operation.value
    this.before = operation.before
  }

  reverse(){
    if(this.op === 'toggle') {
      return {}
    }
  }

}