import React, { Component } from "react"
import { Form, FormGroup, Col, Button, Input, InputGroup, InputGroupAddon, InputGroupButtonDropdown, Label, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AllOfSame } from './__tsTimelineExport'
import TooltipItem from "./TooltipItem"

interface TextInputProps  {
  value: string
  callback: ({newVal, ids, same}:{newVal: any, ids?: string[], same?: AllOfSame}) => void
  label?: string
  id?: string
  labelSize?: number
  options?: {
    ids?: string[]
    allOfSame?: AllOfSame
    allOfSameEnabled?: boolean
    defaultSubmit?: 'clip' | 'file'
    justFormGroup?: boolean
  }
}

interface TextInputState extends TextInputProps {
  initValue: string
  splitButtonOpen: boolean
  splitButtonSelect: AllOfSame
  inputSize: number
}

class TextInput extends Component<TextInputProps, TextInputState> {

  constructor (props: TextInputProps) {
    super(props)
    this.state = {
      value: props.value, 
      initValue: props.value,
      callback: props.callback,
      label: props.label,
      labelSize: props.labelSize ? props.labelSize : 2,
      inputSize: props.label ? (props.labelSize ? (12 - props.labelSize) : 10) : 12,
      id: props.id,
      options: props.options,
      splitButtonOpen: false,
      splitButtonSelect: 'file'
    }
  }

  static getDerivedStateFromProps(props: TextInputProps, state: TextInputState){
    if( props.callback !== state.callback ) {
      return {...state, value: props.value, initValue: props.value, callback: props.callback, options: props.options}
    }
    return null
  }

  toggleSplitButton = () => this.setState({...this.state, splitButtonOpen: !this.state.splitButtonOpen});

  setSplitButton = (value: AllOfSame) => {
    this.setState({...this.state, splitButtonSelect: value}, this.toggleSplitButton)
  };

  submitName = (e: any) => {
    e.preventDefault()
    // console.log(e.target.value)
    this.setState({...this.state, value: e.target.value})
  }

  submitChanges = (options?: {ids?: string[], same?: AllOfSame}) => {
    this.state.callback({...options, newVal: this.state.value})
  }

  discardChanges = () => {
    this.setState({...this.state, value: this.state.initValue})
  }

  render() {
    const allOfSameEnabled = (this.state.value !== this.state.initValue && this.state.options && this.state.options.allOfSameEnabled) ? true : false
    const formGroup =
        <FormGroup row>
          {this.state.label && (<Label for={this.state.id ? this.state.id : this.state.label} sm={this.state.labelSize} size='sm'>{this.state.label}</Label>)}
          <Col sm={this.state.inputSize}>
            <InputGroup>
              <Input bsSize='sm' value={this.state.value} onChange={(evt: any) => this.submitName(evt)} 
                onKeyDown={(evt: any) => {
                  if(this.state.value !== this.state.initValue && evt.key === 'Enter') {
                    this.submitChanges({same: this.state.options && this.state.options.defaultSubmit && this.state.options.defaultSubmit === 'file' ? 'file' : undefined})
                    evt.target.blur()
                  }
                  if(this.state.value !== this.state.initValue && evt.keyCode === 27) {
                    this.discardChanges()
                    evt.target.blur()
                  }
                }}
                id={this.state.id ? this.state.id : this.state.label} />
              {this.state.value !== this.state.initValue && (
                <InputGroupAddon addonType="append" size='sm'>
                  <TooltipItem text='Änderung verwerfen' tooltipId={this.state.id ? 'btn-dismiss-'+this.state.id : 'btn-dismiss'}>
                    <Button size='sm' onClick={this.discardChanges} color="danger"><FontAwesomeIcon icon="times" /></Button>
                  </TooltipItem>
                </InputGroupAddon>)}
              {allOfSameEnabled && 
                  <InputGroupButtonDropdown size='sm' addonType="append" isOpen={this.state.splitButtonOpen} toggle={this.toggleSplitButton}>
                    <TooltipItem text='Bearbeite mehrere Clips gleichzeitig' tooltipId={this.state.id ? 'btn-dd-'+this.state.id : 'btn-dd'}>
                      <Button onClick={() => this.submitChanges({same: this.state.splitButtonSelect})} color="primary"><FontAwesomeIcon icon="check-double" /></Button>
                    </TooltipItem>
                    <DropdownToggle split color='primary'/>
                    <DropdownMenu>
                      <DropdownItem active={this.state.splitButtonSelect === 'file'} toggle={false} onClick={()=>this.setSplitButton('file')}>Gleiche Dateien</DropdownItem>
                      <DropdownItem active={this.state.splitButtonSelect === 'track'} toggle={false} onClick={()=>this.setSplitButton('track')}>Alle auf der Spur</DropdownItem>
                      <DropdownItem active={this.state.splitButtonSelect === 'dir'} toggle={false} onClick={()=>this.setSplitButton('dir')}>Dateien im selben Ordner</DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                }
              {this.state.value !== this.state.initValue && (
                <InputGroupAddon addonType="append" size='sm'>
                  <TooltipItem text='Änderung speichern' tooltipId={this.state.id ? 'btn-save-'+this.state.id : 'btn-save'}>
                    <Button size='sm' onClick={()=>this.submitChanges()} color="success"><FontAwesomeIcon icon="check" /></Button>
                  </TooltipItem>
                </InputGroupAddon>)}
            </InputGroup>
          </Col>
        </FormGroup>

      return this.state.options?.justFormGroup ? formGroup : <Form className='text-input'>{formGroup}</Form>
  }
}

export default TextInput