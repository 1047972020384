import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { observer } from "mobx-react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { v4 as uuidv4 } from 'uuid'
import randomstring from 'randomstring'
import { useTranslation } from 'react-i18next'

import { AttributeAvailable, AttributeRule } from '../__tsTimelineExport'
import TextInput from '../TextInput'
import { useStore } from '../../store'
// import TextEditable from '../../TextEditable';
import ModalRulesRule from './ModalRulesRule';
import ModalCheckAction from '../../ModalCheckAction';
import ModalRulesAttribute from './ModalRulesAttribute';


interface ModalEditProps  {
  // selectedIds: string[]
}

const ModalRules = observer( forwardRef((props: ModalEditProps, ref) => {
  const [modal, setModal] = useState(false)
  const [selected, setSelected]:[number|undefined, any] = useState(undefined)

  const {attributeRulesetStore} = useStore()
  const { t } = useTranslation('clipReport')
  // const [ownerStringList, setOwnerStringList] = useState(attributeRulesetStore.findOwnerStringList)

  // const text = useRef('');

  // const handleBlur = () => {
  //     console.log(text.current);
  // };

  const toggle = (save?: boolean, rulesetId?: string) => {
    if(save && rulesetId){
      attributeRulesetStore.editRulesEnd(save)
      attributeRulesetStore.saveAttributeRulesetToServer(rulesetId)
    } else {
      attributeRulesetStore.requestServerRules()
      attributeRulesetStore.editRulesStart()
    }
    if(save === false) attributeRulesetStore.editRulesEnd(save)
    setModal(!modal)
  }

  const emptyAttributeRule: AttributeRule = {
    id: randomstring.generate(5),
    enabled: true,
    name: '',
    search: [{
      name: '',
      type: 'folderAndFile',
      regex: {
        pattern: '',
        flags: ''
      },
      required: true
    }],
    result: [{
      key: '',
      value: ''
    }],
    changed: true
  }

  const emptyAttribute: AttributeAvailable = {
    key: 'attribute_'+randomstring.generate(2),
    type: 'clip',
    valueSource: 'clipItem',
    displayName: 'neues Attribut'
  }

  const addNewRule = (rulesetId: string, ruleId?: number) => {
    // attributeRulesetStore.setSortingRule({rulesetId, ruleId, rule: emptyRule})
    attributeRulesetStore.setAttributeRulesetRule(rulesetId, emptyAttributeRule, undefined, true)
  }

  const addNewAttribute = (rulesetId: string) => {
    attributeRulesetStore.setAttributeRulesetAttribute(rulesetId, emptyAttribute, undefined, true)
  }

  if(modal && selected === undefined && attributeRulesetStore.attributeRulesetsEdit.length > 0) {
    const ruleset = attributeRulesetStore.activeAttributeRuleset
    const defaultRulesetId = attributeRulesetStore.attributeRulesetsEdit[0].id
    setSelected( ruleset ? ruleset.id : defaultRulesetId )
  }

  const ruleset = attributeRulesetStore.attributeRulesetsEdit.find(rs=>rs.id === selected)
  // if(attributeRulesetStore.attributeRulesets.length === 0) return <></>

  useImperativeHandle(ref, () => ({
    toggleChildModal(){
      toggle()
    }
  }));
  
  return (
    <div>
      {/* <DropdownItem onClick={()=>toggle()}>
        Regelwerke
      </DropdownItem> */}
      <Modal isOpen={modal} toggle={()=>toggle()} className={'modalRules'} size="lg" keyboard={false}>
        <ModalHeader toggle={()=>toggle()}>
          {t('ruleset.editRuleset')}
        </ModalHeader>
        <ModalBody>
          <div className="ruleset-select">
            <h2>{t('ruleset.selectRuleset')}</h2>
            <ListGroup>
              <TransitionGroup>
                {attributeRulesetStore.attributeRulesetsEdit.map((ruleset, index) => {
                  return(
                    <CSSTransition key={'ruleset-item-'+index} timeout={800} classNames='ruleset-item'>
                      <ListGroupItem active={selected !== undefined && ruleset.id === selected} tag="a" action onClick={()=>setSelected(ruleset.id)} key={'ruleset-'+index}>
                        {ruleset.name}
                        {ruleset.active
                          ? <Button color="success" size="sm">{t('ruleset.selected')}{'  '}<FontAwesomeIcon icon='clipboard-check'/></Button>
                          : <Button color="secondary" size="sm" onClick={()=>attributeRulesetStore.setAttributeRulesetActive(ruleset.id)}>{t('ruleset.select')}{'  '}<FontAwesomeIcon icon='clipboard-list'/></Button>
                        }
                        <ModalCheckAction
                          modalTitle={t('ruleset.deleteConfirm')}
                          callback={() => attributeRulesetStore.setAttributeRuleset({id: ruleset.id, remove: true, editSafeMode: true})}
                          style={{display: 'inline'}}>
                            <Button color="danger" size="sm">{t('ruleset.delete')}{'  '}<FontAwesomeIcon icon='trash'/></Button>
                        </ModalCheckAction>
                      </ListGroupItem>
                    </CSSTransition>)
                })}
              </TransitionGroup>
              <ListGroupItem tag="a" action onClick={()=>{
                const rulesetId = randomstring.generate(5)
                const newRulesetName = 'Neues Regelwerk'
                const searchRulesetName = new RegExp('^' + newRulesetName + '( \\d+)*$')
                const newRulesetNameExists = attributeRulesetStore.attributeRulesetsEdit.filter(el => el.name.match(searchRulesetName)).length
                const newRulesetNameLastNumber = Math.max( ...attributeRulesetStore.attributeRulesetsEdit.filter(el => el.name.match(searchRulesetName)).map(el => ( el.name.match(searchRulesetName) && el.name.match(searchRulesetName)![1] ) ? parseInt( el.name.match(searchRulesetName)![1] ) : 0 ) )
                const rulesetName = newRulesetName + (newRulesetNameExists > 0 ? ' '+(newRulesetNameLastNumber + 1).toString() : '')
                attributeRulesetStore.setAttributeRuleset({id: rulesetId, name: rulesetName, active: true, editSafeMode: true})
                attributeRulesetStore.setAttributeRulesetRule(rulesetId, emptyAttributeRule, undefined, true)
                setSelected( rulesetId )
                }} key='ruleset-new'><FontAwesomeIcon icon='plus-circle'/>{'  '}{t('ruleset.addRuleset')}</ListGroupItem>
            </ListGroup>
          </div>
          { typeof ruleset !== 'undefined' && <>
            <div>
              {/* <div>
                <TextEditable
                  initValue={rule.name ? rule.name : ''}
                  callback={(name: string) => {
                    console.log(name)
                    setRule({...rule, name})
                    changeRule()
                  }}
                  // htmlClosure={{before: '<h4>', after: '</h4>'}}
                />
              </div> */}
              {/* <TextInput id={'rule-search-name-'+index} value={rule.name?rule.name:''} label='Regelwerk-Name' labelSize={3}
                callback={({newVal}) => {
                  setRule({...rule, name: newVal})
                  changeRule()
                }} /> */}
                <h2>{t('ruleset.settingsOf')} <Badge color="secondary">{ruleset.name}</Badge></h2>
                <div style={{marginBottom: '1em'}}>
                        <TextInput id='ruleset-name' value={ruleset.name} callback={({newVal: rulesetName}) => attributeRulesetStore.setAttributeRuleset({id: ruleset.id, name: rulesetName, editSafeMode: true})} label={t('ruleset.rulesetName')}/>
                      </div>
                      {/* <TextEditable 
                        initValue={ruleset.name}
                        htmlClosure={{before: '<h4>', after: '</h4>'}}
                        callback={(rulesetName: string) => attributeRulesetStore.setSortingRule({rulesetId: ruleset.id, rulesetName})} /> */}
            </div>
            
            <div className="ruleset-attributes">
              {attributeRulesetStore.attributeRulesetsEdit.map((ruleset, rulesetIndex) => {
                if ( selected !== undefined && ruleset.id === selected ) {
                  return (
                    <div key={'ruleset-attributes-'+rulesetIndex}>
                      <h3>{t('ruleset.attributesOf')} <Badge color="secondary">{ruleset.name}</Badge></h3>
                      <TransitionGroup>
                        {ruleset.attributes.map((listItem, index) => 
                          <CSSTransition key={'attribute-item-'+index} timeout={800} classNames='rule-item'>
                            <ModalRulesAttribute  index={index} rulesetId={ruleset.id} attribute={listItem}/>
                          </CSSTransition>
                        )}
                      </TransitionGroup>
                      
                      <div className='add-new-rule' onClick={() => addNewAttribute(ruleset.id)}>
                        <span><FontAwesomeIcon icon='plus-circle' size='2x'/><br/>
                        {t('ruleset.addAttribute')}</span>
                      </div>
                    </div>
                  )
                } else {
                  return ''
                }
              })}
            </div>

            <div className="ruleset-rules">
              {attributeRulesetStore.attributeRulesetsEdit.map((ruleset, rulesetIndex) => {
                if ( selected !== undefined && ruleset.id === selected ) {
                  return (
                    <div key={'ruleset-rules-'+rulesetIndex}>
                      <h3>{t('ruleset.rulesOf')} <Badge color="secondary">{ruleset.name}</Badge></h3>
                      <TransitionGroup>
                        {ruleset.rules.map((listItem, index) => 
                          <CSSTransition key={'rule-item-'+index} timeout={800} classNames='rule-item'>
                            <ModalRulesRule  index={index} rulesetId={ruleset.id} rule={listItem}/>
                          </CSSTransition>
                        )}
                      </TransitionGroup>
                      
                      <div className='add-new-rule' onClick={() => addNewRule(ruleset.id)}>
                        <span><FontAwesomeIcon icon='plus-circle' size='2x'/><br/>
                        {t('ruleset.addRule')}</span>
                      </div>
                    </div>
                  )
                } else {
                  return ''
                }
              })}
            </div>
          </>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle(true, selected)}>{t('ruleset.save')}</Button>{' '}
          <Button color="secondary" onClick={()=>toggle(false)}>{t('ruleset.cancel')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}))

export default ModalRules;