import * as ReportTS from '../components/clipReport/__tsTimelineExport'
import ClipAttribute from './ClipAttribute'
import ClipTimes from './ClipTimes'
import VisiblePart from './ClipVisiblePart'
import Operation from './Operation'
import { TimelineStore } from '../components/stores/TimelineStore'
import Times from './Times'
import TrackClip from './TrackClip'
import ClipGroup from './ClipGroup'

interface ClipProps {
  store?: TimelineStore
  times: ClipTimes
  name: string
  id: string
  fileId: string
  sequenceId?: string
  itemType?: ReportTS.ItemType
  filter?: ReportTS.Effect[]
  pproColorLabel?: string
  alphatype?: string
  parent: {
    sequenceId: string
    clipIds: string[]
  }
  trackType: ReportTS.TrackType
  attributes: ReportTS.Attribute
}
type ClipKeys = keyof ClipProps

export default class Clip {
  store?: TimelineStore
  times: ClipTimes
  name: string
  id: string
  fileId: string
  itemType?: ReportTS.ItemType
  filter?: ReportTS.Effect[]
  pproColorLabel?: string
  alphatype?: string
  groups: ClipGroup[]
  sequenceId?: string
  // parentSequence: string
  // parentClipId?: string
  parent: {
    sequenceId: string
    clipIds: string[]
  }
  // sequenceUnfold?: boolean
  trackType: ReportTS.TrackType
  attributes: ReportTS.Attribute
  // linkedClips?: Clip[]
  linkedClipsIds: string[]

  constructor({store, times, name, id, fileId, itemType, filter, pproColorLabel, alphatype, sequenceId, parent, trackType, attributes}: ClipProps ) {
    this.store = store
    this.times = times
    this.name = name
    this.id = id
    this.fileId = fileId
    this.itemType = itemType
    this.filter = filter
    this.pproColorLabel = pproColorLabel
    this.alphatype = alphatype
    this.sequenceId = sequenceId
    this.parent = parent
    this.trackType = trackType
    this.attributes = attributes
    this.groups = []
    this.linkedClipsIds = []
  }

  addStore(store: TimelineStore) {
    this.store = store
  }

  modifyAttribute(modification: ReportTS.Modification) {
    console.log(modification)
    const valueBefore = this.attributes[modification.key]?.value
    if(modification.mode === 'change' && typeof modification.value === 'undefined') {
      this.removeAttribute(modification.key)
      return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: undefined, before: valueBefore})
    }
    switch (modification.mode) {
      case 'toggle':
        if(typeof this.attributes[modification.key]?.value === 'boolean'){
          this.setAttribute(modification.key, ! this.attributes[modification.key].value)
          return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: this.attributes[modification.key].value, before: valueBefore})
        } else {
          this.setAttribute(modification.key, true)
          return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: true, before: undefined})
        }
      case 'change':
        if(typeof modification.value === 'boolean' || typeof modification.value === 'string'){
          this.setAttribute(modification.key, modification.value)
          return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore})
        }
        break;
      case 'groupSet':
      case 'arrayAdd':
        if( typeof modification.value === 'object' && modification.value?.hasOwnProperty('search') && modification.value?.hasOwnProperty('replace') ){
          
          this.addGroup(modification.value.replace as ClipGroup)
          return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore})
        }
        break
      // case 'arrayAdd':
      //   if( typeof modification.value === 'object' && modification.value?.hasOwnProperty('search') && modification.value?.hasOwnProperty('replace') ){
      //     this.addGroup(modification.value.replace.group)
      //     // this.setAttribute(modification.key, modification.value.replace)
      //     return new Operation({op: modification.mode, id: this.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore})
      //     // new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: {noIndex: (clip[modification.key] as Array<any>).length-1} })
      //   }
      //   break;
      // case 'arrayAdd':
      //   if (valueBefore !== modification.value && modification.key !== 'transparence' && modification.key !== 'ignore' && modification.key !== 'sequenceUnfold' && modification.key !== 'owner' && modification.key !== 'rights') {
      //     // if (modification.value && modification.value.index !== undefined && typeof modification.value.index === 'number' && modification.value.value) {
      //     //   (clip[modification.key] as Array<any>)[value.index] = value.value
      //     //   this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore }))
      //     // } else
          
      //     if(typeof modification.value === 'object' && modification.value.search) {
      //       console.log([{...modification.value.search, ...modification.value.replace}]);
      //       (clip[modification.key] as Array<any>) = [{...modification.value.search, ...modification.value.replace}]
      //       this.actualOperationStack.push()
      //     }
      //   }
      //   break
      // case 'arrayChange':
      //   if (valueBefore !== modification.value) {
      //     if(typeof modification.value === 'object' && modification.value.search && modification.key !== 'transparence' && modification.key !== 'ignore' && modification.key !== 'sequenceUnfold' && modification.key !== 'owner' && modification.key !== 'rights') {
      //       const keys = Object.keys(modification.value.search)
      //       const values = Object.values(modification.value.search)
      //       // console.log(keys)
      //       // console.log(values)
      //       // clip[(modification.key as keyof ReportTS.Clip)].find((arr: any) => arr[modification.value.search])
      //       if(keys.length === 1 && values.length === 1) {
      //         (clip[modification.key] as Array<any>).forEach((arr: any, index: number) => {
      //           if (arr[keys[0]] === values[0]) {
      //             // console.log({...arr, ...modification.value.replace})
                  
      //             if(typeof modification.value === 'object' && modification.value.search) {
      //               (clip[modification.key as ReportTS.ClipKeysMod] as Array<any>)[index] = {...arr, ...modification.value.replace}
      //               this.actualOperationStack.push(new Operation({op: modification.mode, id: clip.id, type: 'clip', key: modification.key, value: modification.value, before: valueBefore }))
      //             }
      //           }
      //         })
      //       }
      //     }
      //   }
      //   break
      default:
        break;
    }
  }

  setAttribute(key: string, value: string | boolean | ClipGroup[]) {
    if(!this.attributes.hasOwnProperty(key)) {
      this.attributes[key] = {value: value, type: 'clip'}
    } else
      this.attributes[key].value = value
  }

  getAttribute(key: string, type: ReportTS.AttributeType = 'clip', {visible = 0, timeFormat, sequenceId, partTimes}:{visible?: number, timeFormat?: string, sequenceId?: string, partTimes?: Times} = {}) {
    // const times = this.times.find(t=>t.sequenceId===sequenceId)
    switch (key) {
      case 'groups':
        
        // // get clipGroups
        // const allItemGroups = this.store?.getSequenceOfClip(this.id)?.itemGroups
        // if(allItemGroups){
        //   const clipItemGroups = Array.from(allItemGroups.entries()).filter(([id, itemGroup]) => itemGroup.clipItems?.find(clip => clip === this))
        // }
        return new ClipAttribute({name: key, type, value: this.groups})
      case 'TCin':
        if (typeof timeFormat === 'undefined') return
        // const framesStart = this.times.visible?.[visible]?.startFormatted(timeFormat)
        // const originalValueStart = this.times.startFormatted(timeFormat)
        return new ClipAttribute({
          value: partTimes ? partTimes.startFormatted(timeFormat) : this.times.visible?.[visible]?.startFormatted(timeFormat), 
          originalValue: this.times.startFormatted(timeFormat), 
          type: type, name: key})
      case 'TCout':
        if (typeof timeFormat === 'undefined') return
        return new ClipAttribute({
          value: partTimes ? partTimes.endFormatted(timeFormat) : this.times.visible?.[visible]?.endFormatted(timeFormat), 
          originalValue: this.times.endFormatted(timeFormat), 
          type: type, name: key})
      case 'duration':
        if (typeof timeFormat === 'undefined') return
        return new ClipAttribute({
          value: partTimes ? partTimes.durationFormatted(timeFormat) : this.times.visible?.[visible]?.durationFormatted(timeFormat), 
          originalValue: this.times.durationFormatted(timeFormat), 
          type: type, name: key})
      default:
        if(!this.attributes.hasOwnProperty(key))
          return new ClipAttribute({value: undefined, name: key, type: 'clip'})

        return new ClipAttribute({...this.attributes[key], type: type, name: key})
    }
  }

  removeAttribute(key: string) {
    // console.log(`remove: ${key}`)
    delete this.attributes[key]
    console.log(JSON.parse(JSON.stringify(this.attributes)))
  }

  addVisible({start, end}: {start: number, end: number}) {
    // const times = this.times.find(t=>t.sequenceId===sequenceId)
    this.times.visible.push(new VisiblePart({timebase: this.times.timebase, start, end}))
  }

  modifyVisible({id, start, end}: {id: number, start?: number, end?: number}) {
    // console.log('modifyVisible')
    // console.log({id, start, end})
    // const times = this.times.find(t=>t.sequenceId===this.parent.sequenceId)
    if(this.times.visible.length < id) return false
    this.times.visible[id].setTime({start, end})
    return true
  }

  changeValue({key, value}: {key: ClipKeys, value: string}): Operation {
    const before = this[key]
    const op: ReportTS.OperationMode = typeof value === 'boolean' ? 'toggle' : 'change'

    return new Operation({op, id: this.id, type: 'clip', key: key, value, before})
  }

  get filePathItem() {
    return this.store?.filePaths.get(this.fileId)
  }

  get trackClips() : TrackClip[] {
    if(this.getAttribute('sequenceUnfold')?.getValueBoolean(false) && this.store && this.sequenceId) {
      return this.store.getVTracks({sequenceId: this.sequenceId, range: {inPoint: this.times.inPoint!, outPoint: this.times.outPoint!}})
        .map(track => track.clips).reduce((acc, val) => acc.concat(val), [])
    }
    return [new TrackClip({clip: this})]
    // return [{id: this.id, times: this.times, parent: {sequenceId: this.sequenceId ? this.sequenceId : this.parent.sequenceId}}]
  }

  // get visjsClassnamesParent() {
  //   let classnames: string[] = []
  //   this.getAttribute('ignore')?.getValueBoolean() && classnames.push('ignore')
  //   this.getAttribute('transparence')?.getValueBoolean() && classnames.push('transparence')
  //   return classnames
  // }

  get visjsClassnames () {
    let classnames: string[] = []
    this.getAttribute('ignore')?.getValueBoolean() && classnames.push('ignore')
    this.getAttribute('transparence')?.getValueBoolean() && classnames.push('transparence')
    typeof this.sequenceId !== 'undefined' && classnames.push('isSequence')
    this.getAttribute('sequenceUnfold') && classnames.push('unfolded')
    // this.parent.sequenceId && classnames.push('isSubSequence')
    // this.groupIds.length > 0 && classnames.push('isGroupItem')
    this.groups.length > 0 && classnames.push('isGroupItem')
    typeof this.pproColorLabel !== 'undefined' && classnames.push(`pproColorLabel-${this.pproColorLabel}`)
    
    switch (this.filePathItem?.type) {
      case 'adjustmentLayer':
        classnames.push('isAdjustmentLayer')
        break;
      case 'graphic':
        classnames.push('isGraphicsElement')
        break
      // case 'generator':
      //   classnames.push('isGenerator')
      //   break
      default:
        break;
    }
    switch (this.itemType) {
      case 'adjustmentLayer':
        classnames.push('isAdjustmentLayer')
        break;
      case 'graphic':
        classnames.push('isGraphicsElement')
        break
      case 'generator':
        classnames.push('isGenerator')
        break
      default:
        break;
    }
    return classnames
  }

  get linkedVideoAudio() {
    // return typeof this.linkedClips !== 'undefined' && 
    return this.linkedClipsIds.length > 0 && 
      (this.trackType === 'video' || typeof this.linkedClips.find(clip => clip.trackType === 'video') !== 'undefined') && 
      (this.trackType === 'audio' || typeof this.linkedClips.find(clip => clip.trackType === 'audio') !== 'undefined')
  }

  get linkedClips() {
    return this.store ? this.linkedClipsIds.map(clipId => this.store!.clipItems.get(clipId)!) : []
  }

  // get linkedIds() {
  //   return this.linkedClips && this.linkedClips.length > 0 ? this.linkedClips.map(lc=>lc.id) : []
  //   // return this.linkedClips && this.linkedClips.length > 0 ? [this.id, ...this.linkedClips.map(lc=>lc.id)] : [this.id]
  // }

  link(clip: Clip) {
    // console.log(`Link ${this.id} with ${clip.id}`)
    // console.log(`same clip? ${(this === clip) ? 'yes':'no'}`)
    // console.log(`already linked? ${this.linkedClips?.find(lC=>lC===clip) ? 'yes':'no'}`)
    // console.log(`linked clips: ${this.linkedClips?.map(lC=>lC.id).join(' ')}`)
    if(this === clip || this.linkedClips?.find(lC=>lC===clip)) return
    if(typeof this.linkedClips === 'undefined') this.linkedClipsIds = []
    this.linkedClipsIds.push(clip.id)
    clip.link(this)
    this.linkedClips.forEach(lC => lC.link(clip))
  }

  addGroup(clipGroup: ClipGroup) {
    const itemGroup = this.store?.getSequenceOfClip(this.id)?.itemGroups.get(clipGroup.groupId)
    console.log(itemGroup)
    if(itemGroup){
      if(itemGroup.clipItems) itemGroup.clipItems.push(this)
      else itemGroup.clipItems = [this]
    }
    this.groups.push(clipGroup)
  }
  removeGroup(clipGroup: ClipGroup) {
    this.groups = this.groups.filter(group => group !== clipGroup)
  }

  get itemGroups() {
    const allItemGroups = this.store?.getSequenceOfClip(this.id)?.itemGroups
    console.log(allItemGroups)
    if(allItemGroups){
      return Array.from(allItemGroups.entries()).filter(([id, itemGroup]) => itemGroup.clipItems?.find(clip => clip === this))
    }
    return []
  }

  get groupIds() {
    return this.itemGroups.map(([id]) => id)
  }

  isGroupOf(groupId: number){
    // get clipGroups
    const allItemGroups = this.store?.getSequenceOfClip(this.id)?.itemGroups
    if(allItemGroups){
      return typeof Array.from(allItemGroups.entries()).find(([id, itemGroup]) => id === groupId && itemGroup.clipItems?.find(clip => clip === this)) !== 'undefined'
    }
    return false
  }

  undo(operation: Operation) {
    // console.log(operation)
    if (operation.before && operation.before.noIndex !== undefined) {
      this.removeAttribute(operation.key)
    } else {
      this.modifyAttribute({mode: 'change', key: operation.key, value: operation.before})
    }
  }

  get rawData() {
    // const sequenceGroups = this.store?.getSequenceOfClip(this.id)?.itemGroups
    // const sequenceGroupsArray = sequenceGroups ? Array.from(sequenceGroups) : []
    return {
      times: this.times,
      name: this.name,
      id: this.id,
      fileId: this.fileId,
      itemType: this.itemType,
      filter: this.filter,
      pproColorLabel: this.pproColorLabel,
      alphatype: this.alphatype,
      sequenceId: this.sequenceId,
      parent: this.parent, // fix?
      trackType: this.trackType,
      attributes: this.attributes,
      // groups: this.groups.map(group => ({...group, group: undefined, groupId: sequenceGroupsArray.map(([id,itemGroup])=> ({id, itemGroup})).find(obj => obj.itemGroup === group.group)})), // fix?
      groups: this.groups.map(group => group.rawData), // fix?
      linkedClipsIds: this.linkedClipsIds
    }
  }
}