import React, { createContext } from 'react';
import { observer } from "mobx-react";
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from '@superwf/mobx-react-router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan, faEye, faEyeSlash, faFileImage, faFileVideo, faFont, faFileAudio, faPencilAlt, faCheck, faTimes, faPlusCircle, faTrash, faFilm, faVolumeUp, faMousePointer, faArrowCircleLeft, faStream, faListUl, faCheckDouble, faClipboardList, faClipboardCheck, faArrowAltCircleDown, faArrowAltCircleUp, faCircle, faUndoAlt, faExternalLinkAlt, faSlidersH, faList, faTh, faPlay, faSave, faUpload, faLevelUpAlt, faInfoCircle, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

import { RootStore } from './components/stores/RootStore'

import './App.scss';
import ViewManager from './components/ViewManager';
import { useStore } from './components/store'

library.add(faBan, faEye, faEyeSlash, faFileImage, faFileVideo, faFont, faFileAudio, faPencilAlt, faCheck, faTimes, faPlusCircle, faTrash, faFilm, faVolumeUp, faMousePointer, faArrowCircleLeft, faStream, faListUl, faCheckDouble, faClipboardList, faClipboardCheck, faArrowAltCircleDown, faArrowAltCircleUp, faCircle, faUndoAlt, faExternalLinkAlt, faSlidersH, faList, faTh, faPlay, faSave, faUpload, faLevelUpAlt, faInfoCircle, faMoon, faSun)

const AppContext = createContext<RootStore>(new RootStore())
const browserHistory = createBrowserHistory();

const AppView = observer(() =>{
  return <ViewManager/>
})

const App = () => {
  const {routingStore} = useStore()
  const history = syncHistoryWithStore(browserHistory, routingStore);

  return (
    <div className="App">
      <AppContext.Provider value={new RootStore()}>
        <Router history={history}>
          <AppView />
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
