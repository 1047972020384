import * as ReportTS from '../clipReport/__tsTimelineExport'
import { ServerRequest } from '../../ts/__tsGlobal'
// import * as RulesTS from '../../ts/__rules'
import * as Globals from '../clipReport/functions/Functions'
import { makeAutoObservable } from "mobx";
// import randomstring from 'randomstring'

import UserService from '../../auth/user-service'
import { RootStore } from './RootStore'

export class AttributeRulesetStore {
  rootStore: RootStore
  attributeRulesets: ReportTS.AttributeRuleset []
  attributeRulesetsEdit: ReportTS.AttributeRuleset []
  attributeSearchTypes: {folder: string, file: string, folderAndFile: string, clipname: string}
  serverRules: {
    request: ServerRequest
    data: ReportTS.AttributeRuleset[]
  }
  defaultAttributes: ReportTS.AttributeAvailable []
  defaultAttributeOrder: string []
  

  constructor(rootStore: RootStore){
    this.rootStore = rootStore
    makeAutoObservable(this)
    this.attributeRulesets = []
    this.attributeRulesetsEdit = []
    this.serverRules = {request: {status: 'not requested', timestamp: new Date().getTime()}, data: []}
    // this.attributeRulesets = [
    //   {
    //     id: 'str0',
    //     name: 'me2',
    //     active: true,
    //     rulesetVersion: 1,
    //     rules: [
    //       {id: 'uuid01', enabled: true, name: 'Shutterstock', search: [{name: 'init', type: 'folderAndFile', regex: { pattern: 'shutterstock', flags: 'i'}, required: true},{name: 'id', type: 'folderAndFile', regex: { pattern: 'shutterstock_(\\d+)', flags: 'i'}, required: false, varIndex: 1}], result: [{key: 'owner', value: 'shutterstock ({{id}})'},{key: 'rights', value: 'Standard-Lizenz'}]},
    //       {id: 'uuid02', enabled: true, name: 'Videohive', search: [{name: 'init', type: 'folderAndFile', regex: { pattern: 'videohive', flags: 'i'}, required: true},{name: 'id', type: 'folderAndFile', regex: { pattern: 'videohive_(\\d+)', flags: 'i'}, required: false, varIndex: 1}], result: [{key: 'owner', value: 'videohive ({{id}})'},{key: 'rights', value: 'Video Media (Multi-Use) Licence'}]},
    //       {id: 'uuid03', enabled: true, name: 'Adobe Stock', search: [{name: 'init', type: 'folderAndFile', regex: { pattern: 'adobestock', flags: 'i'}, required: true},{name: 'id', type: 'folderAndFile', regex: { pattern: 'adobestock_(\\d+)', flags: 'i'}, required: false, varIndex: 1}], result: [{key: 'owner', value: 'AdobeStock ({{id}})'},{key: 'rights', value: 'Standard-Lizenz'}]},
    //       {id: 'uuid04', enabled: true, name: 'Audiojungle', search: [{name: 'init', type: 'folderAndFile', regex: { pattern: 'audiojungle', flags: 'i'}, required: true},{name: 'id', type: 'folderAndFile', regex: { pattern: 'audiojungle_(\\d+)', flags: 'i'}, required: false, varIndex: 1}], result: [{key: 'owner', value: 'audiojungle ({{id}})'},{key: 'rights', value: 'Lizenz ?'}]},
    //       {id: 'uuid05', enabled: true, name: 'Audio Network', search: [{name: 'init', type: 'folderAndFile', regex: { pattern: 'anw\\d+', flags: 'i'}, required: true},{name: 'id', type: 'folderAndFile', regex: { pattern: 'anw(\\d+[_,-]\\d+)', flags: 'i'}, required: false, varIndex: 1}], result: [{key: 'owner', value: 'audio network ({{id}})'},{key: 'rights', value: 'Lizenztyp Professional'}]},
          
    //       {id: 'uuid06', enabled: true, name: 'Kunde', search: [{name: 'init', type: 'folder', regex: { pattern: 'kunde', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Kunde'}, {key: 'rights', value: 'Alle Rechte für diesen Zweck'}]},
    //       {id: 'uuid07', enabled: true, name: 'Extern', search: [{name: 'init', type: 'folder', regex: { pattern: 'kunde', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'extern'}, {key: 'rights', value: 'unbekannt'}]},
    //       {id: 'uuid08', enabled: true, name: 'Video RAW', search: [{name: 'init', type: 'folder', regex: { pattern: '1000 video raw', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Eigendreh'}, {key: 'rights', value: 'Alle Rechte'}]},
    //       {id: 'uuid09', enabled: true, name: 'Sprache Layout', search: [{name: 'init', type: 'folder', regex: { pattern: 'ADOBE PREMIERE PRO CAPTURED AUDIO', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Eigenproduktion'}, {key: 'rights', value: 'Alle Rechte'}]},
    //       {id: 'uuid10', enabled: true, name: 'Verpackung', search: [{name: 'init', type: 'folder', regex: { pattern: '1200 VERPACKUNG', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Kunde'}, {key: 'rights', value: 'Alle Rechte'}]},
    //       {id: 'uuid11', enabled: true, name: 'Grafik FX', search: [{name: 'init', type: 'folder', regex: { pattern: '1500 GRAFIK FX', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Eigenproduktion'}, {key: 'rights', value: 'Alle Rechte'}]},
    //       {id: 'uuid12', enabled: true, name: 'Animationen', search: [{name: 'init', type: 'folder', regex: { pattern: '1600 ANIMATIONEN', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Eigenproduktion'}, {key: 'rights', value: 'Alle Rechte'}]},
    //       {id: 'uuid13', enabled: true, name: 'After Effects', search: [{name: 'init', type: 'folder', regex: { pattern: '1400 AFTER EFFECTS', flags: 'i'}, required: true}], result: [{key: 'owner', value: 'Eigenproduktion'}, {key: 'rights', value: 'Alle Rechte'}]},

    //       {id: 'uuid14', enabled: true, name: 'Stock neu gekauft', search: [{name: 'init', type: 'folder', regex: { pattern: 'neu gekauft', flags: 'i'}, required: true}], result: [{key: 'stock', value: 'neu gekauft'}]},
    //       {id: 'uuid15', enabled: true, name: 'Stock bereits gekauft', search: [{name: 'init', type: 'folder', regex: { pattern: 'bereits gekauft', flags: 'i'}, required: true}], result: [{key: 'stock', value: 'bereits gekauft'}]},
    //       {id: 'uuid16', enabled: true, name: 'Stock nicht gekauft', search: [{name: 'init', type: 'folder', regex: { pattern: 'preview', flags: 'i'}, required: true}], result: [{key: 'stock', value: 'preview'},{key: 'rights', value: 'keine Rechte'}]}
    //     ],
    //     attributes: [
    //       {key: 'owner', type: 'file', displayName: 'Quelle'},
    //       {key: 'rights', type: 'file', displayName: 'Rechte'},
    //       {key: 'stock', type: 'file', displayName: 'Stock', notEditable: true},
    //     ],
    //     attributeListOrder: ['name', 'owner', 'icon', 'TCin', 'TCout', 'duration', 'rights', 'stock']
    //   }
    // ]
    this.attributeSearchTypes = {folder: 'Ordner', file: 'Datei', folderAndFile: 'Ordner und Datei', clipname: 'Clipname'}
    // if(this.serverRules.request.status === 'not requested')
    //   this.requestServerRules()
    this.defaultAttributes = [
      {key: 'transparence', type: 'clip', valueSource: 'clipItem', i18nKey: 'editOption.transparence', valueType: 'boolean', hideInExport: true},
      {key: 'ignore', type: 'clip', valueSource: 'clipItem', i18nKey: 'editOption.ignore', valueType: 'boolean', hideInExport: true},
      {key: 'name', type: 'clip', valueSource: 'clipItem', i18nKey: 'editOption.name', valueType: 'string'},
      {key: 'actionButtons', type: 'clip', valueSource: 'clipItem', i18nKey: 'editOption.action', notEditable: true, hideInExport: true},
      {key: 'icon', type: 'clip', valueSource: 'clipItem', i18nKey: 'editOption.filetype', notEditable: true, valueType: 'string', hideInExport: true},
      {key: 'TCin', type: 'clip', valueSource: 'clipPart', i18nKey: 'TCin', notEditable: true, valueType: 'string'},
      {key: 'TCout', type: 'clip', valueSource: 'clipPart', i18nKey: 'TCout', notEditable: true, valueType: 'string'},
      {key: 'duration', type: 'clip', valueSource: 'clipPart', i18nKey: 'duration', notEditable: true, valueType: 'string'}
    ]
    this.defaultAttributeOrder = ['name', 'icon', 'TCin', 'TCout', 'duration']
  }

  cleanupSession(){
    this.attributeRulesets = []
    this.serverRules = {request: {status: 'not requested', timestamp: new Date().getTime()}, data: []}
    this.attributeSearchTypes = {folder: 'Ordner', file: 'Datei', folderAndFile: 'Ordner und Datei', clipname: 'Clipname'}
  }

  get hasActiveRuleset(){
    const activeRuleset = this.attributeRulesets.find(ruleset=>ruleset.active)
    return typeof activeRuleset !== 'undefined'
  }

  get availableAttributes(){
    const activeAttributeRuleset = this.attributeRulesets.find(aR => aR.active)
    return [...this.defaultAttributes, ...(activeAttributeRuleset ? activeAttributeRuleset.attributes : [])]
  }

  editRulesStart(){
    console.log('start safemode')
    this.attributeRulesetsEdit = JSON.parse(JSON.stringify(this.attributeRulesets))
  }

  editRulesEnd(save: boolean){
    console.log('end safemode')
    if(save){
      console.log('-- save')
      this.attributeRulesets = JSON.parse(JSON.stringify(this.attributeRulesetsEdit))
    } else {
      console.log('-- cancel')
      this.attributeRulesetsEdit = []
    }
  }

  setAttributeRuleset({id, name = '', active, remove, editSafeMode}: {id: string, name?: string, active?: boolean, remove?: boolean, editSafeMode?: boolean}){
    const existingAttributeRuleset = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).find(attrRS => attrRS.id === id)
    if( existingAttributeRuleset ) {
      if( name !== '' ) existingAttributeRuleset['name'] = name
      existingAttributeRuleset['active'] = active
      if ( remove ) 
        if( editSafeMode ) {
          this.attributeRulesetsEdit = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).filter(rs => rs.id !== id)
        } else {
          this.attributeRulesets = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).filter(rs => rs.id !== id)
        }
    } else {
      (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).push({ id, name, rulesetVersion: 1, active, rules: [], attributes: [], attributeListOrder: [] })
    }
  }

  setAttributeRulesetRule(id: string, rule: ReportTS.AttributeRule, remove?: boolean, editSafeMode?: boolean){
    const existingAttributeRuleset = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).find(attrRS => attrRS.id === id)
    if( existingAttributeRuleset ) {
      const existingRule = existingAttributeRuleset.rules.find(rulesetRule => rulesetRule.id === rule.id)
      if ( existingRule ) {
        if ( remove ) {
          existingAttributeRuleset['rules'] = existingAttributeRuleset.rules.filter(rulesetRule => rulesetRule.id !== rule.id)
        } else {
          existingRule['enabled'] = rule.enabled
          existingRule['name'] = rule.name
          existingRule['search'] = rule.search
          existingRule['result'] = rule.result
        }
      } else {
        existingAttributeRuleset.rules.push(rule)
      }
    } 
  }

  setAttributeRulesetAttribute(id: string, attribute: ReportTS.AttributeAvailable, remove?: boolean, editSafeMode?: boolean){
    const existingAttributeRuleset = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).find(attrRS => attrRS.id === id)
    if( existingAttributeRuleset ) {
      const existigAttribute = existingAttributeRuleset.attributes.find(rulesetAttribute => rulesetAttribute.key === attribute.key)
      if ( existigAttribute ) {
        if ( remove ) {
          existingAttributeRuleset['attributes'] = existingAttributeRuleset.attributes.filter(rulesetAttribute => rulesetAttribute.key !== attribute.key)
        } else {
          existigAttribute['key'] = attribute.key
          existigAttribute['type'] = attribute.type
          existigAttribute['valueType'] = attribute.valueType
          existigAttribute['displayName'] = attribute.displayName
          existigAttribute['notEditable'] = attribute.notEditable
          existigAttribute['hideInExport'] = attribute.hideInExport
        }
      } else {
        existingAttributeRuleset.attributes.push(attribute)
      }
    } 
  }

  setAttributeRulesetAttributeListOrder(id: string, attributeListOrder: string[], editSafeMode?: boolean){
    const existingAttributeRuleset = (editSafeMode? this.attributeRulesetsEdit : this.attributeRulesets).find(attrRS => attrRS.id === id)
    if( existingAttributeRuleset ) {
      existingAttributeRuleset.attributeListOrder = attributeListOrder
    } 
  }

  setAttributeRulesetActive(id: string, saveToDB: boolean = true) {
    this.attributeRulesets.forEach(ruleset => {
      ruleset.active = ( ruleset.id === id ) ? true : undefined
    })
    if(saveToDB) {
      const rulesetDbId = this.attributeRulesets.find(aR=>aR.id===id)?.dbId
      if(rulesetDbId)
        UserService.setSortRulesetActive(rulesetDbId)
    }
  }

  saveAttributeRulesetToServer(rulesetId: string){
    const ruleset = this.attributeRulesets.find(ruleset => ruleset.id === rulesetId)

    if(ruleset) {
      UserService.setSortRules(ruleset).then(
        (data: any) => {
          console.log(data)
        },
        error => {
          console.log('error')
          console.log( (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString() )
        }
      )
    }
  }

  get activeRulesetAttributeOrder(): string[] {
    const activeAttributeRuleset = this.attributeRulesets.find(aR => aR.active)
    return activeAttributeRuleset ? activeAttributeRuleset.attributeListOrder : []
  }

  get activeAttributeRuleset() {
    return this.attributeRulesets.find(aR => aR.active)
  }

  getAttributeByKey(attribute: string): ReportTS.AttributeAvailable {
    const activeAttributeRuleset = this.attributeRulesets.find(aR => aR.active)
    const availableAttributes = [...this.defaultAttributes, ...(activeAttributeRuleset ? activeAttributeRuleset.attributes : [])]
    const matchingAttribute = availableAttributes.find(attr => attr.key === attribute)
    // console.log(matchingAttribute)
    return matchingAttribute ? matchingAttribute : {key: '', type: 'clip', valueSource: 'clipItem', valueType: 'string'}
  }

  getAttributesByFilepaths(filePathItem: ReportTS.FilePathItem): ReportTS.Attribute {
          
    if (filePathItem.type === 'graphic' ) {
      return {owner: {value: Globals.DEFAULT.SOURCE.SELF.owner, type: this.getAttributeByKey('owner').type}, rights: {value: Globals.DEFAULT.SOURCE.SELF.rights, type: this.getAttributeByKey('rights').type}}
    }

    if(filePathItem.pathurl) {
      const filePath = decodeURIComponent(filePathItem.pathurl)
      const indexFilename = filePath.lastIndexOf("/") + 1
      const fileName = filePath.substring(indexFilename)
      const searchString = {
        file: fileName,
        folder: filePath.substr(0, indexFilename - 1),
        folderAndFile: filePath,
        clipname: filePathItem.name ? filePathItem.name : fileName
      }

      const activeAttributeRuleset = this.attributeRulesets.find(aR => aR.active)
      if(activeAttributeRuleset){
        return activeAttributeRuleset.rules.map(rule => {
          const foundPattern: {key: string, value?: boolean | string, requiredNotFound?: boolean}[] = rule.search.map(search => {
            const regexResult = searchString[search.type].match(new RegExp(search.regex.pattern, search.regex.flags))
            if (regexResult === null) return search.required ? {key: search.name, requiredNotFound: true } : {key: search.name}
            if (search.required) return {key: search.name, value: true }
            const varIndex = search.varIndex === undefined ? 0 : search.varIndex
            if (regexResult.length-1 >= varIndex) return {key: search.name, value: regexResult[varIndex] }
            return search.required ? {key: search.name, requiredNotFound: true} : {key: search.name}
          })
          const foundPatternWithValue = foundPattern.filter(fP => fP.value !== undefined) as {key: string, value: boolean | string, requiredNotFound?: boolean}[]

          // console.log(foundPatternWithValue)
          if(foundPatternWithValue.length > 0 && foundPattern.filter(fP => fP.requiredNotFound).length === 0){
            return rule.result.map(res => ({[res.key]: { value: Globals.replaceVariables(res.value, foundPatternWithValue), type: this.getAttributeByKey(res.key).type } }))
            .reduce((acc, val) => Object.assign(acc, val), {})
          }
          return undefined

        }).filter(attr => attr !== undefined)
        .reduce((acc, val) => Object.assign(acc, val), {}) as  ReportTS.Attribute
      }
    }

    return {}
  }

  requestServerRules() {
    // console.log('start Request: ' +new Date().getTime() )
    this.serverRules.request = {status: 'loading', timestamp: new Date().getTime()}
    UserService.getSortRules().then((data: ReportTS.AttributeRuleset[]) =>{
      this.setServerRules(data)
      // this.serverRules = {
      //   request: {status: 'loaded', timestamp: this.serverRules.request.timestamp}, 
      //   data
      // }
      // this.attributeRulesets = data
    })
  }

  setServerRules(rulesets: ReportTS.AttributeRuleset[]){
    this.serverRules = {
      request: {status: 'loaded', timestamp: this.serverRules.request.timestamp}, 
      data: rulesets
    }
    this.attributeRulesets = rulesets
  }

  safeTryRequestServerRules() {
    if (this.serverRules.request.status === 'not requested' || this.serverRules.request.timestamp+(1000*60*5) < new Date().getTime()){
      this.requestServerRules()
    }
  }

}

// export type TStore = ReturnType<typeof createStore>

// export type TStoreInput = typeof createStore