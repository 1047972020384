import React, { useEffect, useState, useCallback } from "react"
import { Button } from 'reactstrap'
import ContentEditable, { ContentEditableEvent } from "react-contenteditable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AttributeRule }from '../__tsTimelineExport'

interface AttributeTextProps {
  initValue: string
  callback: (val: string) => void
  placeholder?: string
  rule?: AttributeRule
}

const AttributeText = (props: AttributeTextProps) => {
  const [editDisabled, setEditDisabled] = useState(true)
  const [initValue, setInitValue] = useState(props.initValue)
  const [contentEditable, setContentEditable] = useState(initValue)
  // const text = useRef('');
  // text.current = props.initValue

  const ruleSearchNames = useCallback(() => {
    return props.rule ? props.rule.search.map(s => s.name) : []
  }, [props])

  const formatContentEditable = useCallback((string: string) => {
    const matches = Array.from(string.matchAll(/{{(\w+)}}/g))
    let indexOffset = 0
    let index = 0
    let newContentEditable = string
    for (const match of matches) {
      const highlightId = ruleSearchNames().filter(name => name === match[1]).length > 0 ? index : 'error'
      newContentEditable = newContentEditable.insert(match.index! + match[0].length + indexOffset, '</span>')
      newContentEditable = newContentEditable.insert(match.index! + indexOffset, `<span class="highlight highlight-${highlightId}">`)
      indexOffset += `<span class="highlight highlight-${highlightId}"></span>`.length
      index += 1
    }
    return newContentEditable
  }, [ruleSearchNames])

  useEffect(()=>{
    const newFormatted = formatContentEditable(stripHtml(contentEditable))
    console.log(contentEditable)
    console.log(formatContentEditable(stripHtml(newFormatted)))
    if(contentEditable !== newFormatted)
      setContentEditable(newFormatted)
  }, [formatContentEditable, contentEditable]) // , [contentEditable]

  const stripHtml = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const handleChange = useRefCallback((evt: ContentEditableEvent) => {
    // const newFormatted = formatContentEditable(stripHtml(evt.target.value))
    // if(evt.target.value != newFormatted)
    //   setContentEditable(newFormatted)
    console.log(evt.target.value)
    console.log(formatContentEditable(stripHtml(evt.target.value)))
    setContentEditable(formatContentEditable(stripHtml(evt.target.value)))
  }, []);

  const submitChange = (e?: React.MouseEvent) => {
    if(e)
      e.preventDefault()
    setEditDisabled(true)
    setInitValue(contentEditable)
    props.callback( stripHtml( contentEditable ) )
  }

  const discardChanges = (e?: React.MouseEvent) => {
    if(e)
      e.preventDefault()
    setContentEditable(initValue)
    setEditDisabled(true)
  }

  const handleBlur = useRefCallback(() => {
    console.log(contentEditable); // 👍 correct value
  }, [contentEditable]);

  return <div className='Aligner'>
      <ContentEditable className='Aligner-item input-monospace' style={{marginRight: '1em',}}
        html={contentEditable} // innerHTML of the editable div
        disabled={editDisabled} // use true to disable edition
        onClick={() => {
          console.log(editDisabled)
          if(editDisabled) 
            setEditDisabled(!editDisabled)}}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={(evt)=>{
          console.log(evt)
          switch (evt.key) {
            case 'Escape':
              discardChanges()
              break
            case 'Enter':
              submitChange()
              break
          }
        }}
      />
      {editDisabled ? (
        <Button className='Aligner-item button-round' outline color="secondary" onClick={() => setEditDisabled(!editDisabled)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
      ) : (
        <div className='Aligner-item'>
          <Button className='button-round' style={{marginRight: '1em',}} outline color="success" onClick={submitChange}><FontAwesomeIcon icon="check" /> </Button>
          <Button className='button-round' outline color="danger" onClick={discardChanges}><FontAwesomeIcon icon="times" /> </Button>
        </div>
      )}
    </div>

  // // state: TextEditableState
  // private contentEditable = React.createRef<HTMLDivElement>()

  // constructor (props: TextEditableProps) {
  //   super(props)
  //   console.log(props)
  //   // this.contentEditable = React.createRef();
  //   this.state = {
  //     htmlClosure: props.htmlClosure ? props.htmlClosure : {before: '', after: ''},
  //     initValue: props.initValue,
  //     value: props.initValue,
  //     html: props.htmlClosure ? props.htmlClosure.before + props.initValue + props.htmlClosure.after : props.initValue,
  //     placeholder: props.placeholder,
  //     callback: props.callback,
  //     editDisabled: true
  //   }
  // }

  // componentDidUpdate(prevProps: TextEditableProps, prevState: TextEditableState) {
  //   if (this.state.editDisabled !== prevState.editDisabled && this.contentEditable ) {
  //     if ( prevState.editDisabled ) {
  //       console.log( this.contentEditable.current! )
  //       this.contentEditable.current!.focus();
  //       // this.contentEditable.current!.setSelectionRange(0, this.state.value.length);
  //     } else {
  //       this.contentEditable.current!.blur()
  //     }
  //   }
  // }

  // submitChange = (e: React.MouseEvent) => {
  //   e.preventDefault()
  //     this.setState(
  //       {...this.state, initValue: this.state.value, editDisabled: true},
  //       this.state.callback( this.state.value )
  //     )
  // }

  // handleChange = (evt: ContentEditableEvent) => {
  //   console.log(evt.target.value)
  //   this.setState({...this.state, ...this.cleanValue(evt.target.value) });
  // };

  // discardChanges = (e: React.MouseEvent) => {
  //   e.preventDefault()
  //   this.setState({...this.state, ...this.cleanValue(this.state.initValue), editDisabled: true})
  // }

  // cleanValue = (valueWithHtml: string) => {
  //   const regexBefore = new RegExp(this.state.htmlClosure!.before, 'g')
  //   const regexAfter  = new RegExp(this.state.htmlClosure!.after, 'g')
  //   const regexNewLine = new RegExp('<div><br></div>', 'g')
  //   const valueClean = valueWithHtml.replace(regexBefore, '').replace(regexAfter, '').replace(regexNewLine, '')
  //   return ({value: valueClean,
  //       html: this.state.htmlClosure!.before + valueClean + this.state.htmlClosure!.after,
  //     });
  // }

  // render() {
  //   return(
  //     <div className='Aligner' style={this.props.style}>
  //       <ContentEditable className='Aligner-item' style={{marginRight: '1em',}}
  //         html={this.state.html} // innerHTML of the editable div
  //         disabled={this.state.editDisabled} // use true to disable edition
  //         onChange={this.handleChange} // handle innerHTML change
  //         onKeyUp={(evt)=>console.log(evt.key)}
  //         innerRef={this.contentEditable}
  //       />
  //       {this.state.editDisabled ? (
  //         <Button className='Aligner-item button-round' outline color="secondary" onClick={() => this.setState({...this.state, editDisabled: false})}><FontAwesomeIcon icon="pencil-alt" /> </Button>
  //       ) : (
  //         <div className='Aligner-item'>
  //           <Button className='button-round' style={{marginRight: '1em',}} outline color="success" onClick={this.submitChange}><FontAwesomeIcon icon="check" /> </Button>
  //           <Button className='button-round' outline color="danger" onClick={this.discardChanges}><FontAwesomeIcon icon="times" /> </Button>
  //         </div>
  //       )}
  //     </div>)
  // }
}

export default AttributeText

declare global {
  interface String {
    insert(index: number, string: string): string;
  }
}

String.prototype.insert = function(index, string) {
  if (index > 0) {
    return this.substring(0, index) + string + this.substr(index);
  }

  return string + this;
}

const useRefCallback = <T extends any[]>(
  value: ((...args: T) => void) | undefined,
  deps?: React.DependencyList
): ((...args: T) => void) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, deps ?? [value]);

  const result = React.useCallback((...args: T) => {
    ref.current?.(...args);
  }, []);

  return result;
};