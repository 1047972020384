import React, { useState } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
} from 'reactstrap'
import { useObserver } from "mobx-react"
import { Switch, Route } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useStore } from './store'
// import ModalRules from './timelineExport/ModalRules'
import ModalCheckAction from './ModalCheckAction';
// import {ReactComponent as StoryboardFontLogo} from '../assets/Storyboard_Letter_Logo.svg'
import {ReactComponent as IconCCHelpers} from '../assets/Icon_bg_CCHelpers.svg'
import NavItemsClipReport from './clipReport/NavItemsClipReport'
import NavItemsStoryboard from './storyboard/NavItemsStoryboard'

const NavbarGlobal = () => {
  const {globalStore,routingStore} = useStore()
  const [isOpen, setIsOpen] = useState(false)
  // const refModalRules = useRef<any>(null)
  const { t } = useTranslation(['translation', 'clipReport']);

  const toggle = () => setIsOpen(prevState => !prevState)

  return useObserver(() => (
    <div style={{marginLeft: '-15px', marginRight: '-15px'}}>
      <Navbar color="light" light expand="md">
        <NavbarBrand tag="a" onClick={() => { routingStore.push('/') }} style={{fontWeight: 600}}><IconCCHelpers style={{height:'27px', cursor: 'pointer'}}/>&nbsp; CC Helpers</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {!globalStore.loggedIn && <NavItem onClick={() => { routingStore.push('/about') }} >
              <NavLink>{t("navbar.about")}</NavLink>
            </NavItem>}
            {!globalStore.loggedIn && <NavItem onClick={() => { routingStore.push('/login') }} >
              <NavLink>{t("login")}</NavLink>
            </NavItem>}
            {!globalStore.loggedIn && <NavItem onClick={() => { routingStore.push('/register') }} >
              <NavLink>{t("navbar.register")}</NavLink>
            </NavItem>}
            {globalStore.loggedIn && <NavItem onClick={() => { routingStore.push('/dashboard') }} >
              <NavLink>{t("navbar.dashboard")}</NavLink>
            </NavItem>}
            {globalStore.loggedIn && 
              <Switch>
                {globalStore.userAccessClipReport && <Route path='/timeline-export'>
                  <NavItemsClipReport />
                </Route>}
                {globalStore.userAccessStoryboard && <Route path='/storyboard'>
                  <NavItemsStoryboard />
                </Route>}
                <Route path='/'>
                  {globalStore.userAccessStoryboard &&<NavItem className='nav-storyboard' onClick={() => { routingStore.push('/storyboard') }} >
                    <NavLink>Stroyboard</NavLink>
                  </NavItem>}
                  {globalStore.userAccessClipReport && <NavItem className='nav-timeline-export' onClick={() => { routingStore.push('/timeline-export') }} >
                    <NavLink>{t("clipReport:clipReport")}</NavLink>
                  </NavItem>}
                </Route>
              </Switch>}
            {(globalStore.loggedIn) && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {t("navbar.user.account")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" disabled>
                    <div>
                      {globalStore.loggedIn.username}
                    </div>
                  </DropdownItem>
                  <DropdownItem tag="a" active={routingStore.location.pathname === '/account'}>
                    <div onClick={() => routingStore.push('/account')}>
                      {t("navbar.user.details")}
                    </div>
                  </DropdownItem>
                  <DropdownItem tag="a">
                    <ModalCheckAction
                      modalTitle={t('user.wantToLogoutQuestion')}
                      confirmButton={t('user.logoutConfirm')}
                      confirmButtonColor="primary"
                      modalBody={<>{t("user.loggedInAs", {username: globalStore.loggedIn.username})}</>}
                      callback={() => {
                        globalStore.setLoggedIn()
                        localStorage.removeItem('user')
                      }}
                      style={{display: 'inline'}}>
                        <div>{t("logout")}</div>
                    </ModalCheckAction>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}  
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  ))
}
export default NavbarGlobal